:root {
  --blue: #1d3246;
  --white: #ffffff;
  --black: #000000;
  --active-blue: #427da5;
}

@font-face {
  font-family: 'AvenirHeavy';
  src: local('MyFont'), url(./assets/fonts/Avenir-Heavy.ttf) format('truetype');
}
@font-face {
  font-family: 'AvenirLight';
  src: local('AvenirLight'),
    url(./assets/fonts/Avenir-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'AvenirMedium';
  src: local('AvenirMedium'),
    url(./assets/fonts/Avenir-Medium.ttf) format('truetype');
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'AvenirLight', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f3f8 !important;
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--white);
  background: var(--black);
}

.ant-input::selection {
  color: var(--white);
  background: var(--black);
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: 'AvenirLight', sans-serif;
}

.btn-custom {
  margin: 0 5px;
}

.btn-gradient-01 {
  background: linear-gradient(
    to right,
    var(--blue) 0%,
    #647380 100%
  ) !important;
}

table {
  color: var(--black) !important;
}

thead[class*='ant-table-thead'] th {
  /* background-color: yellow !important; */
  font-weight: bolder !important;
  /* border-right: 1px dashed black; */
  padding: 10px 16px !important;
  color: var(--black);
  font-size: 17px;
  font-family: 'AvenirHeavy';
}
thead[class*='ant-table-thead'] th:hover {
  background-color: var(--blue);
  color: var(--white);
}

.ant-input:focus,
.ant-input-focused {
  border-color: var(--black) !important;
}

.btn-custom {
  /* border-color: black !important; */
  color: var(--white) !important;
}

.btn-custom:hover {
  border-color: var(--black) !important;
  color: var(--white) !important;
}

.btn-custom2:hover,
.btn-custom2:focus {
  border-color: var(--black) !important;
  color: var(--black) !important;
}

/* .table-hover tbody tr:hover th {
  background-color: yellow;
} */

/* .btn-gradient-01:before {
    background: linear-gradient(to right, #343a40b8 0%, #212529 100%) !important;
    background-size: 200% auto;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    z-index: -100;
    font-weight: 600;
    transition: opacity 2s;
    color: #fff;
    border: 0 none;
    padding: 12px 20px;
    opacity: 0;
}

.btn-gradient-01:hover:before {
    opacity: 1;
} */

.default-sidebar > .side-navbar ul a:hover i {
  color: var(--active-blue) !important;
}
