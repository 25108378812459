@import url(icons/lineawesome/css/line-awesome.min.css);
@import url(icons/ionicons/css/ionicons.min.css);
@import url(icons/themify/css/themify-icons.min.css);
@import url(icons/meteocons/css/meteocons.min.css);

html {
  font-size: 14px;
}
body,
html {
  height: 100%;
}
body {
  /* font-family: "Montserrat", sans-serif; */
  font-family: "AvenirMedium", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  /* color: #98a8b4; */
  color: black;
  background-color: #f2f3f8;
}
a {
  color: #5d5386;
  text-decoration: none;
  transition: all 0.5s ease;
}
a:focus,
a:hover {
  color: #e76c90;
  text-decoration: underline;
}
*[class*="icon-"] {
  transform: translateY(3px);
}
body {
  overflow-x: hidden;
}
a,
i,
span {
  display: inline-block;
  text-decoration: none;
}
a:hover,
a:focus,
i:hover,
i:focus,
span:hover,
span:focus {
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
}
ol,
ul,
li {
  list-style: none;
}
section {
  padding: 35px 0;
}
.section {
  padding: 35px 0;
}
canvas {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  /* font-family: "Noto Sans", sans-serif; */
  font-family: "AvenirMedium", sans-serif;
  line-height: 1.1;
  color: #2c304d;
}
h1,
.h1 {
  font-size: 2.5rem;
  /* font-weight: 600; */
}
h2,
.h2 {
  font-size: 1.5rem;
  font-weight: 500;
}
h3,
.h3 {
  font-size: 1.35rem;
  font-weight: 500;
}
h4,
.h4 {
  font-size: 1.2rem;
  font-weight: 500;
}
h5,
.h5 {
  font-size: 1rem;
  font-weight: 500;
}
h6,
.h6 {
  font-size: 0.85rem;
  font-weight: 500;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}
#preloader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999999;
}
.canvas {
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loader-logo {
  display: block;
  width: 120px;
  margin-bottom: 25px;
}
.spinner {
  animation: spinner 1s linear infinite;
  border: solid 5px transparent;
  border-top: solid 5px #5d5386;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  margin: 0 auto;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.blockquote {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  font-size: 0.85rem;
  border-left: 0.25rem solid #eceeef;
}
.blockquote-footer {
  color: #636c72;
}
.blockquote-footer::before {
  content: "\2014 \00A0";
}
.blockquote-reverse {
  padding-right: 1rem;
  padding-left: 0;
  text-align: right;
  border-right: 0.25rem solid #eceeef;
  border-left: 0;
}
.page-header {
  position: relative;
  z-index: 99;
  padding: 0 15px 30px 15px;
  width: 100%;
}
.page-header-2 {
  background: #fff;
  border-bottom: 1px solid #eee;
  position: relative;
  z-index: 99;
  padding: 25px 30px;
  margin-bottom: 10px;
  width: 100%;
}
.page-header-title {
  color: #2c304d;
  /* font-weight: 600; */
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  margin-right: auto !important;
}
.page-header-tools {
  display: inline-block;
}
.container-fluid {
  padding: 30px 30px;
}
@media (max-width: 576px) {
  .container-fluid {
    padding: 15px;
  }
  .content-inner {
    margin-left: 0 !important;
  }
  .page-header,
  .page-header-2 {
    padding: 20px 15px;
  }
}
header.page-header {
  padding: 30px 0;
}
table {
  font-size: 1rem;
  color: #98a8b4;
}
.content-inner {
  width: calc(100% - 240px);
  margin-left: 240px;
  transition: all 0.3s ease;
}
.content-inner.active {
  width: calc(100% - 90px);
  margin-left: 90px;
  transition: all 0.3s ease;
}
.content-inner.compact {
  width: calc(100% - 90px);
  margin-left: 90px;
  transition: all 0.3s ease;
}
.content-inner.compact.active {
  width: calc(100% - 0px);
  margin-left: 0;
  transition: all 0.3s ease;
}
.content-inner.boxed {
  width: calc(100% - 0px);
  margin-left: 0;
}
button,
input {
  outline: none !important;
}
.flex-row {
  display: flex;
}
.flex-row > [class*="col-"] {
  display: flex;
  flex-direction: column;
}
.flex-row > [class*="col-"] > .widget {
  background: #fff;
  height: 100%;
}
.flex-row > [class*="col-"] > .widget-image {
  height: 100%;
}
.flex-row.row:after,
.flex-row.row:before {
  display: flex;
}
.box-scroll {
  overflow-x: auto !important;
}
.go-top {
  background: #fff;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #2c304d;
  position: fixed;
  bottom: 3rem;
  right: 1rem;
  text-decoration: none;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  display: none;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.15);
  z-index: 10000;
}
.go-top:hover,
.go-top:focus {
  color: #5d5386;
}
.c-icon {
  display: block;
  vertical-align: middle;
  text-align: center;
  padding: 10px;
}
.c-icon i {
  color: #2c304d;
  font-size: 3rem;
  vertical-align: middle;
}
.c-class {
  color: #98a8b4;
  font-size: 0.85rem;
  font-weight: 500;
  width: 100%;
  display: block;
  vertical-align: middle;
  text-align: center;
  padding: 0;
  margin-bottom: 20px;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.animated.infinite {
  animation-iteration-count: infinite;
}
@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }
  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }
  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }
  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
.swing {
  transform-origin: top center;
  animation-name: swing;
}
@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}
.bounce {
  animation-name: bounce;
  transform-origin: center bottom;
}
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}
.pulse {
  animation-name: pulse;
}
.logo-centered {
  width: 140px;
  margin: 0 auto;
}
.logo-centered img {
  width: 140px;
  margin-bottom: 50px;
}
.photo-profil {
  position: relative;
  width: 100px;
  margin: 0 auto 35px;
}
.photo-profil img {
  width: 100px;
  height: 100px;
}
.photo-profil .icon {
  position: absolute;
  background: #fff;
  font-size: 2rem;
  border-radius: 50%;
  right: -15px;
  bottom: -10px;
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  padding: 0;
}
.animated-icon {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;
  margin: 0 auto 30px;
}
.animated-icon .icon {
  background: #fff;
  font-size: 3.5rem;
  text-align: center;
  color: #2c304d;
  position: absolute;
  top: 7px;
  left: 7px;
}
.animated-icon .icon {
  width: 85px;
  height: 85px;
}
.animated-icon .icon i {
  position: relative;
  top: 5px;
}
.animated-icon .gradient,
.animated-icon .gradient:after {
  display: block;
  content: "";
  width: 400px;
  height: 100px;
  background: #5d5386;
  background: linear-gradient(
    135deg,
    #5d5386 0%,
    #5d5386 20%,
    #e23f82 40%,
    #e23f82 60%,
    #5d5386 80%,
    #5d5386 100%
  );
}
.animated-icon .gradient {
  animation: 8s anim linear infinite;
}
.animated-icon .gradient:after {
  transform: translateX(400px);
}
@keyframes anim {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-400px);
  }
}
.elisyam-bg {
  height: 100vh;
  width: 100%;
}
.elisyam-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.elisyam-overlay.overlay-01 {
  background: linear-gradient(
    135deg,
    rgba(46, 52, 81, 0.4) 0%,
    rgba(52, 40, 104, 0.95) 100%
  );
}
.elisyam-overlay.overlay-02 {
  background: linear-gradient(
    135deg,
    rgba(255, 195, 113, 0.4) 0%,
    rgba(205, 95, 109, 0.95) 100%
  );
}
.elisyam-overlay.overlay-03 {
  background: linear-gradient(
    135deg,
    rgba(46, 52, 81, 0.4) 0%,
    rgba(205, 95, 109, 0.95) 100%
  );
}
.elisyam-overlay.overlay-04 {
  background: linear-gradient(
    135deg,
    rgba(66, 39, 90, 0.4) 0%,
    rgba(115, 75, 109, 0.95) 100%
  );
}
.elisyam-overlay.overlay-05 {
  background: linear-gradient(
    135deg,
    rgba(0, 201, 255, 0.4) 0%,
    rgba(146, 254, 157, 0.95) 100%
  );
}
.elisyam-overlay.overlay-06 {
  background: linear-gradient(
    135deg,
    rgba(46, 52, 81, 0.2) 0%,
    rgba(46, 52, 81, 0.95) 100%
  );
}
.elisyam-overlay.overlay-07 {
  background: linear-gradient(
    135deg,
    rgba(46, 52, 81, 0.5) 0%,
    rgba(46, 52, 81, 0.95) 100%
  );
}
.elisyam-overlay.overlay-08 {
  background: linear-gradient(
    135deg,
    rgba(46, 52, 81, 0.85) 0%,
    rgba(46, 52, 81, 0.85) 100%
  );
}
.elisyam-bg.background-01 {
  background: url(img/background/01.jpg) no-repeat;
  background-size: cover;
}
.elisyam-bg.background-02 {
  background: url(img/background/02.jpg) no-repeat;
  background-size: cover;
}
.elisyam-bg.background-03 {
  background: url(img/background/03.jpg) no-repeat;
  background-size: cover;
}
.elisyam-bg.background-04 {
  background: url(img/background/04.jpg) no-repeat;
  background-size: cover;
}
.elisyam-bg.background-05 {
  background: url(img/background/05.jpg) no-repeat;
  background-size: cover;
}
.elisyam-bg.background-06 {
  background: url(img/background/06.jpg) no-repeat;
  background-size: cover;
}
.bg-fixed-01 {
  background: linear-gradient(
      135deg,
      rgba(46, 52, 81, 0.4) 0%,
      rgba(205, 95, 109, 0.95) 100%
    ),
    url(img/background/01.jpg) no-repeat center center;
}
.bg-fixed-02 {
  background: linear-gradient(
      135deg,
      rgba(46, 52, 81, 0.4) 0%,
      rgba(205, 95, 109, 0.95) 100%
    ),
    url(img/background/02.jpg) no-repeat center center;
}
.bg-fixed-03 {
  background: linear-gradient(
      135deg,
      rgba(255, 195, 113, 0.4) 0%,
      rgba(205, 95, 109, 0.95) 100%
    ),
    url(img/background/03.jpg) no-repeat center center;
}
.bg-fixed-04 {
  background: linear-gradient(
      135deg,
      rgba(46, 52, 81, 0.5) 0%,
      rgba(46, 52, 81, 0.95) 100%
    ),
    url(img/background/04.jpg) no-repeat center center;
}
.bg-fixed-05 {
  background: linear-gradient(
      135deg,
      rgba(0, 201, 255, 0.4) 0%,
      rgba(146, 254, 157, 0.95) 100%
    ),
    url(img/background/05.jpg) no-repeat center center;
}
.bg-fixed-06 {
  background: linear-gradient(
      135deg,
      rgba(66, 39, 90, 0.4) 0%,
      rgba(115, 75, 109, 0.95) 100%
    ),
    url(img/background/06.jpg) no-repeat center center;
}
.bg-fixed-01,
.bg-fixed-02,
.bg-fixed-03,
.bg-fixed-04,
.bg-fixed-05,
.bg-fixed-06 {
  overflow: auto;
}
.full-screen {
  height: 100vh !important;
}
.btn {
  font-weight: 600 !important;
  line-height: 1.25;
  border: 2px solid transparent;
  padding: 10px 20px;
  font-size: 0.85rem;
  border-radius: 60px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
}
.ripple {
  background-position: center;
  transition: background 0.6s;
}
.ripple:hover {
  background: rgba(255, 255, 255, 0.15)
    radial-gradient(circle, transparent 1%, rgba(255, 255, 255, 0.15) 1%)
    center/15000%;
}
.ripple:active {
  background-color: rgba(255, 255, 255, 0.15);
  background-size: 100%;
  transition: background 0s;
}
.btn:focus,
.btn.focus {
  outline: none !important;
  box-shadow: none !important;
}
.btn:active,
.btn.active {
  background-image: none;
}
a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}
.dropdown-menu {
  border-color: #eee;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.15);
}
.btn-shadow,
.btn-shadow a {
  color: #5d5386;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.15);
}
.btn-shadow i,
.btn-shadow a i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-shadow:hover,
.btn-shadow a:hover {
  color: #5d5386;
  box-shadow: 0 1px 20px 1px rgba(52, 40, 104, 0.2);
}
.btn-shadow:active {
  color: #5d5386;
  background-color: #fff;
  border-color: #fff;
}
.btn-shadow:focus,
.btn-shadow.focus {
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.15) !important;
}
.btn-shadow.disabled,
.btn-shadow:disabled {
  color: #9c90d0;
  background-color: #fff;
  border-color: #fff;
}
.btn-shadow:active,
.btn-shadow.active,
.show > .btn-shadow.dropdown-toggle {
  color: #5d5386;
  background-color: #fff;
  background-image: none;
}
.btn-gradient-01,
.btn-gradient-01 a {
  background: #e23f83;
  background: linear-gradient(to right, #e23f83 0%, #ea5844 100%);
  background-size: 200% auto;
  font-weight: 600;
  transition: 0.5s;
  color: #fff;
  border: 0 none;
  padding: 12px 20px;
}
.btn-gradient-01 i,
.btn-gradient-01 a i {
  color: #fff;
  display: inline-block;
  font-size: 1.2rem;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-gradient-01:hover,
.btn-gradient-01 a:hover {
  color: #fff;
  background-position: right center;
}
.btn-gradient-01:active {
  color: #fff;
  background-color: #e23f83;
  border-color: #e23f83;
}
.btn-gradient-01:focus,
.btn-gradient-01.focus {
  color: #fff;
  box-shadow: none;
}
.btn-gradient-01.disabled,
.btn-gradient-01:disabled {
  background-color: none;
  opacity: 0.3;
}
.btn-gradient-01:active,
.btn-gradient-01.active,
.show > .btn-gradient-01.dropdown-toggle {
  color: #fff;
  background-color: #e23f83;
  background-image: none;
}
.btn-gradient-02,
.btn-gradient-02 a {
  background: #5d5386;
  background: linear-gradient(to right, #5d5386 0%, #5d5386 100%);
  background-size: 200% auto;
  font-weight: 600;
  transition: 0.5s;
  color: #fff;
  border: 0 none;
  padding: 12px 20px;
}
.btn-gradient-02 i,
.btn-gradient-02 a i {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.2rem;
  margin-right: 0.4rem;
}
.btn-gradient-02:hover,
.btn-gradient-02 a:hover {
  color: #fff;
  background-position: right center;
}
.btn-gradient-02:active {
  color: #fff;
  background-color: #5d5386;
  border-color: #5d5386;
}
.btn-gradient-02:focus,
.btn-gradient-02.focus {
  color: #fff;
  box-shadow: none;
}
.btn-gradient-02.disabled,
.btn-gradient-02:disabled {
  background-color: none;
  opacity: 0.3;
}
.btn-gradient-02:active,
.btn-gradient-02.active,
.show > .btn-gradient-02.dropdown-toggle {
  color: #fff;
  background-color: #5d5386;
  background-image: none;
}
.btn-gradient-03,
.btn-gradient-03 a {
  background: #6859ea;
  background: linear-gradient(to right, #6859ea 0%, #6acbe0 100%);
  background-size: 200% auto;
  font-weight: 600;
  transition: 0.5s;
  color: #fff;
  border: 0 none;
  padding: 12px 20px;
}
.btn-gradient-03 i,
.btn-gradient-03 a i {
  display: inline-block;
  font-size: 1.2rem;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-gradient-03:hover,
.btn-gradient-03 a:hover {
  color: #fff;
  background-position: right center;
}
.btn-gradient-03:active {
  color: #fff;
  background-color: #e23f83;
  border-color: #e23f83;
}
.btn-gradient-03:focus,
.btn-gradient-03.focus {
  color: #fff;
  box-shadow: none;
}
.btn-gradient-03.disabled,
.btn-gradient-03:disabled {
  background-color: none;
  opacity: 0.3;
}
.btn-gradient-03:active,
.btn-gradient-03.active,
.show > .btn-gradient-03.dropdown-toggle {
  color: #fff;
  background-color: #e23f83;
  background-image: none;
}
.btn-gradient-04,
.btn-gradient-04 a {
  background: #41c7af;
  background: linear-gradient(to right, #41c7af 0%, #54e38e 100%);
  background-size: 200% auto;
  font-weight: 600;
  transition: 0.5s;
  color: #fff;
  border: 0 none;
  padding: 12px 20px;
}
.btn-gradient-04 i,
.btn-gradient-04 a i {
  display: inline-block;
  font-size: 1.2rem;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-gradient-04:hover,
.btn-gradient-04 a:hover {
  color: #fff;
  background-position: right center;
}
.btn-gradient-04:active {
  color: #fff;
  background-color: #e23f83;
  border-color: #e23f83;
}
.btn-gradient-04:focus,
.btn-gradient-04.focus {
  color: #fff;
  box-shadow: none;
}
.btn-gradient-04.disabled,
.btn-gradient-04:disabled {
  background-color: none;
  opacity: 0.3;
}
.btn-gradient-04:active,
.btn-gradient-04.active,
.show > .btn-gradient-04.dropdown-toggle {
  color: #fff;
  background-color: #e23f83;
  background-image: none;
}
.btn-gradient-05,
.btn-gradient-05 a {
  background: #bc0056;
  background: linear-gradient(to right, #bc0056 0%, #730062 100%);
  background-size: 200% auto;
  font-weight: 600;
  transition: 0.5s;
  color: #fff;
  border: 0 none;
  padding: 12px 20px;
}
.btn-gradient-05 i,
.btn-gradient-05 a i {
  display: inline-block;
  font-size: 1.2rem;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-gradient-05:hover,
.btn-gradient-05 a:hover {
  color: #fff;
  background-position: right center;
}
.btn-gradient-05:active {
  color: #fff;
  background-color: #e23f83;
  border-color: #e23f83;
}
.btn-gradient-05:focus,
.btn-gradient-05.focus {
  color: #fff;
  box-shadow: none;
}
.btn-gradient-05.disabled,
.btn-gradient-05:disabled {
  background-color: none;
  opacity: 0.3;
}
.btn-gradient-05:active,
.btn-gradient-05.active,
.show > .btn-gradient-05.dropdown-toggle {
  color: #fff;
  background-color: #e23f83;
  background-image: none;
}
.btn-primary {
  color: #fff;
  background-color: #5d5386;
  border-color: #5d5386;
}
.btn-primary i {
  color: #fff;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-primary:hover {
  color: #fff;
  background-color: #5d5386;
  border-color: #5d5386;
}
.btn-primary:active {
  color: #fff;
  background-color: #5d5386;
  border-color: #5d5386;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
  outline: none;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #9086b9;
  border-color: #9086b9;
}
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #594f82;
  border-color: #5d5386;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #5d5386;
  border-color: #5d5386;
}
.btn-secondary {
  color: #2c304d;
  background-color: #e4e8f0;
  border: 2px solid #e4e8f0;
}
.btn-secondary i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #2c304d;
  border-color: #2c304d;
}
.btn-secondary:focus,
.btn-secondary.focus {
  border-color: transparent;
  box-shadow: none;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #9e9ead;
  background-color: #edf0f5;
  border-color: #edf0f5;
}
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #2c304d;
  background-image: none;
}
.btn-info {
  color: #fff;
  background-color: #5bc0de;
}
.btn-info i {
  color: #fff;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #31b0d5;
}
.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 2px rgba(91, 192, 222, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  background-color: #5bc0de;
}
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  border-color: #31b0d5;
  background-image: none;
}
.btn-success {
  color: #fff;
  background-color: #5cb85c;
}
.btn-success i {
  color: #fff;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #449d44;
}
.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 2px rgba(92, 184, 92, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  background-color: #5cb85c;
}
.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #449d44;
  background-image: none;
}
.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
}
.btn-warning i {
  color: #fff;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #ec971f;
}
.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 2px rgba(240, 173, 78, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #f0ad4e;
}
.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  border-color: #ec971f;
  background-image: none;
}
.btn-danger {
  color: #fff;
  background-color: #fe195e;
}
.btn-danger i {
  color: #fff;
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #c9302c;
}
.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #fe195e;
}
.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #c9302c;
  background-image: none;
}
.btn-light {
  color: #2c304d;
  background-color: #f8f9fa;
}
.btn-light i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-light:hover {
  color: #2c304d;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  background-color: #fe195e;
}
.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #2c304d;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
  background-image: none;
}
.btn-dark {
  color: #fff;
  background-color: #2c304d;
}
.btn-dark i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-dark:hover {
  color: #fff;
  background-color: #2c304d;
  border-color: #2c304d;
}
.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #fe195e;
}
.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #2e324f;
  border-color: #2e324f;
  background-image: none;
}
.btn-outline-primary {
  color: #5d5386;
  border-color: #5d5386;
  background-image: none;
  background-color: transparent;
}
.btn-outline-primary i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #5d5386;
  border-color: #5d5386;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 2px rgba(121, 106, 238, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #796aee;
  background-color: transparent;
}
.btn-outline-primary:active,
.btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #5d5386;
  border-color: #5d5386;
}
.btn-outline-secondary {
  color: #2c304d;
  border-color: #e4e8f0;
  background-image: none;
  background-color: transparent;
}
.btn-outline-secondary i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #2c304d;
  border-color: #2c304d;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #afaeae;
  background-color: transparent;
}
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #2c304d;
  border-color: #2c304d;
}
.btn-outline-info {
  color: #5bc0de;
  border-color: #5bc0de;
  background-image: none;
  background-color: transparent;
}
.btn-outline-info i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 2px rgba(91, 192, 222, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #5bc0de;
  background-color: transparent;
}
.btn-outline-info:active,
.btn-outline-info.active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #5bc0de;
}
.btn-outline-success {
  color: #5cb85c;
  border-color: #5cb85c;
  background-image: none;
  background-color: transparent;
}
.btn-outline-success i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 2px rgba(92, 184, 92, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #5cb85c;
  background-color: transparent;
}
.btn-outline-success:active,
.btn-outline-success.active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #5cb85c;
}
.btn-outline-warning {
  color: #f0ad4e;
  border-color: #f0ad4e;
  background-image: none;
  background-color: transparent;
}
.btn-outline-warning i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 2px rgba(240, 173, 78, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #f0ad4e;
  background-color: transparent;
}
.btn-outline-warning:active,
.btn-outline-warning.active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f0ad4e;
}
.btn-outline-danger {
  color: #fe195e;
  border-color: #fe195e;
  background-image: none;
  background-color: transparent;
}
.btn-outline-danger i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #fe195e;
  border-color: #fe195e;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #fe195e;
  background-color: transparent;
}
.btn-outline-danger:active,
.btn-outline-danger.active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #fe195e;
}
.btn-outline-light {
  color: #2c304d;
  border-color: #f3f4f5;
  background-image: none;
  background-color: transparent;
}
.btn-outline-light i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-outline-light:hover {
  color: #2c304d;
  background-color: #f3f4f5;
  border-color: #f3f4f5;
}
.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f3f4f5;
  background-color: transparent;
}
.btn-outline-light:active,
.btn-outline-light.active,
.show > .btn-outline-light.dropdown-toggle {
  color: #2c304d;
  background-color: #f3f4f5;
}
.btn-outline-dark {
  color: #2c304d;
  border-color: #2c304d;
  background-image: none;
  background-color: transparent;
}
.btn-outline-dark i {
  font-size: 1.2rem;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.4rem;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #2c304d;
  border-color: #2c304d;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #2c304d;
  background-color: transparent;
}
.btn-outline-dark:active,
.btn-outline-dark.active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #2c304d;
}
.btn-lg {
  padding: 0.75rem 2.5rem;
  font-size: 1rem;
  border-radius: 4.2rem;
}
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.85rem;
}
.btn-square {
  border-radius: 0;
}
.btn-circle {
  border-radius: 50%;
  padding: 10px;
  text-align: center;
}
.btn-circle i {
  font-size: 1.6rem;
}
.button-nav {
  font-weight: 500;
}
.button-nav.nav-tabs {
  border: 0 none;
}
.button-nav.nav-tabs > li > a::after {
  display: none;
}
.button-nav * {
  transition: all 0.35s ease;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.button-nav li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 0.5rem;
  padding: 0;
}
.button-nav li:first-child {
  margin-left: 0;
}
.button-nav li:last-child {
  margin-right: 0;
}
.button-nav a {
  padding: 0.5rem 0;
  color: #2c304d;
  text-decoration: none;
  display: inline-block;
}
.button-nav a.active {
  background: #5d5386;
  padding: 0.5rem 1.07rem;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  border-radius: 35px;
}
.button-nav a.active:hover {
  color: #fff !important;
}
.button-nav a:hover {
  background: #5d5386 !important;
  padding: 0.5rem 1.07rem;
  text-decoration: none;
  display: inline-block;
  border-radius: 35px;
}
.button-nav.nav-tabs > li > a:hover {
  color: #fff !important;
}
.button-nav a:before {
  bottom: 0;
  left: 0;
}
.rounded-button {
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 1.4rem;
  text-align: center;
  border-radius: 50%;
}
a.rounded-button {
  color: #fff;
}
a:hover.rounded-button {
  opacity: 0.8;
}
.bg-gradient-01 {
  background: #fd584c;
  background: linear-gradient(135deg, #fd584c 0%, #f84271 80%) !important;
}
.bg-gradient-02 {
  background: #5d5386;
  background: linear-gradient(135deg, #5d5386 0%, #4c4275 80%) !important;
}
.bg-gradient-03 {
  background: #6859ea;
  background: linear-gradient(135deg, #6859ea 0%, #6acbe0 80%) !important;
}
.bg-gradient-04 {
  background: #00b7ff;
  background: linear-gradient(135deg, #00b7ff 0%, #ffffc7 100%) !important;
}
.bg-gradient-05 {
  background: #662d8c;
  background: linear-gradient(135deg, #662d8c 0%, #ed1e79 80%) !important;
}
.bg-primary,
.bg-violet {
  background-color: #5d5386 !important;
  color: #fff;
}
.bg-danger,
.bg-red {
  background-color: #fe195e !important;
  color: #fff;
}
.bg-info,
.bg-blue {
  background-color: #08a6c3 !important;
  color: #fff;
}
.bg-yellow {
  background-color: #eef157 !important;
  color: #fff;
}
.bg-success,
.bg-green {
  background-color: #69cd00 !important;
  color: #fff;
}
.bg-warning,
.bg-orange {
  background-color: #f7b733 !important;
  color: #fff;
}
.bg-grey {
  background-color: #f6f6f6 !important;
}
.bg-white {
  background-color: #fff !important;
}
.text-gradient-01 {
  background: #fd584c;
  background: linear-gradient(135deg, #fd584c 0%, #f84271 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-02 {
  background: #5d5386;
  background: linear-gradient(135deg, #5d5386 0%, #5d5386 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-03 {
  background: #6859ea;
  background: linear-gradient(135deg, #6859ea 0%, #6acbe0 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-04 {
  background: #00b7ff;
  background: linear-gradient(135deg, #00b7ff 0%, #ffffc7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-05 {
  background: #662d8c;
  background: linear-gradient(135deg, #662d8c 0%, #ed1e79 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-dark {
  color: #2c304d !important;
}
.text-primary {
  color: #5d5386 !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #5d5386 !important;
}
.text-secondary {
  color: #e76c90 !important;
}
.text-red {
  color: #fe195e !important;
}
.text-yellow {
  color: #eef157 !important;
}
.text-green {
  color: #60c400 !important;
}
.text-orange {
  color: #f7b733 !important;
}
.text-violet {
  color: #796aee !important;
}
.text-blue {
  color: #08a6c3 !important;
}
.text-grey-light {
  color: #e6e8e9 !important;
}
.text-grey-dark {
  color: #50525b !important;
}
.text-facebook {
  color: #3b5998 !important;
}
.text-twitter {
  color: #1da1f2 !important;
}
.text-linkedin {
  color: #007bb7 !important;
}
.text-youtube {
  color: #cd201f !important;
}
.progress {
  background-color: rgba(52, 40, 104, 0.07);
  font-size: 0.85rem;
  font-weight: 600;
}
.progress-sm {
  height: 6px;
}
.progress-lg {
  height: 18px;
}
span.badge-pulse {
  background: #fe195e;
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(254, 25, 94, 0.4);
  animation: BadgePulse 1.8s infinite;
}
@-webkit-keyframes BadgePulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(254, 25, 94, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(254, 25, 94, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(254, 25, 94, 0);
  }
}
@keyframes BadgePulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(254, 25, 94, 0.4);
    box-shadow: 0 0 0 0 rgba(254, 25, 94, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(254, 25, 94, 0);
    box-shadow: 0 0 0 10px rgba(254, 25, 94, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 20px rgba(254, 25, 94, 0);
    box-shadow: 0 0 0 20px rgba(254, 25, 94, 0);
  }
}
span.badge-pulse-green {
  background: #60c400;
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(105, 205, 0, 0.4);
  animation: BadgePulseGreen 1.8s infinite;
}
@-webkit-keyframes BadgePulseGreen {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(105, 205, 0, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(105, 205, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(105, 205, 0, 0);
  }
}
@keyframes BadgePulseGreen {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(105, 205, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(105, 205, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(105, 205, 0, 0);
    box-shadow: 0 0 0 10px rgba(105, 205, 0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 20px rgba(105, 205, 0, 0);
    box-shadow: 0 0 0 20px rgba(105, 205, 0, 0);
  }
}
span.badge-pulse-blue {
  background: #08a6c3;
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(8, 166, 195, 0.4);
  animation: BadgePulseBlue 1.8s infinite;
}
@-webkit-keyframes BadgePulseBlue {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(8, 166, 195, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(8, 166, 195, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(8, 166, 195, 0);
  }
}
@keyframes BadgePulseBlue {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(8, 166, 195, 0.4);
    box-shadow: 0 0 0 0 rgba(8, 166, 195, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(8, 166, 195, 0);
    box-shadow: 0 0 0 10px rgba(8, 166, 195, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 20px rgba(8, 166, 195, 0);
    box-shadow: 0 0 0 20px rgba(8, 166, 195, 0);
  }
}
span.badge-pulse-orange {
  background: #f7b733;
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(247, 183, 51, 0.4);
  animation: BadgePulseOrange 1.8s infinite;
}
@-webkit-keyframes BadgePulseOrange {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(278, 832, 541, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(247, 183, 51, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 20px rgba(247, 183, 51, 0);
  }
}
@keyframes BadgePulseOrange {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(247, 183, 51, 0.4);
    box-shadow: 0 0 0 0 rgba(247, 183, 51, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(247, 183, 51, 0);
    box-shadow: 0 0 0 10px rgba(247, 183, 51, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 20px rgba(247, 183, 51, 0);
    box-shadow: 0 0 0 20px rgba(247, 183, 51, 0);
  }
}
.badge {
  font-weight: 500;
}
.badge-text {
  background: #e4e8f0;
  color: #fff;
  padding: 0.4rem 0.7rem;
  border-radius: 50px;
  font-size: 0.9rem;
  text-align: center;
  display: inline-block;
  min-width: 20px;
  min-height: 20px;
  vertical-align: middle;
}
.badge-text.badge-text-small {
  padding: 0.1rem 0.9rem;
}
.badge-text.badge-text-wide {
  padding: 0.3rem 1.4rem;
}
.badge-text.success {
  background: #60c400;
}
.badge-text.info {
  background: #08a6c3;
}
.badge-text.danger {
  background: #fe195e;
}
.badge-rounded {
  background: #5d5386;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  width: 10px;
  height: 10px;
  vertical-align: middle;
}
.badge-rounded.badge-rounded-small {
  width: 25px;
  height: 25px;
  line-height: 25px;
}
.badge-rounded.success {
  background: #60c400;
}
.badge-rounded.info {
  background: #08a6c3;
}
.badge-rounded.danger {
  background: #fe195e;
}
.page {
  background: #f9f9f9;
}
.page.bg-2 {
  background: #fbfbfc;
}
.page .text-white {
  color: #fff;
}
.no-bg {
  background: none !important;
}
.no-shadow {
  box-shadow: none !important;
}
.no-border {
  border: 0 none !important;
}
.square {
  border-radius: 0;
}
.hide {
  display: none;
}
.hidden {
  overflow: hidden;
}
.no-padding {
  padding: 0 !important;
}
.no-padding-top {
  padding-top: 0 !important;
}
.no-padding-right {
  padding-right: 0 !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}
.no-padding-left {
  padding-left: 0 !important;
}
.padding-b-50 {
  padding-bottom: 50px !important;
}
.padding-top-5 {
  padding-top: 5px !important;
}
.padding-top-10 {
  padding-top: 10px !important;
}
.padding-top-15 {
  padding-top: 15px !important;
}
.padding-top-20 {
  padding-top: 20px !important;
}
.padding-top-25 {
  padding-top: 25px !important;
}
.padding-right-5 {
  padding-right: 5px !important;
}
.padding-right-10 {
  padding-right: 10px !important;
}
.padding-right-15 {
  padding-right: 15px !important;
}
.padding-right-20 {
  padding-right: 20px !important;
}
.padding-right-25 {
  padding-right: 25px !important;
}
.padding-bottom-5 {
  padding-bottom: 5px !important;
}
.padding-bottom-10 {
  padding-bottom: 10px !important;
}
.padding-bottom-15 {
  padding-bottom: 15px !important;
}
.padding-bottom-20 {
  padding-bottom: 20px !important;
}
.padding-bottom-25 {
  padding-bottom: 25px !important;
}
.padding-left-5 {
  padding-left: 5px !important;
}
.padding-left-10 {
  padding-left: 10px !important;
}
.padding-left-15 {
  padding-left: 15px !important;
}
.padding-left-20 {
  padding-left: 20px !important;
}
.padding-left-25 {
  padding-left: 25px !important;
}
.no-margin {
  margin: 0 !important;
}
.no-margin-top {
  margin-top: 0 !important;
}
.no-margin-right {
  margin-right: 0 !important;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.no-margin-left {
  margin-left: 0 !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-top-10 {
  margin-top: 10px !important;
}
.margin-top-15 {
  margin-top: 15px !important;
}
.margin-top-20 {
  margin-top: 20px !important;
}
.margin-top-25 {
  margin-top: 25px !important;
}
.margin-right-5 {
  margin-right: 5px !important;
}
.margin-right-10 {
  margin-right: 10px !important;
}
.margin-right-15 {
  margin-right: 15px !important;
}
.margin-right-20 {
  margin-right: 20px !important;
}
.margin-right-25 {
  margin-right: 25px !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.margin-bottom-15 {
  margin-bottom: 15px !important;
}
.margin-bottom-20 {
  margin-bottom: 20px !important;
}
.margin-bottom-25 {
  margin-bottom: 25px !important;
}
.margin-left-5 {
  margin-left: 5px !important;
}
.margin-left-10 {
  margin-left: 10px !important;
}
.margin-left-15 {
  margin-left: 15px !important;
}
.margin-left-20 {
  margin-left: 20px !important;
}
.margin-left-25 {
  margin-left: 25px !important;
}
.page {
  overflow-x: hidden;
}
.overflow-y {
  overflow-y: auto;
}
.has-shadow {
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.08);
}
.max-width-100 {
  max-width: 100px !important;
}
.max-width-150 {
  max-width: 150px !important;
}
.max-width-200 {
  max-width: 200px !important;
}
.max-width-250 {
  max-width: 250px !important;
}
.max-width-300 {
  max-width: 300px !important;
}
.max-width-350 {
  max-width: 350px !important;
}
.font-weight-300 {
  font-weight: 300;
}
.font-weight-300 {
  font-weight: 300;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
.em-separator {
  height: 0;
  margin: 20px 0;
}
.em-separator.separator-solid {
  border: 1px solid #eee;
}
.em-separator.separator-dashed {
  border: 1px dashed #eee;
}
.em-separator.separator-dotted {
  border: 1px dotted #eee;
}
.em-separator.separator-double {
  border: 1px double #eee;
}
.widget-options {
  z-index: 999;
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  float: right;
}
.widget-options .dropdown-toggle {
  color: #98a8b4;
  background: none;
  border: none;
  padding: 0;
  font-size: 1.7rem;
}
.widget-options .dropdown-toggle:after {
  display: none;
}
.widget-options .dropdown-menu {
  border: none;
  min-width: auto;
  font-size: 1rem;
  border-radius: 4px;
  padding: 1.4rem 1.8rem;
  text-align: left;
  transform: translateX(calc(-100% + 5px));
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.15);
}
.widget-options .dropdown-menu .dropdown-item {
  padding: 0.5rem 0;
}
.widget-options .dropdown-menu .dropdown-item:hover,
.widget-options .dropdown-menu .dropdown-item:focus {
  background: none;
}
.widget-options .dropdown-menu a {
  color: #2c304d;
  font-weight: 500;
}
.widget-options .dropdown-menu a:hover,
.widget-options .dropdown-menu a:hover i {
  background: transparent;
  color: #5d5386;
}
.widget-options .dropdown-menu a i {
  font-size: 1.6rem;
  vertical-align: middle;
  color: #aea9c3;
  margin-right: 0.7rem;
  transition: all 0.4s ease;
}
.widget-options .btn {
  padding: 8px 15px;
}
.quick-actions {
  z-index: 999;
  position: absolute;
  top: 10px;
  right: 20px;
}
.quick-actions.hover {
  z-index: 999;
  position: absolute;
  top: 10px;
  right: 20px;
  display: none;
}
.widget-image:hover .quick-actions.hover {
  display: block;
}
.quick-actions.light .dropdown-toggle {
  color: #fff;
}
.quick-actions.dark .dropdown-toggle {
  color: #2c304d;
}
.quick-actions .dropdown-toggle {
  color: #98a8b4;
  background: none;
  border: none;
  padding: 0;
  font-size: 1.7rem;
}
.quick-actions .dropdown-toggle:after {
  display: none;
}
.quick-actions .dropdown-menu {
  border: none;
  min-width: auto;
  font-size: 1rem;
  border-radius: 4px;
  padding: 1.4rem 1.8rem;
  text-align: left;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.15);
}
.quick-actions .dropdown-menu .dropdown-item {
  padding: 0.5rem 0;
}
.quick-actions .dropdown-menu .dropdown-item:hover,
.quick-actions .dropdown-menu .dropdown-item:focus {
  background: none;
}
.quick-actions .dropdown-menu a {
  color: #2c304d;
  font-weight: 500;
}
.quick-actions .dropdown-menu a:hover,
.quick-actions .dropdown-menu a:hover i {
  background: transparent;
  color: #5d5386;
}
.quick-actions .dropdown-menu a i {
  font-size: 1.4rem;
  vertical-align: -2px;
  color: #2c304d;
  margin-right: 0.7rem;
  transition: all 0.4s ease;
}
.actions {
  z-index: 999;
  display: block;
}
.actions.light .dropdown-toggle {
  color: #fff;
}
.actions.dark .dropdown-toggle {
  color: #2c304d;
}
.actions .dropdown-toggle {
  color: #98a8b4;
  background: none;
  border: none;
  padding: 0;
  font-size: 1.7rem;
}
.actions .dropdown-toggle:after {
  display: none;
}
.actions .dropdown-menu {
  border: none;
  min-width: auto;
  font-size: 1rem;
  border-radius: 4px;
  padding: 1.4rem 1.8rem;
  text-align: left;
  box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.15);
}
.actions .dropdown-menu .dropdown-item {
  padding: 0.5rem 0;
}
.actions .dropdown-menu .dropdown-item:hover,
.actions .dropdown-menu .dropdown-item:focus {
  background: none;
}
.actions .dropdown-menu a {
  color: #2c304d;
  font-weight: 500;
}
.actions .dropdown-menu a:hover,
.actions .dropdown-menu a:hover i {
  background: transparent;
  color: #5d5386;
}
.actions .dropdown-menu a i {
  font-size: 1.4rem;
  vertical-align: -2px;
  color: #2c304d;
  margin-right: 0.7rem;
  transition: all 0.4s ease;
}
.rounded-widget .widget {
  border-radius: 4px;
}
.rounded-widget .widget-image {
  border-radius: 4px;
}
.rounded-widget .widget-header {
  border-radius: 4px 4px 0 0;
}
.rounded-widget .widget-footer {
  border-radius: 0 0 4px 4px;
}
.widget {
  background: #fff;
  border-radius: 0;
  border: none;
  margin-bottom: 30px;
}
.widget-image {
  background: #fff;
  border-radius: 0;
  border: none;
  margin-bottom: 30px;
  position: relative;
}
.widget-header {
  background: #fff;
  padding: 0.85rem 1.4rem;
  position: relative;
  width: 100%;
}
.widget-header.bordered {
  border-bottom: 0.07rem solid #eee;
}
.widget-header.no-actions {
  padding: 1.49rem;
}
.widget-header h1,
.widget-header h2,
.widget-header h3,
.widget-header h4,
.widget-header h5,
.widget-header h6 {
  color: #2c304d;
  margin-bottom: 0;
}
.section-title h1,
.section-title h2,
.section-title h3,
.section-title h4,
.section-title h5,
.section-title h6 {
  color: #2c304d;
  font-weight: 600;
  margin-bottom: 0;
}
.widget-header h2 {
  color: #2c304d;
  font-size: 1.2rem;
  display: table-cell;
  vertical-align: middle;
  margin-right: auto;
}
.widget-header h2 i {
  color: #aea9c3;
  font-size: 1.8rem;
  padding-right: 0.5rem;
  vertical-align: middle;
}
.widget-body {
  padding: 1.4rem;
}
.widget-image-footer {
  background: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.2rem 0;
  z-index: 1;
}
.widget-footer {
  background: #fff;
  padding: 1rem 1.07rem;
  position: relative;
}
.widget-footer.big {
  padding: 2.1rem 1.07rem;
}
ul.social-network {
  list-style: none;
  margin-left: 0 !important;
  padding: 0;
}
ul.social-network li {
  display: inline;
  margin: 0;
}
.social-network a.ico-facebook:hover {
  color: #3b5998;
}
.social-network a.ico-linkedin:hover {
  color: #007bb7;
}
.social-network a.ico-rss:hover {
  color: #f26522;
}
.social-network a.ico-twitter:hover {
  color: #3cf;
}
.social-network a.ico-youtube:hover {
  color: #cd201f;
}
.social-network li a {
  color: #aea9c3;
  display: inline-block;
  position: relative;
  font-size: 2rem;
  width: 45px;
  text-align: center;
  transition: all 0.4s;
}
.social-network li i {
  margin: 0;
  line-height: 50px;
  text-align: center;
}
.social-network li a:hover i {
  -webkit-transform: rotate(360deg);
  -ms--transform: rotate(360deg);
  transform: rotate(360deg);
  transition: all 0.4s;
}
.social-network i {
  transition: all 0.4s;
}
.table thead {
  background-color: rgba(52, 40, 104, 0.05);
  border-radius: 4px;
}
.table thead th {
  vertical-align: middle;
  border: 0 none;
  padding: 20px 12px 20px 12px;
  color: #5d5386;
  font-weight: 600;
}
.table td {
  border-top: 0;
  padding: 0.85rem;
  vertical-align: middle;
  font-weight: 500;
}
.table-hover tbody tr:hover {
  background: rgba(52, 40, 104, 0.04);
}
.table-bordered th,
.table-bordered td {
  border: 0.07rem solid #eee;
}
.table .td-actions a i {
  background: transparent;
  color: rgba(52, 40, 104, 0.4);
  font-size: 1.6rem;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.4s ease;
}
.table .td-actions a i.edit:hover,
.table .td-actions a i.more:hover {
  background: rgba(52, 40, 104, 0.8);
  color: #fff;
}
.table .td-actions a i.delete:hover {
  background: #e76c90;
  color: #fff;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: rgba(52, 40, 104, 0.02);
  color: #5d5386;
}
.dataTables_wrapper.container-fluid {
  padding: 0 1.07rem;
}
.dt-buttons {
  float: left;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-radius: 50%;
  font-size: 1rem;
  margin-right: 5px;
}
.page-item:last-child .page-link {
  border-radius: 50%;
  font-size: 1rem;
  margin-left: 5px;
}
.page-item.active .page-link {
  z-index: 2;
  color: #5d5386;
  background-color: transparent;
  font-weight: 600;
}
.page-item.disabled .page-link {
  color: rgba(52, 40, 104, 0.15);
  pointer-events: none;
  cursor: not-allowed;
}
.page-link {
  padding: 0.5rem;
  line-height: 1rem;
  text-align: center;
  color: #aea9c3;
  margin: 0 0.3rem;
  background-color: transparent;
  border: 0 none;
  font-size: 1rem;
}
.page-link:focus,
.page-link:hover {
  color: #5d5386;
  text-decoration: none;
  background-color: transparent;
  box-shadow: none;
}
.breadcrumb {
  background: none;
  z-index: 50;
  border-radius: 0;
  margin: 0;
  padding: 0;
}
.breadcrumb li.breadcrumb-item {
  color: #94a4b0;
  font-weight: 500;
}
.breadcrumb li.breadcrumb-item i {
  font-size: 1.2rem;
  vertical-align: middle;
  display: inline-block;
}
.breadcrumb-item + .breadcrumb-item::before {
  color: #94a4b0;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: "-";
}
.modal {
  z-index: 1200;
}
.modal-top .modal-dialog {
  margin-top: 0 !important;
}
.modal-header {
  color: #2c304d;
  border-bottom: 0.07rem solid #eee;
}
.modal-header .close {
  font-size: 2rem;
}
.close {
  color: #d2d1d5;
  opacity: 0.8;
}
.modal-content {
  border: 0 none;
}
.modal-backdrop.show {
  opacity: 1;
}
.modal-backdrop {
  background: rgba(46, 52, 81, 0.8);
}
.modal-dialog-centered .modal-content {
  border-radius: 4px;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="city"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
select,
textarea {
  outline: none;
  box-shadow: none !important;
}
.form-control {
  font-size: 1rem;
  line-height: 1.25;
  /* color: #2c304d; */
  color: black;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 0.75rem 1.07rem;
}
.form-control::-moz-placeholder {
  font-size: 0.85rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #94a4b0;
}
.form-control::-webkit-input-placeholder {
  font-size: 0.85rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #94a4b0;
}
.form-control:-ms-input-placeholder {
  font-size: 0.85rem;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #94a4b0;
}
select.form-control:not([size]):not([multiple]) {
  height: calc(2.85rem + 2px);
}
.form-control:focus,
.has-success .form-control:focus,
.has-danger .form-control:focus,
.has-warning .form-control:focus,
.has-info .form-control:focus {
  border-color: #5d5386;
}
.has-success .form-control {
  border-color: #5cb85c;
}
.has-danger .form-control {
  border-color: #fe195e;
}
.has-warning .form-control {
  border-color: #f0ad4e;
}
.has-info .form-control {
  border-color: #31b0d5;
}
.form-control-label {
  color: #2c304d;
  font-weight: 500;
}
.form-control-plaintext {
  color: #2c304d;
  font-weight: 300;
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.invalid-feedback {
  margin: 0;
  font-size: 0.95rem;
  color: #fe195e;
}
button,
input[type="submit"] {
  cursor: pointer;
  font-family: inherit;
  font-weight: 300 !important;
}
form label {
  color: #2c304d;
  font-weight: 500;
}
.input-group span.input-group-addon {
  font-weight: 500;
  border-radius: 0;
  border: 0 none;
  padding: 0.75rem 1.07rem;
  text-align: center;
}
.input-group span.input-group-addon i {
  font-size: 1.4rem;
}
.input-group span.input-group-addon.addon-primary {
  background-color: #5d5386;
  color: #fff;
}
.input-group span.input-group-addon.addon-secondary {
  background-color: #e4e8f0;
  color: #2c304d;
}
.input-group span.input-group-addon.addon-orange {
  background-color: #e76c90;
  color: #fff;
}
.input-group .input-group-btn .btn {
  padding: 0.9rem 1.07rem;
  border-radius: 0;
  font-weight: 500;
}
.input-group .input-group-btn .btn i {
  font-size: 1.4rem;
}
.input-group .dropdown-toggle {
  background: #f5f5f5;
  color: #94a4b0;
}
.input-group ul.dropdown-menu {
  padding: 15px;
  color: #94a4b0;
  border-radius: 0;
}
.input-group ul.dropdown-menu a {
  padding: 5px 0;
  color: inherit;
  text-decoration: none;
}
.input-group ul.dropdown-menu a:hover {
  color: #5d5386;
}
.material-input input {
  font-size: 1rem;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #e4e8f0;
}
.material-input input:focus {
  outline: none;
}
.material-input label {
  color: #98a8b4;
  font-size: 0.9rem;
  font-weight: 400;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.material-input input:focus ~ label,
.material-input input:valid ~ label {
  top: -20px;
  font-size: 0.8rem;
  color: #5d5386;
}
.material-input .bar {
  position: relative;
  display: block;
  width: 100%;
}
.material-input .bar:before,
.material-input .bar:after {
  content: "";
  height: 1px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5d5386;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.material-input .bar:before {
  left: 50%;
}
.material-input .bar:after {
  right: 50%;
}
.material-input input:focus ~ .bar:before,
.material-input input:focus ~ .bar:after {
  width: 50%;
}
.material-input .highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}
.material-input input:focus ~ .highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}
@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }
  to {
    width: 0;
    background: transparent;
  }
}
.modal-content {
  border-radius: 0;
}
.i-checks {
  display: flex;
}
section.forms p {
  font-size: 0.9rem;
  color: #555;
}
section.forms form span,
section.forms form small {
  font-size: 0.85rem;
  color: #999;
  font-weight: 300;
}
.styled-radio {
  position: relative;
  padding-left: 35px;
  text-align: left;
}
.styled-radio label {
  cursor: pointer;
}
.styled-radio label:before,
.styled-radio label:after {
  content: "";
  position: absolute;
  top: 50%;
  border-radius: 50%;
}
.styled-radio label:before {
  left: 0;
  width: 20px;
  height: 20px;
  margin: -15px 0 0;
  background: rgba(52, 40, 104, 0.1);
  box-shadow: 0 0 1px #eee;
}
.styled-radio label:after {
  left: 5px;
  width: 10px;
  height: 10px;
  margin: -10px 0 0;
  opacity: 0;
  background: #5d5386;
  -webkit-transform: translate3d(-15px, 0, 0) scale(0.5);
  transform: translate3d(-15px, 0, 0) scale(0.5);
  -webkit-transition: opacity 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out,
    -webkit-transform 0.25s ease-in-out;
}
.styled-radio input[type="radio"] {
  position: absolute;
  top: 0;
  left: -9999px;
  visibility: hidden;
}
.radio-disabled,
.radio-disabled.styled-radio label {
  cursor: not-allowed;
  opacity: 0.8;
}
.styled-radio input[type="radio"]:checked + label:after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.styled-checkbox {
  position: relative;
  padding-left: 35px;
  text-align: left;
}
.styled-checkbox label {
  cursor: pointer;
}
.styled-checkbox label:before,
.styled-checkbox label:after {
  content: "";
  position: absolute;
  top: 50%;
  border-radius: 4px;
}
.styled-checkbox label:before {
  left: 0;
  width: 20px;
  height: 20px;
  margin: -15px 0 0;
  background: rgba(52, 40, 104, 0.1);
  box-shadow: 0 0 1px #eee;
}
.styled-checkbox label:after {
  font-family: "Ionicons";
  content: "\f383";
  font-size: 1rem;
  left: 5px;
  margin: -15px 0 0;
  opacity: 0;
  color: #5d5386;
  -webkit-transform: translate3d(-15px, 0, 0) rotate(90deg);
  transform: translate3d(-15px, 0, 0) rotate(90deg);
  -webkit-transition: opacity 0.2s ease-in-out,
    -webkit-transform 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out,
    -webkit-transform 0.2s ease-in-out;
}
.styled-checkbox input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: -9999px;
  visibility: hidden;
}
.checkbox-disabled,
.checkbox-disabled.styled-checkbox label {
  cursor: not-allowed;
  opacity: 0.8;
}
.styled-checkbox input[type="checkbox"]:checked + label:after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.checkbox {
  cursor: pointer;
  border-radius: 50%;
  display: block;
  color: rgba(52, 40, 104, 0.3);
  transition: color 0.8s ease;
}
.checkbox:hover:after {
  color: rgba(52, 40, 104, 0.4);
}
.checkbox:after {
  line-height: 40px;
  font-family: "Ionicons";
  display: block;
  content: "";
  text-align: center;
  font-size: 1.4rem;
  transition: color 0.5s ease;
}
.check:after {
  content: "\f383";
}
.check.is-checked:after {
  color: #60c400;
}
.heart:after {
  content: "\f141";
}
.heart.is-checked:after {
  color: #f14c38;
}
.star:after {
  content: "\f24e";
}
.star.is-checked:after {
  color: #fcc525;
}
.toggle-checkbox {
  display: none;
}
.toggle-checkbox + span {
  width: 60px;
  height: 30px;
  border-radius: 35px;
  background-color: #dfdde7;
  transition-duration: 0.3s;
  transition-property: background-color;
  cursor: pointer;
}
.toggle-checkbox:checked + span {
  background-color: #5d5386;
}
.toggle-checkbox + span > span {
  float: left;
  width: 26px;
  height: 26px;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 50%;
  background-color: white;
  transition-timing-function: ease;
  transition-duration: 0.3s;
  transition-property: transform;
  pointer-events: none;
}
.toggle-checkbox:checked + span > span {
  transform: translateX(30px);
}
.toggle-checkbox-sm {
  display: none;
}
.toggle-checkbox-sm + span {
  width: 50px;
  height: 25px;
  border-radius: 35px;
  background-color: #dfdde7;
  transition-duration: 0.3s;
  transition-property: background-color;
  cursor: pointer;
}
.toggle-checkbox-sm:checked + span {
  background-color: #5d5386;
}
.toggle-checkbox-sm + span > span {
  float: left;
  width: 20px;
  height: 20px;
  margin-top: 2px;
  margin-left: 2px;
  border-radius: 50%;
  background-color: white;
  transition-timing-function: ease;
  transition-duration: 0.3s;
  transition-property: transform;
  pointer-events: none;
}
.toggle-checkbox-sm:checked + span > span {
  transform: translateX(25px);
}
.step-container {
  position: relative;
  min-height: 100px;
  display: block;
  margin-bottom: 2rem;
}
.step-wizard {
  display: inline-block;
  position: relative;
  width: 100%;
}
.step-wizard .progress {
  position: absolute;
  top: 23px;
  height: 8px;
  left: 0;
  width: 100%;
}
.step-wizard .progressbar {
  position: absolute;
  background-color: #5d5386;
  height: 8px;
  border: 0 none;
  width: 0%;
  transition: width 0.6s ease;
}
.step-wizard ul {
  position: absolute;
  width: 100%;
  list-style-type: none;
  padding: 0;
}
.step-wizard li {
  display: inline-block;
  text-align: center;
  width: 30%;
}
.step-wizard li .step {
  position: absolute;
  display: inline-block;
  font-size: 1.2rem;
  line-height: 42px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 6px solid;
  border-color: #5d5386;
  background: #fff;
  transition: background-color 0.6s ease, border-color 0.6s ease;
}
.step-wizard li .title {
  position: absolute;
  width: 100%;
  left: 28px;
  padding-top: 60px;
  color: rgba(52, 40, 104, 0.4);
  transition: color 0.6s ease;
}
.step-wizard .active .step {
  color: #fff;
  background-color: #5d5386;
  border-color: #5d5386;
}
.step-wizard .active .title {
  color: #e76c90;
  font-weight: 500;
}
.step-wizard li > a {
  display: block;
  width: 100%;
  color: #2c304d;
  position: relative;
  text-align: center;
}
.step-wizard a:hover .step {
  color: #fff;
  background-color: #5d5386;
}
.step-wizard a:hover .title {
  color: #5d5386;
}
@media only screen and (max-width: 1200px) {
  .step-wizard li {
    width: 30%;
  }
}
@media only screen and (max-width: 578px) {
  .step-wizard li {
    width: 25%;
  }
}
.sa-icon {
  width: 80px;
  height: 80px;
  border: 4px solid gray;
  border-radius: 50%;
  margin: 20px auto;
  padding: 0;
  position: relative;
  box-sizing: content-box;
}
.sa-icon.sa-success .sa-line {
  height: 5px;
  background-color: #e76c90;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}
.sa-icon.sa-success .sa-line.sa-tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sa-icon.sa-success .sa-line.sa-long {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sa-icon.sa-success .sa-placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(228, 232, 240, 0.8);
  -webkit-border-radius: 40px;
  border-radius: 40px;
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2;
}
.sa-icon.sa-success .sa-fix {
  width: 5px;
  height: 90px;
  background-color: #fff;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sa-icon.sa-success {
  border-color: #5d5386;
}
.sa-icon.sa-success {
  border-color: transparent\9;
}
.sa-icon.sa-success .sa-line.sa-tip {
  -ms-transform: rotate(45deg) \9;
}
.sa-icon.sa-success .sa-line.sa-long {
  -ms-transform: rotate(-45deg) \9;
}
.sa-icon.sa-success:before,
.sa-icon.sa-success:after {
  content: "";
  -webkit-border-radius: 40px;
  border-radius: 40px;
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  background: white;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sa-icon.sa-success::before {
  -webkit-border-radius: 120px 0 0 120px;
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
}
.sa-icon.sa-success:after {
  -webkit-border-radius: 0 120px 120px 0;
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 60px;
  transform-origin: 0 60px;
}
@-webkit-keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}
@-webkit-keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@-webkit-keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg);
  }
}
@keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg);
  }
}
.animateSuccessTip {
  -webkit-animation: animateSuccessTip 1s;
  animation: animateSuccessTip 1s;
}
.animateSuccessLong {
  -webkit-animation: animateSuccessLong 1.4s;
  animation: animateSuccessLong 1.4s;
}
.sa-icon.sa-success.animate::after {
  -webkit-animation: rotatePlaceholder 8s ease-in;
  animation: rotatePlaceholder 8s ease-in;
}
.timeline {
  width: 100%;
  position: relative;
  padding: 1px 0;
  list-style: none;
  font-weight: 500;
}
.timeline .timeline-item {
  padding-left: 0;
  padding-right: 30px;
}
.timeline .timeline-item.timeline-item-right,
.timeline .timeline-item:nth-of-type(even):not(.timeline-item-left) {
  padding-left: 30px;
  padding-right: 0;
}
.timeline .timeline-item .timeline-event {
  width: 100%;
}
.timeline:before {
  border-right-style: solid;
}
.timeline:before,
.timeline:after {
  content: " ";
  display: block;
}
.timeline:after {
  clear: both;
}
.timeline:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50%;
  height: 100% !important;
  margin-left: 2px;
  border-right-width: 4px;
  border-right-style: solid;
  border-right-color: rgba(52, 40, 104, 0.1);
}
.timeline .timeline-label .label {
  background-color: #e76c90;
  border-radius: 35px;
  color: #fff;
  display: inline;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1;
  padding: 0.65rem 1.4rem;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}
.timeline.timeline-line-solid:before {
  border-right-style: solid;
}
.timeline.timeline-line-dotted:before {
  border-right-style: dotted;
}
.timeline.timeline-line-dashed:before {
  border-right-style: dashed;
}
.timeline .timeline-item {
  position: relative;
  float: left;
  clear: left;
  width: 50%;
  margin-bottom: 20px;
}
.timeline .timeline-item:before,
.timeline .timeline-item:after {
  content: "";
  display: table;
}
.timeline .timeline-item:after {
  clear: both;
}
.timeline .timeline-item:last-child {
  margin-bottom: 0 !important;
}
.timeline .timeline-item.timeline-item-right > .timeline-event,
.timeline
  .timeline-item:nth-of-type(even):not(.timeline-item-left)
  > .timeline-event {
  float: right !important;
}
.timeline .timeline-item.timeline-item-right > .timeline-event:before,
.timeline
  .timeline-item:nth-of-type(even):not(.timeline-item-left)
  > .timeline-event:before,
.timeline .timeline-item.timeline-item-right > .timeline-event:after,
.timeline
  .timeline-item:nth-of-type(even):not(.timeline-item-left)
  > .timeline-event:after {
  right: auto !important;
  border-left-width: 0 !important;
}
.timeline .timeline-item.timeline-item-right > .timeline-event:before,
.timeline
  .timeline-item:nth-of-type(even):not(.timeline-item-left)
  > .timeline-event:before {
  left: -15px !important;
  border-right-width: 15px !important;
}
.timeline .timeline-item.timeline-item-right > .timeline-event:after,
.timeline
  .timeline-item:nth-of-type(even):not(.timeline-item-left)
  > .timeline-event:after {
  left: -14px !important;
  border-right-width: 14px !important;
}
.timeline .timeline-item > .timeline-event:before {
  top: 10px;
  right: -15px;
  border-top: 15px solid transparent;
  border-left-width: 15px;
  border-left-style: solid;
  border-right-width: 0;
  border-right-style: solid;
  border-bottom: 15px solid transparent;
}
.timeline .timeline-item > .timeline-event:after {
  top: 11px;
  right: -14px;
  border-top: 14px solid transparent;
  border-left-width: 14px;
  border-left-style: solid;
  border-right-width: 0;
  border-right-style: solid;
  border-bottom: 14px solid transparent;
}
.timeline .timeline-item > .timeline-point {
  top: 25px;
}
.timeline .timeline-item:nth-of-type(2) {
  margin-top: 40px;
}
.timeline .timeline-item.timeline-item-left,
.timeline .timeline-item.timeline-item-right {
  clear: both !important;
}
.timeline .timeline-item.timeline-item-right,
.timeline .timeline-item:nth-of-type(even):not(.timeline-item-left) {
  float: right;
  clear: right;
}
.timeline .timeline-item.timeline-item-right > .timeline-point,
.timeline
  .timeline-item:nth-of-type(even):not(.timeline-item-left)
  > .timeline-point {
  left: -14px;
}
.timeline .timeline-item > .timeline-event {
  position: relative;
  float: left;
}
.timeline .timeline-item > .timeline-event:before {
  border-left-color: rgba(52, 40, 104, 0.04);
  border-right-color: rgba(52, 40, 104, 0.04);
}
.timeline .timeline-item > .timeline-event:after {
  border-left-color: rgba(52, 40, 104, 0.04);
  border-right-color: rgba(52, 40, 104, 0.04);
}
.timeline .timeline-item > .timeline-event:before,
.timeline .timeline-item > .timeline-event:after {
  content: "";
  display: inline-block;
  position: absolute;
}
.timeline .timeline-item > .timeline-point {
  color: #5d5386;
  background: #5d5386;
  right: -14px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  margin-left: 8px;
  margin-right: 8px;
  position: absolute;
  z-index: 100;
  border-width: 3px;
  border-style: solid;
  border-radius: 100%;
  line-height: 20px;
  text-align: center;
  box-shadow: 0 0 0 5px #f2f3f8;
}
.timeline .timeline-label {
  position: relative;
  float: left;
  clear: left;
  width: 50%;
  margin-bottom: 20px;
  top: 1px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  text-align: center;
}
.timeline .timeline-label:before,
.timeline .timeline-label:after {
  content: "";
  display: table;
}
.timeline .timeline-label:after {
  clear: both;
}
.timeline .timeline-label:last-child {
  margin-bottom: 0 !important;
}
.timeline .timeline-label + .timeline-item {
  margin-top: 0;
}
.timeline .timeline-label + .timeline-item + .timeline-item {
  margin-top: 40px;
}
.timeline .time-right {
  color: rgba(52, 40, 104, 0.5);
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 0.85rem;
  font-weight: 500;
}
.timeline .time-left {
  color: rgba(52, 40, 104, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 0.85rem;
  font-weight: 500;
}
.timeline .widget-header .user-image {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.07rem;
}
.timeline .widget-header .user-image img {
  width: 50px;
}
.timeline .widget-header .title {
  color: #2c304d;
  font-size: 1rem;
}
.timeline .widget-header .username {
  color: #e76c90;
}
.timeline .widget-header .time {
  font-size: 0.85rem;
}
.timeline .widget-body {
  padding: 1rem 1.4rem;
}
.timeline .widget-footer {
  border-top: 1px solid #eee;
  margin: 0 1.4rem;
  padding: 1.07rem 0;
}
.timeline .users-like {
  padding: 0;
}
.timeline .users-like a {
  margin: 0 -1.6rem 0 0;
  transition: all 0.4s ease;
}
.timeline .users-like a:hover {
  margin-right: -0.3rem;
}
.timeline .users-like img {
  width: 40px;
  border: 0.25rem solid #fff;
}
.timeline .users-like a.view-more {
  background: #5d5386;
  color: #fff;
  width: 40px;
  height: 40px;
  border: 0.25rem solid #fff;
  border-radius: 50%;
  vertical-align: middle;
  font-size: 0.85rem;
  text-align: center;
  line-height: 30px;
  margin-right: 0;
}
.timeline .widget-footer .meta li {
  display: inline-block;
  margin-right: 0.5rem;
}
.timeline .widget-footer .meta li:last-child {
  margin-right: 0;
}
.timeline .widget-footer .meta li a {
  color: rgba(52, 40, 104, 0.3);
}
.timeline .widget-footer .meta li a:hover {
  color: rgba(52, 40, 104, 0.9);
}
.timeline .widget-footer .meta li i {
  font-size: 1.8rem;
  vertical-align: middle;
  margin-right: 0.3rem;
}
.timeline .widget-footer .meta li .numb {
  vertical-align: middle;
}
@media screen and (max-width: 768px) {
  .timeline.timeline {
    width: 100%;
    max-width: 100%;
  }
  .timeline.timeline .timeline-item {
    padding-left: 72px;
    padding-right: 0;
  }
  .timeline.timeline .timeline-item.timeline-item-right,
  .timeline.timeline .timeline-item:nth-of-type(even):not(.timeline-item-left) {
    padding-left: 72px;
    padding-right: 0;
  }
  .timeline.timeline .timeline-item .timeline-event {
    width: 100%;
  }
  .timeline.timeline:before {
    left: 42px;
    width: 0;
    margin-left: -1px;
  }
  .timeline.timeline .timeline-item {
    width: 100%;
    margin-bottom: 20px;
  }
  .timeline.timeline .timeline-item:nth-of-type(even) {
    margin-top: 0;
  }
  .timeline.timeline .timeline-item > .timeline-event {
    float: right !important;
  }
  .timeline.timeline .timeline-item > .timeline-event:before,
  .timeline.timeline .timeline-item > .timeline-event:after {
    right: auto !important;
    border-left-width: 0 !important;
  }
  .timeline.timeline .timeline-item > .timeline-event:before {
    left: -15px !important;
    border-right-width: 15px !important;
  }
  .timeline.timeline .timeline-item > .timeline-event:after {
    left: -14px !important;
    border-right-width: 14px !important;
  }
  .timeline.timeline .timeline-item > .timeline-point {
    transform: translateX(-50%);
    left: 42px !important;
    margin-left: 0;
  }
  .timeline.timeline .timeline-label {
    transform: translateX(-50%);
    margin: 0 0 20px 42px;
  }
  .timeline.timeline .timeline-label + .timeline-item + .timeline-item {
    margin-top: 0;
  }
  .timeline .time-right {
    left: 0;
  }
}
.nav-tabs {
  border-bottom: 2px solid #eee;
}
.nav-tabs .nav-item {
  position: relative;
}
.nav-link {
  display: block;
  padding: 0.7rem 1rem;
}
.nav-tabs .nav-link {
  border: 0 none;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #e76c90;
  background-color: #fff;
  border-bottom: 2px solid #5d5386 !important;
  margin-bottom: -1px;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border: 0 none;
}
.nav-tabs .nav-link i {
  color: #aea9c3;
}
.nav-tabs > li > a {
  border: none;
}
.nav-tabs > li.active > a,
.nav-tabs > li > a:hover {
  border: none;
  color: #5d5386 !important;
  background: transparent;
}
.nav-tabs > li > a::after {
  content: "";
  background: #5d5386;
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -1px;
  transition: all 0.2s ease 0s;
  transform: scale(0);
}
.nav-tabs > li.active > a::after,
.nav-tabs > li:hover > a::after {
  transform: scale(1);
}
.nav-tabs .nav-link.disabled {
  color: #98a8b4;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.nav-tabs .dropdown-toggle::after {
  border: 0 none;
}
.nav-tabs .dropdown-item {
  color: #5d5386;
  padding: 0.65rem 1.5rem;
}
.nav-tabs .dropdown-item.active,
.nav-tabs .dropdown-item:active {
  color: #fff !important;
  background-color: #5d5386;
}
.nav-left.nav-tabs {
  float: left;
  border-bottom: 0;
  border-radius: 0;
  display: table;
  padding: 0.65rem 1.07rem;
}
.nav-left.nav-tabs .nav-item {
  text-align: center;
}
.nav-link i {
  color: #aea9c3;
}
.alert {
  color: #fff;
  position: relative;
  padding: 0.85rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-lg {
  padding: 1.25rem;
}
.alert i {
  font-size: 2rem;
  vertical-align: middle;
}
.alert .close::before {
  content: "\f129";
}
.alert .close {
  font-family: "ionicons";
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  font-size: 1rem;
  outline: none !important;
  text-shadow: none;
  cursor: pointer;
}
.alert .close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  padding-right: 1.25rem;
  color: inherit;
  opacity: 1;
}
.alert-primary .close,
.alert-succes .close,
.alert-info .close,
.alert-warning .close,
.alert-danger .close {
  color: #fff;
}
.alert-secondary .close {
  color: #2c304d;
}
.alert-shadow {
  color: #2c304d;
  background-color: #fff;
  border-color: #eee;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.15);
}
.alert-primary {
  color: #fff;
  background-color: #5d5386;
  border-color: #5d5386;
}
.alert-secondary {
  color: #2c304d;
  background-color: #e4e8f0;
  border-color: #e4e8f0;
}
.alert-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.alert-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.alert-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.alert-danger {
  color: #fff;
  background-color: #c9302c;
  border-color: #c9302c;
}
.alert-outline-primary.dashed,
.alert-outline-secondary.dashed,
.alert-outline-success.dashed,
.alert-outline-info.dashed,
.alert-outline-warning.dashed,
.alert-outline-danger.dashed {
  border-style: dashed;
}
.alert-outline-primary.dotted,
.alert-outline-secondary.dotted,
.alert-outline-success.dotted,
.alert-outline-info.dotted,
.alert-outline-warning.dotted,
.alert-outline-danger.dotted {
  border-style: dotted;
}
.alert-outline-primary {
  color: #5d5386;
  border-color: #5d5386;
  border-width: 2px;
}
.alert-outline-secondary {
  color: #2c304d;
  border-color: #e4e8f0;
  border-width: 2px;
}
.alert-outline-success {
  color: #5cb85c;
  border-color: #5cb85c;
  border-width: 2px;
}
.alert-outline-info {
  color: #5bc0de;
  border-color: #5bc0de;
  border-width: 2px;
}
.alert-outline-warning {
  color: #f0ad4e;
  border-color: #f0ad4e;
  border-width: 2px;
}
.alert-outline-danger {
  color: #c9302c;
  border-color: #c9302c;
  border-width: 2px;
}
.alert-primary-bordered {
  color: #5d5386;
  border: 1px solid #eee;
  box-shadow: 7px 0 0 0 #5d5386 inset;
}
.alert-secondary-bordered {
  color: #2c304d;
  border: 1px solid #eee;
  box-shadow: 7px 0 0 0 #2c304d inset;
}
.alert-success-bordered {
  color: #5cb85c;
  border: 1px solid #eee;
  box-shadow: 7px 0 0 0 #5cb85c inset;
}
.alert-info-bordered {
  color: #5bc0de;
  border: 1px solid #eee;
  box-shadow: 7px 0 0 0 #5bc0de inset;
}
.alert-warning-bordered {
  color: #f0ad4e;
  border: 1px solid #eee;
  box-shadow: 7px 0 0 0 #f0ad4e inset;
}
.alert-danger-bordered {
  color: #c9302c;
  border: 1px solid #eee;
  box-shadow: 7px 0 0 0 #c9302c inset;
}
.accordion .card-header {
  padding: 1.6rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0 none;
}
.accordion-icon .card-header {
  padding: 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0 none;
}
.accordion-icon.icon-01 .card-header:before {
  font-family: "lineAwesome";
  content: "\f113";
  display: inline-block;
  font-size: 1.4rem;
  vertical-align: middle;
  margin-right: 0.5rem;
}
.accordion-icon.icon-01 .card-header.collapsed:before {
  content: "\f110";
}
.accordion-icon.icon-02 .card-header:before {
  font-family: "lineAwesome";
  content: "\f28e";
  display: inline-block;
  font-size: 1.4rem;
  vertical-align: middle;
  margin-right: 0.5rem;
}
.accordion-icon.icon-02 .card-header.collapsed:before {
  content: "\f2c2";
}
.accordion .card-title,
.accordion-icon .card-title {
  margin-bottom: 0;
  display: inline-block;
}
.accordion .card-body,
.accordion-icon .card-body {
  border-bottom: 0.07rem solid #eee;
}
.popover {
  border-color: #eee;
  padding: 0;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.18);
}
.popover .popover-header {
  background-color: #fff;
  border-color: #eee;
  color: #5d5386;
  padding: 0.85rem 1.07rem;
}
.popover-body {
  padding: 0.85rem 1.07rem;
  color: #2c304d;
}
.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  right: 0;
  border-top-color: #eee;
}
.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-right-color: #eee;
}
.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  right: 0;
  border-bottom-color: #eee;
}
.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: #eee;
}
.tooltip-inner {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  max-width: 200px;
  color: #98a8b4;
  text-align: center;
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.18);
  border-radius: 35px;
  padding: 0.65rem 1.07rem;
}
.tooltip.show {
  opacity: 1;
}
.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip.bs-tooltip-top .arrow::before {
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #fff;
}
.tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip.bs-tooltip-right .arrow::before {
  left: -5px;
  top: 50%;
  border-width: 5px 5px 5px 0;
  border-right-color: #fff;
}
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip.bs-tooltip-bottom .arrow::before {
  top: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #fff;
}
.tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip.bs-tooltip-left .arrow::before {
  right: -5px;
  top: 50%;
  border-width: 5px 0 5px 5px;
  border-left-color: #fff;
}
.noty_layout_mixin,
#noty_layout__top,
#noty_layout__topLeft,
#noty_layout__topCenter,
#noty_layout__topRight,
#noty_layout__bottom,
#noty_layout__bottomLeft,
#noty_layout__bottomCenter,
#noty_layout__bottomRight,
#noty_layout__center,
#noty_layout__centerLeft,
#noty_layout__centerRight {
  position: fixed;
  margin: 0;
  padding: 0;
  z-index: 9999999;
  border-radius: 4px;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0) scale(1, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  filter: blur(0);
  -webkit-filter: blur(0);
  max-width: 90%;
}
#noty_layout__top {
  top: 0;
  left: 5%;
  width: 90%;
}
#noty_layout__topLeft {
  top: 20px;
  left: 20px;
  width: 325px;
}
#noty_layout__topCenter {
  top: 5%;
  left: 50%;
  width: 325px;
  -webkit-transform: translate(-webkit-calc(-50% - 0.5px)) translateZ(0)
    scale(1, 1);
  transform: translate(calc(-50% - 0.5px)) translateZ(0) scale(1, 1);
}
#noty_layout__topRight {
  top: 20px;
  right: 20px;
  width: 325px;
}
#noty_layout__bottom {
  bottom: 0;
  left: 5%;
  width: 90%;
}
#noty_layout__bottomLeft {
  bottom: 20px;
  left: 20px;
  width: 325px;
}
#noty_layout__bottomCenter {
  bottom: 5%;
  left: 50%;
  width: 325px;
  -webkit-transform: translate(-webkit-calc(-50% - 0.5px)) translateZ(0)
    scale(1, 1);
  transform: translate(calc(-50% - 0.5px)) translateZ(0) scale(1, 1);
}
#noty_layout__bottomRight {
  bottom: 20px;
  right: 20px;
  width: 325px;
}
#noty_layout__center {
  top: 50%;
  left: 50%;
  width: 325px;
  -webkit-transform: translate(
      -webkit-calc(-50% - 0.5px),
      -webkit-calc(-50% - 0.5px)
    )
    translateZ(0) scale(1, 1);
  transform: translate(calc(-50% - 0.5px), calc(-50% - 0.5px)) translateZ(0)
    scale(1, 1);
}
#noty_layout__centerLeft {
  top: 50%;
  left: 20px;
  width: 325px;
  -webkit-transform: translate(0, -webkit-calc(-50% - 0.5px)) translateZ(0)
    scale(1, 1);
  transform: translate(0, calc(-50% - 0.5px)) translateZ(0) scale(1, 1);
}
#noty_layout__centerRight {
  top: 50%;
  right: 20px;
  width: 325px;
  -webkit-transform: translate(0, -webkit-calc(-50% - 0.5px)) translateZ(0)
    scale(1, 1);
  transform: translate(0, calc(-50% - 0.5px)) translateZ(0) scale(1, 1);
}
.noty_progressbar {
  display: none;
}
.noty_has_timeout.noty_has_progressbar .noty_progressbar {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0 0 4px 4px;
  opacity: 0.3;
  filter: alpha(opacity=10);
}
.noty_bar {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate(0, 0) translateZ(0) scale(1, 1);
  -ms-transform: translate(0, 0) scale(1, 1);
  transform: translate(0, 0) scale(1, 1);
  -webkit-font-smoothing: subpixel-antialiased;
  overflow: hidden;
}
.noty_effects_open {
  opacity: 0;
  -webkit-transform: translate(50%);
  -ms-transform: translate(50%);
  transform: translate(50%);
  -webkit-animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation: noty_anim_in 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.noty_effects_close {
  -webkit-animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation: noty_anim_out 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.noty_fix_effects_height {
  -webkit-animation: noty_anim_height 75ms ease-out;
  animation: noty_anim_height 75ms ease-out;
}
.noty_close_with_click {
  cursor: pointer;
}
.noty_close_button {
  position: absolute;
  top: 2px;
  right: 2px;
  font-weight: 700;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.noty_close_button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.noty_modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: 10000;
  opacity: 0.3;
  left: 0;
  top: 0;
}
.noty_modal.noty_modal_open {
  opacity: 0;
  -webkit-animation: noty_modal_in 0.3s ease-out;
  animation: noty_modal_in 0.3s ease-out;
}
.noty_modal.noty_modal_close {
  -webkit-animation: noty_modal_out 0.3s ease-out;
  animation: noty_modal_out 0.3s ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes noty_modal_in {
  100% {
    opacity: 0.3;
  }
}
@keyframes noty_modal_in {
  100% {
    opacity: 0.3;
  }
}
@-webkit-keyframes noty_modal_out {
  100% {
    opacity: 0;
  }
}
@keyframes noty_modal_out {
  100% {
    opacity: 0;
  }
}
@keyframes noty_modal_out {
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes noty_anim_in {
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
}
@keyframes noty_anim_in {
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
  }
}
@-webkit-keyframes noty_anim_out {
  100% {
    -webkit-transform: translate(50%);
    transform: translate(50%);
    opacity: 0;
  }
}
@keyframes noty_anim_out {
  100% {
    -webkit-transform: translate(50%);
    transform: translate(50%);
    opacity: 0;
  }
}
@-webkit-keyframes noty_anim_height {
  100% {
    height: 0;
  }
}
@keyframes noty_anim_height {
  100% {
    height: 0;
  }
}
.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.noty_bar .noty_body {
  font-weight: 600;
  padding: 1.07rem 1.25rem;
}
.noty_bar .noty_buttons {
  padding: 1.07rem;
}
.noty_bar .noty_close_button {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  filter: alpha(opacity=20);
  opacity: 0.5;
  background: transparent;
}
.noty_bar .noty_close_button:hover {
  background: transparent;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.75;
}
.noty_type__alert,
.noty_type__notification {
  background-color: rgba(44, 48, 77, 0.8);
  color: #fff;
}
.noty_type__notification.noty_has_timeout.noty_has_progressbar
  .noty_progressbar {
  background-color: #e76c90;
  opacity: 0.5;
}
.noty_type__warning {
  background-color: #f0ad4e;
  color: #fff;
  border-color: #f0ad4e;
}
.noty_type__error {
  background-color: #fe195e;
  color: #fff;
  border-color: #fe195e;
}
.noty_type__info,
.noty_type__information {
  background-color: #5bc0de;
  color: #fff;
  border-color: #5bc0de;
}
.noty_type__success {
  background-color: #5cb85c;
  color: #fff;
  border-color: #5cb85c;
}
.daterangepicker .btn-primary,
.daterangepicker .btn-primary:hover,
.daterangepicker .btn-primary:focus,
.daterangepicker .btn-primary.disabled,
.daterangepicker .btn-primary:disabled {
  background-color: #5d5386;
  border-color: #5d5386;
}
.input-daterange input {
  text-align: center;
}
.input-daterange input:first-child {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.input-daterange input:last-child {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.daterangepicker {
  position: absolute;
  left: 0;
  margin-top: 5px;
  width: auto;
  padding: 0;
}
.daterangepicker.dropdown-menu {
  max-width: none;
  background-color: transparent;
  border: 0;
  z-index: 1000;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.daterangepicker.dropup {
  margin-top: -7px;
}
.daterangepicker .calendar,
.daterangepicker .ranges {
  float: left;
}
.daterangepicker.opensleft .calendars {
  float: left;
}
.daterangepicker.opensright .calendars {
  float: right;
}
.daterangepicker.single .calendar {
  float: none;
  margin-left: 0;
  margin-right: 0;
}
.daterangepicker.single .ranges {
  display: none;
}
.daterangepicker.show-calendar .calendar {
  display: block;
}
.daterangepicker .calendar {
  display: none;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 4px;
  margin: 7px;
  padding: 14px;
  -webkit-box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.08);
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.08);
}
.daterangepicker table {
  width: 100%;
  margin: 0;
}
.daterangepicker table tbody td,
.daterangepicker table tbody th {
  cursor: pointer;
}
.daterangepicker td,
.daterangepicker th {
  white-space: nowrap;
  text-align: center;
}
.daterangepicker td.week,
.daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}
.daterangepicker th {
  color: #2c304d;
  font-weight: 500;
  font-size: 1rem;
}
.daterangepicker th > i {
  top: 0;
}
.daterangepicker th.next,
.daterangepicker th.prev {
  cursor: pointer;
}
.daterangepicker th.next i,
.daterangepicker th.prev i {
  font-size: 20px;
}
.daterangepicker th.available:focus,
.daterangepicker th.available:hover {
  color: #333;
}
.daterangepicker td.available:focus,
.daterangepicker td.available:hover {
  background-color: rgba(200, 200, 200, 0.2);
  border-radius: 3px;
}
.daterangepicker td.disabled,
.daterangepicker td.off {
  color: #aea9c3 !important;
}
.daterangepicker td.available {
  color: #2c304d;
}
.daterangepicker td.disabled {
  cursor: not-allowed;
}
.daterangepicker td.in-range {
  background-color: rgba(200, 200, 200, 0.2);
}
.daterangepicker td.active,
.daterangepicker td.active:focus,
.daterangepicker td.active:hover {
  background-color: #5d5386;
  color: #fff;
  border-radius: 4px;
}
.daterangepicker .table-condensed tr > td,
.daterangepicker .table-condensed tr > th {
  padding: 10px;
  line-height: 1.2;
}
.daterangepicker .table-condensed thead tr:last-child th {
  padding-top: 14px;
}
.daterangepicker .table-condensed .month {
  font-size: 1.07rem;
  line-height: 1;
  color: #e76c90;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 500;
}
.daterangepicker select {
  display: inline-block;
}
.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}
.daterangepicker select.yearselect {
  width: 40%;
}
.daterangepicker select.ampmselect,
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect {
  width: 60px;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}
.daterangepicker .daterangepicker_input {
  position: relative;
}
.daterangepicker .daterangepicker_input i {
  position: absolute;
  right: 11px;
  top: auto;
  bottom: 2px;
  color: #999;
  font-size: 24px;
}
.daterangepicker .daterangepicker_input input {
  padding-left: 11px;
  padding-right: 34px;
}
.daterangepicker .calendar-time {
  text-align: center;
  margin: 12px 0;
}
.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}
.ranges {
  background-color: #fff;
  position: relative;
  border: 1px solid #eee;
  border-radius: 4px;
  width: 200px;
  margin-top: 7px;
  -webkit-box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.08);
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.08);
}
.opensright .ranges {
  margin-left: 0;
}
.opensleft .ranges {
  margin-right: 0;
}
.ranges ul {
  list-style: none;
  margin: 0;
  padding: 7px 0;
}
.ranges ul + .daterangepicker-inputs {
  border-top: 1px solid #e5e5e5;
}
.ranges ul li {
  color: #333;
  padding: 8px 12px;
  cursor: pointer;
  margin-top: 1px;
}
.ranges ul li:first-child {
  margin-top: 0;
}
.ranges ul li:focus,
.ranges ul li:hover {
  background-color: rgba(200, 200, 200, 0.2);
}
.ranges ul li.active {
  color: #fff;
  background-color: #6200ea;
}
.ranges .daterangepicker-inputs {
  padding: 12px;
  padding-top: 19px;
}
.ranges
  .daterangepicker-inputs
  .daterangepicker_input
  + .daterangepicker_input {
  margin-top: 19px;
}
.ranges .daterangepicker-inputs .daterangepicker_input > span {
  display: block;
  font-size: 12px;
  margin-bottom: 7px;
  color: #999;
}
.ranges .daterangepicker-inputs + .range_inputs {
  border-top: 1px solid #e5e5e5;
}
.ranges .range_inputs {
  padding: 12px;
}
.ranges .range_inputs .btn {
  display: block;
  width: 100%;
}
.ranges .range_inputs .btn + .btn {
  margin-top: 12px;
}
@media (min-width: 769px) {
  .ranges {
    margin: 7px;
  }
}
.daterange-custom {
  cursor: pointer;
}
.daterange-custom:after {
  content: "";
  display: table;
  clear: both;
}
.daterange-custom .badge,
.daterange-custom .label {
  margin: 4px 0 0 7px;
  vertical-align: top;
}
.daterange-custom .label-icon {
  margin-top: 0;
  margin-right: 5px;
}
.daterange-custom-display {
  display: inline-block;
  position: relative;
  padding-left: 21px;
  line-height: 1;
}
.daterange-custom-display:after {
  content: "\e9c9";
  font-family: icomoon;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: all ease-in-out 0.2s;
  -o-transition: all ease-in-out 0.2s;
  transition: all ease-in-out 0.2s;
}
.daterange-custom.is-opened .daterange-custom-display:after {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.daterange-custom-display > i {
  display: inline-block;
  font-size: 28px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.015em;
}
.daterange-custom-display b {
  display: inline-block;
  margin-left: 4px;
  font-weight: 400;
}
.daterange-custom-display b > i {
  font-size: 11px;
  display: block;
  line-height: 12px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
}
.daterange-custom-display em {
  line-height: 30px;
  vertical-align: top;
  margin: 0 4px;
}
@media (max-width: 769px) {
  .opensleft,
  .opensright {
    left: 0 !important;
    right: 0;
  }
  .opensleft .calendars,
  .opensright .calendars {
    float: none;
  }
  .daterangepicker.opensleft .calendar,
  .daterangepicker.opensleft .calendars,
  .daterangepicker.opensleft .ranges,
  .daterangepicker.opensright .calendar,
  .daterangepicker.opensright .calendars,
  .daterangepicker.opensright .ranges {
    float: none;
  }
  .daterangepicker {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .daterangepicker .calendar {
    margin-left: 0;
    margin-right: 0;
  }
  .daterangepicker .ranges {
    width: 100%;
  }
}
.img-hover-01 {
  background: #2c304d;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: center;
  border-radius: 4px;
  margin: 0;
}
.img-hover-01 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}
.img-hover-01 img {
  max-width: 100%;
  border-radius: 4px;
}
.img-hover-01 > div {
  width: 100%;
  bottom: 0;
  position: absolute;
  padding: 8px;
  color: #fff;
  text-align: right;
  overflow: hidden;
}
.img-hover-01 > div a i {
  font-size: 1.8rem;
  color: #fff;
  padding: 0 5px;
  position: relative;
  bottom: -50px;
}
.img-hover-01 > div a span {
  font-size: 1.07rem;
  color: #fff;
  padding: 0;
  position: relative;
  bottom: -50px;
}
.img-hover-01 a {
  opacity: 0.8;
  transition: all 0.1s;
}
.img-hover-01 a:hover {
  opacity: 1;
}
.img-hover-01:hover img,
.img-hover-01.hover img {
  opacity: 0.2;
  bottom: 0;
}
.img-hover-01:hover > div,
.img-hover-01.hover > div {
  bottom: 5px;
}
.img-hover-01:hover > div a i,
.img-hover-01.hover > div a i {
  bottom: 5px;
}
.img-hover-01:hover > div a span,
.img-hover-01.hover > div a span {
  bottom: 8px;
}
.img-hover-01:hover a span,
.img-hover-01.hover a span {
  transition-delay: 0.4s;
}
.img-hover-01:hover a:first-child i,
.img-hover-01.hover a:first-child i {
  transition-delay: 0.1s;
}
.img-hover-01:hover a:nth-child(2) i,
.img-hover-01.hover a:nth-child(2) i {
  transition-delay: 0.2s;
}
.img-hover-02 {
  position: relative;
  overflow: hidden;
  margin: 0;
  width: 100%;
  background: #2c304d;
  text-align: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
.img-hover-02 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.img-hover-02 img {
  max-width: 100%;
  vertical-align: top;
}
.img-hover-02 i {
  position: absolute;
  top: 100%;
  left: 50%;
  border-radius: 50%;
  font-size: 34px;
  color: #e76c90;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #fff;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.25);
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 300ms 0ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.img-hover-02 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}
.img-hover-02:hover img,
.img-hover-02.hover img {
  opacity: 0.3;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.img-hover-02:hover i,
.img-hover-02.hover i {
  top: 50%;
  transition: all 500ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 1;
}
.db-social .jumbotron {
  margin: 0;
  background: url(img/background/05.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: bottom center;
  color: #fff !important;
  height: 300px;
  position: relative;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.3);
  padding: 0;
}
.db-social .profile-overlay {
  background: linear-gradient(
    to bottom,
    rgba(242, 243, 248, 0) 0%,
    rgba(242, 243, 248, 1) 100%
  );
}
.db-social .head-profile li {
  display: inline-block;
  text-align: center;
  padding: 0 1rem;
}
.db-social .head-profile li:first-child {
  padding-left: 0;
}
.db-social .head-profile li:last-child {
  padding-right: 0;
}
.db-social .head-profile li i {
  font-size: 3rem;
}
.db-social .head-profile li .heading {
  color: #aea9c3;
  font-size: 1rem;
}
.db-social .head-profile li .counter {
  color: #2c304d;
  font-size: 1.4rem;
  font-weight: 600;
}
.db-social .head-profile {
  margin-top: -120px;
  border-radius: 4px;
  position: relative;
}
.db-social .head-profile:before {
  content: "";
  background: rgba(255, 255, 255, 0.6);
  height: 20px;
  width: 90%;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 4px 4px 0 0;
}
.db-social .head-profile:after {
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 20px;
  width: 80%;
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 4px 4px 0 0;
}
.db-social .image-default img {
  width: 120px;
  position: absolute;
  top: -80px;
  left: 0;
  right: 0;
  margin: 0 auto;
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 1);
  z-index: 10;
}
.db-social .infos {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 1rem;
  line-height: 1.8rem;
}
.db-social h2 {
  color: #2c304d;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0.2rem;
}
.db-social .location {
  color: #aea9c3;
  font-size: 1rem;
}
.db-social .follow .btn {
  padding: 10px 30px;
}
.db-social .head-profile .actions {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5rem;
}
.db-social .head-nav {
  font-weight: 500;
}
.db-social .nav-tabs {
  border: 0 none;
}
.db-social .head-nav * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  transition: all 0.35s ease;
}
.db-social .head-nav li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 1.4rem;
  padding: 0;
}
.db-social .head-nav li:first-child {
  margin-left: 0;
}
.db-social .head-nav li:last-child {
  margin-right: 0;
}
.db-social .head-nav a {
  padding: 1.07rem 0;
  color: #2c304d;
  position: relative;
  text-decoration: none;
  display: inline-block;
}
.db-social .head-nav a:after {
  height: 2px;
  position: absolute;
  content: "";
  background-color: rgba(52, 40, 104, 0.8);
  width: 0;
  transition: all 0.35s ease;
}
.db-social .head-nav a:after {
  bottom: 0;
  left: 0;
}
.db-social .head-nav a:hover,
.db-social .head-nav .current a {
  color: #2c304d;
}
.db-social .head-nav a:hover:before,
.db-social .head-nav .current a:before,
.db-social .head-nav a:hover:after,
.db-social .head-nav .current a:after {
  width: 100%;
}
.db-social .widget-header .user-image {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.07rem;
}
.db-social .widget-header .user-image img {
  width: 50px;
}
.db-social .widget-header .title {
  color: #2c304d;
  font-size: 1rem;
}
.db-social .widget-header .username {
  color: #e76c90;
  font-weight: 600;
}
.db-social .widget-header .time {
  color: #aea9c3;
  font-size: 0.85rem;
}
.db-social .widget-body {
  padding: 1rem 1.4rem;
}
.db-social .widget-footer {
  border-top: 1px solid #eee;
  margin: 0 1.4rem;
  padding: 1.07rem 0;
}
.db-social .users-like {
  padding: 0;
}
.db-social .users-like a {
  margin: 0 -1.6rem 0 0;
  transition: all 0.4s ease;
}
.db-social .users-like a:hover {
  margin-right: -0.3rem;
}
.db-social .users-like img {
  width: 35px;
  border: 0.25rem solid #fff;
}
.db-social .users-like a.view-more {
  background: #5d5386;
  color: #fff;
  width: 35px;
  height: 35px;
  border: 0.25rem solid #fff;
  border-radius: 50%;
  vertical-align: middle;
  font-size: 0.85rem;
  text-align: center;
  line-height: 30px;
  margin-right: 0;
}
.db-social .widget-footer .meta li {
  display: inline-block;
  margin-right: 0.5rem;
}
.db-social .widget-footer .meta li:last-child {
  margin-right: 0;
}
.db-social .widget-footer .meta li a {
  color: #aea9c3;
}
.db-social .widget-footer .meta li a:hover {
  color: #5d5386;
}
.db-social .widget-footer .meta li i {
  font-size: 1.8rem;
  vertical-align: middle;
  margin-right: 0.3rem;
}
.db-social .widget-footer .meta li .numb {
  vertical-align: middle;
}
.db-social .comments {
  background: #f5f6fb;
}
.db-social .comments-header {
  border-top: 0.07rem solid #eee;
  padding: 0.85rem 1.4rem;
  position: relative;
  width: 100%;
}
.db-social .comments-header .user-image {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.07rem;
}
.db-social .comments-header .user-image img {
  width: 40px;
}
.db-social .comments-header .title {
  color: #2c304d;
  font-size: 1rem;
}
.db-social .comments-header .username {
  color: #2c304d;
  font-weight: 600;
}
.db-social .comments-header .time {
  font-size: 0.85rem;
}
.db-social .comments-body {
  padding: 1rem 1.4rem;
}
.db-social .comments-footer {
  padding: 0 1.4rem 1rem 1.4rem;
}
.db-social .comments-footer .meta li {
  display: inline-block;
  margin-right: 0.3rem;
}
.db-social .comments-footer .meta li a {
  color: rgba(52, 40, 104, 0.3);
}
.db-social .comments-footer .meta li a:hover {
  color: rgba(52, 40, 104, 0.9);
}
.db-social .comments-footer .meta li i {
  font-size: 1.2rem;
  vertical-align: middle;
}
.db-social .comments-footer .meta li .rep {
  font-size: 0.85rem;
  vertical-align: middle;
}
.db-social .reply:before {
  position: absolute;
  display: block;
  left: 30px;
  width: 4px;
  height: 100%;
  border-radius: 4px;
  background-color: #e4e8f0;
  content: " ";
}
.db-social .reply:after {
  display: block;
  position: absolute;
  top: 25px;
  left: 25px;
  width: 15px;
  height: 15px;
  border: 3px solid #f5f6fb;
  border-radius: 50%;
  background-color: rgba(52, 40, 104, 0.2);
  content: " ";
}
.db-social .reply {
  background: #f5f6fb;
  position: relative;
}
.db-social .reply-header {
  border-top: 0.07rem solid #eee;
  padding: 0.85rem 1.4rem 0 4rem;
  position: relative;
  width: 100%;
}
.db-social .reply-header .user-image {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.07rem;
}
.db-social .reply-header .user-image img {
  width: 40px;
}
.db-social .reply-header .title {
  color: #2c304d;
  font-size: 1rem;
}
.db-social .reply-header .username {
  color: #2c304d;
  font-weight: 600;
}
.db-social .reply-header .time {
  font-size: 0.85rem;
}
.db-social .reply-body {
  padding: 1rem 1.4rem 1rem 4rem;
}
.db-social .reply-footer {
  padding: 0 1.4rem 1rem 4rem;
}
.db-social .reply-footer .meta li {
  display: inline-block;
  margin-right: 0.3rem;
}
.db-social .reply-footer .meta li a {
  color: rgba(52, 40, 104, 0.3);
}
.db-social .reply-footer .meta li a:hover {
  color: rgba(52, 40, 104, 0.9);
}
.db-social .reply-footer .meta li i {
  font-size: 1.2rem;
  vertical-align: middle;
}
.db-social .reply-footer .meta li .rep {
  font-size: 0.85rem;
  vertical-align: middle;
}
.db-social .publisher {
  border-top: 0.07rem solid #eee;
  position: relative;
  padding: 1rem;
  background: #f5f6fb;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.db-social .publisher > * {
  margin: 0 8px;
}
.db-social .publisher > *:first-child {
  margin-left: 0;
}
.db-social .publisher > *:last-child {
  margin-right: 0;
}
.db-social .publisher-multi {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
}
.db-social .publisher-multi > * {
  margin: 0;
  width: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
}
.db-social .publisher-multi .publisher-input {
  padding: 1rem;
  border-radius: 4px;
  color: #98a8b4;
}
.db-social .publisher-input {
  border: 0 none;
  outline: none !important;
  background-color: #fff;
  -webkit-box-flex: 1;
  flex-grow: 1;
}
.db-social .publisher-bottom {
  padding: 1rem 0 0;
}
.db-social .publisher-btn {
  background-color: transparent;
  border: 0 none;
  color: #98a8b4;
  font-size: 1.8rem;
  margin-right: 0.5rem;
}
.db-social .publisher-btn:hover {
  color: #5d5386;
}
.db-social h5,
.db-social .column h5 {
  color: #2c304d;
  font-weight: 600;
}
.db-social .column .list-group-item {
  padding: 1.07rem 1.25rem;
  border-top: 0 none;
  border-right: 0 none;
  border-bottom: 0.07rem solid #eee;
  border-left: 0 none;
  margin-bottom: 0;
}
.db-social .column .list-group-item:last-child {
  border-bottom: 0 none;
}
.db-social .column .list-group-item .media i {
  font-size: 2rem;
}
.db-social .column .list-group-item .media p {
  font-size: 0.85rem;
}
.db-social .column .new-badge {
  background: #2c304d;
  padding: 0.5rem 0;
  position: relative;
}
.db-social .column .new-badge .badge-img {
  display: inline-block;
  vertical-align: middle;
}
.db-social .column .new-badge .title {
  display: inline-block;
  vertical-align: middle;
  margin-left: 1rem;
}
.db-social .column .new-badge .heading {
  color: #fff;
  font-size: 1.4rem;
}
.db-social .column .new-badge .text {
  font-size: 0.9rem;
}
.db-social .column .new-badge i {
  color: #e76c90;
  font-size: 5rem;
  vertical-align: middle;
}
.db-social .column .friends-list {
  padding: 0;
}
.db-social .column .friends-list a {
  transition: all 0.4s ease;
}
.db-social .column .friends-list img {
  width: 45px;
}
.db-social .column .friends-list a.view-more {
  background: #5d5386;
  color: #fff;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  vertical-align: middle;
  font-size: 0.85rem;
  text-align: center;
  line-height: 45px;
  margin-right: 0;
}
@media (max-width: 1200px) {
  .db-social .jumbotron {
    background-attachment: scroll;
  }
  .db-social .head-profile li {
    padding: 0 0.5rem;
  }
  .db-social .rang {
    top: 15px;
  }
}
@media (max-width: 991px) {
  .db-social .jumbotron {
    background-attachment: scroll;
  }
  .db-social .head-profile li {
    padding: 0 0.5rem;
  }
  .db-social .rang {
    top: 15px;
  }
  .db-social .image-default img {
    top: -165px;
  }
  .db-social .head-profile ul {
    margin-top: 3rem;
  }
}
@media (max-width: 576px) {
  .db-social .jumbotron {
    background-attachment: scroll;
  }
  .db-social .head-nav li {
    margin: 0;
  }
  .db-social .head-profile .widget-body {
    padding: 10px;
  }
  .db-social .head-profile li {
    padding: 0 0.4rem;
  }
  .db-social .image-default img {
    top: -205px;
  }
  .db-social .head-profile ul {
    margin-top: 6rem;
  }
  .db-social .infos {
    margin-top: 2rem;
  }
  .db-social .follow {
    margin: 1.4rem;
  }
  .db-social .rang {
    top: 15px;
  }
  .db-social .widget-footer {
    margin: 0 0.8rem;
  }
  .db-social .users-like a {
    margin-right: -1.8rem;
  }
  .db-social .users-like a:hover {
    margin-right: -1.8rem;
  }
  .db-social .input-group-addon .btn {
    padding: 18px 10px;
  }
  .db-social .hobbies {
    margin-right: 2rem;
  }
}
.db-smarthome.page {
  background-color: #f2f3f8;
  background: url(img/background/db-smarthome.jpg);
  background-position: bottom right;
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.db-smarthome nav.navbar {
  background: transparent;
  padding: 0;
  box-shadow: none;
}
.db-smarthome nav.navbar .nav-item > a span {
  border-color: #f2f3f8;
}
.db-smarthome nav.navbar .avatar {
  border-color: transparent;
}
.room-details .room-image {
  position: relative;
  overflow: hidden;
}
.room-details .room-image .overlay {
  background: linear-gradient(
    to bottom,
    rgba(63, 76, 107, 0) 30%,
    rgba(63, 76, 107, 0.8) 90%
  );
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  border-radius: 0 0 4px 4px;
}
.room-details .room-image .live {
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 2;
}
.room-details .room-image .live .badge-rounded {
  display: inline-block;
}
.room-details .room-image .live p {
  color: #fff;
  display: inline-block;
  margin-bottom: 0;
  text-transform: uppercase;
}
.room-details .main-room-info .temperature {
  color: #2c304d;
  font-size: 3rem;
}
.room-details .main-room-info .temperature i {
  color: #aea9c3;
  font-size: 2rem;
  vertical-align: middle;
}
.room-details .global-room-info .title {
  color: #2c304d;
}
.room-details .global-room-info .setgs {
  color: #2c304d;
}
@media (max-width: 576px) {
  .db-smarthome.page {
    background-image: none;
  }
  .db-smarthome .page-header-title {
    margin-bottom: 1.07rem;
  }
}
.header {
  min-height: 70px;
  position: relative;
}
.navbar-header .logo-big {
  width: 170px;
}
.navbar-header .logo-small {
  width: 70px;
}
.navbar-holder {
  width: 100%;
}
nav.navbar {
  background: #fff;
  padding: 0 15px;
  color: #aea9c3;
  border-radius: 0;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.08);
  z-index: 1000;
  width: 100%;
}
nav.navbar .badge {
  display: block;
  width: 12px;
  height: 12px;
  line-height: 12px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}
nav.navbar .navbar-brand .brand-small {
  display: none;
}
nav.navbar a {
  color: inherit;
}
nav.navbar .container-fluid {
  width: 100%;
}
nav.navbar .menu-btn {
  margin-right: 0;
  font-size: 1.2em;
  vertical-align: middle;
  padding: 0;
}
nav.navbar .menu-btn span {
  background: #aea9c3;
  width: 20px;
  height: 2px;
  display: block;
  margin: 7px 0;
  border-radius: 35px;
  transition: all 0.3s cubic-bezier(0.81, -0.33, 0.345, 1.375);
}
nav.navbar .menu-btn span:nth-of-type(2) {
  position: relative;
  width: 30px;
}
nav.navbar .menu-btn span:last-of-type {
  position: relative;
  width: 15px;
  margin-top: 0;
}
nav.navbar .menu-btn span:first-of-type {
  margin-top: 0;
  transition: all 0.6s;
}
nav.navbar .menu-btn.active span:first-of-type {
  transform: translateX(10px);
  margin-top: 0;
}
nav.navbar .menu-btn.active span:nth-of-type(2) {
  transform: none;
}
nav.navbar .menu-btn span:last-of-type {
  margin-top: 0;
  transition: all 0.3s;
}
nav.navbar .menu-btn.active span:last-of-type {
  transform: translateX(15px);
  margin-top: 0;
}
nav.navbar .nav-item > a {
  position: relative;
  font-size: 1.8rem;
  padding: 0;
  width: 50px;
  text-align: center;
  line-height: 70px;
}
nav.navbar .nav-item > a span {
  position: absolute;
  top: 20px;
  right: 10px;
  border: 3px solid #fff;
}
nav.navbar .nav-item > a.logout i {
  margin-left: 10px;
}
nav.navbar .search-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  background: #fff;
  z-index: 9999;
  border-radius: 0;
  display: none;
}
nav.navbar .search-box .dismiss {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #5d5386;
}
nav.navbar .search-box form {
  height: 100%;
}
nav.navbar .search-box form input {
  height: 100%;
  border: none;
  padding: 20px;
  color: #5d5386;
}
nav.navbar .search-box form input::placeholder {
  color: #2a2c3d;
  font-weight: 500;
}
.db-modern nav.navbar .search-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  background: #2c304d;
  z-index: 9999;
  border-radius: 0;
  display: none;
}
.db-modern nav.navbar .search-box .dismiss {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #aea9c3;
}
.db-modern nav.navbar .search-box form {
  height: 100%;
}
.db-modern nav.navbar .search-box form input {
  background: #2c304d;
  height: 100%;
  border: none;
  padding: 20px;
  color: #fff;
}
.db-modern nav.navbar .search-box form input::placeholder {
  color: #aea9c3;
  font-weight: 500;
}
nav.navbar .dropdown-menu {
  right: 0;
  min-width: 300px;
  left: auto;
  margin-top: 0;
  padding: 0;
  max-width: 400px;
  border-radius: 4px;
  border: none;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
}
nav.navbar .dropdown-menu.notification .notifications-header {
  position: relative;
}
nav.navbar
  .dropdown-menu.notification
  .notifications-header
  .notifications-overlay {
  background: rgba(93, 83, 134, 0.8);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px 4px 0 0;
}
nav.navbar .dropdown-menu.notification .notifications-header img {
  border-radius: 4px 4px 0 0;
}
nav.navbar .dropdown-menu.notification .notifications-header .title {
  color: #fff;
  font-size: 1.2rem;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  z-index: 1;
}
nav.navbar .dropdown-menu.notification .notifications-header-2 {
  position: relative;
  padding: 1.2rem 0;
  border-bottom: 0.07rem solid #eee;
}
nav.navbar .dropdown-menu.notification .notifications-header-2 .title {
  color: #5d5386;
  font-size: 1.2rem;
  text-align: center;
}
nav.navbar .dropdown-menu.notification li a:hover i {
  color: #5d5386;
  transition: all 0.4s ease;
}
.dropdown-menu.notification .message-icon {
  display: table-cell;
  vertical-align: bottom;
}
.dropdown-menu.notification .message-icon i {
  color: #aea9c3;
  font-size: 2.8rem;
}
.dropdown-menu.notification .message-body {
  display: table-cell;
  vertical-align: top;
  padding: 0.7rem 0 0 1rem;
  color: #98a8b4;
}
.dropdown-menu.notification .message-body-heading {
  font-size: 0.85rem;
  font-weight: 600;
  color: #5d5386;
  margin: 0;
  padding: 0;
}
.dropdown-menu.notification .message-body span.date {
  color: #98a8b4;
  font-size: 0.7rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
nav.navbar .dropdown-menu a {
  padding: 1rem 0 1rem 1rem;
  font-size: 0.85rem;
  color: #5d5386;
  width: 100%;
  position: relative;
  z-index: 2;
}
nav.navbar .dropdown-menu a:hover {
  color: #5d5386;
}
nav.navbar .dropdown-menu a.all-notifications {
  color: #5d5386;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 1.4rem 0;
  border-top: 0.07rem solid rgba(0, 0, 0, 0.05);
  background: transparent;
}
nav.navbar .dropdown-menu a.all-notifications:hover {
  color: #e76c90;
}
nav.navbar .dropdown-menu a small {
  color: #98a8b4;
}
nav.navbar .dropdown-menu h3 {
  font-weight: 500;
}
nav.navbar .dropdown-menu.dropdown-grid {
  min-width: 300px;
}
nav.navbar .dropdown-menu.dropdown-grid li {
  width: 50%;
  float: left;
  text-align: center;
}
nav.navbar .dropdown-menu.dropdown-grid a {
  padding: 25px 15px;
  border-bottom: 0 none;
  position: relative;
}
nav.navbar .dropdown-menu.dropdown-grid a:hover .icon {
  color: #5d5386;
}
nav.navbar .dropdown-menu .dropdown-grid span {
  position: absolute;
  left: 10px;
  top: 9px;
  border: 3px solid #fff;
}
nav.navbar .dropdown-menu.dropdown-grid .icon {
  font-size: 2.4rem;
  color: #98a8b4;
}
nav.navbar .dropdown-menu.dropdown-grid .title {
  font-size: 1rem;
  margin-top: -5px;
}
nav.navbar .avatar {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  border: 0.2rem solid #fff;
}
nav.navbar .user-size.dropdown-menu {
  min-width: 250px;
  overflow: hidden;
}
nav.navbar .user-size.dropdown-menu a {
  font-weight: 600;
  padding: 0.5rem 1.4rem;
  border-bottom: 0 none;
  position: relative;
}
nav.navbar .user-size.dropdown-menu a:hover {
  background: none;
}
nav.navbar .user-size.dropdown-menu .separator {
  margin: 1.4rem 0;
  border-bottom: 0.07rem solid rgba(0, 0, 0, 0.05) !important;
}
nav.navbar .user-size.dropdown-menu li.welcome {
  width: 150px;
  margin: 0 auto;
  padding: 1.4rem 0;
  position: relative;
}
nav.navbar .user-size.dropdown-menu li.welcome a.edit-profil {
  position: absolute;
  background: #fff;
  font-size: 1.6rem;
  border-radius: 50%;
  right: 20px;
  bottom: 10px;
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.1);
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  padding: 0;
}
nav.navbar .user-size.dropdown-menu li.welcome img {
  display: block;
  width: 80px;
  height: 80px;
  margin: 0 auto 10px;
}
nav.navbar .user-size.dropdown-menu h2 {
  color: #5d5386;
  font-size: 1.2rem;
  font-weight: 500;
  text-align: center;
  margin: 0;
}
nav.navbar .user-size.dropdown-menu a h6 {
  transition: all 0.4s ease;
}
nav.navbar .user-size.dropdown-menu a:hover h6 {
  margin-left: 5px;
}
nav.navbar .dropdown-menu .user-body h6 {
  font-size: 1rem;
  display: inline-block;
  margin-bottom: 0;
}
nav.navbar .user-size.dropdown-menu a.logout {
  background: #5d5386;
  width: 70px;
  height: 70px;
  color: #fff;
  border-radius: 50%;
  text-align: center !important;
  padding: 0;
  line-height: 55px;
  position: relative;
  bottom: -20px;
  font-size: 1.8rem;
  margin: 10px auto 0;
}
nav.navbar .user-size.dropdown-menu a.logout:hover {
  background: #e76c90;
}
.navbar {
  padding: 0.5rem 1rem;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-right: 1rem;
  font-size: 1.25rem;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler-left {
  left: 1rem;
}
.navbar-toggler-right {
  right: 1rem;
}
.navbar-light .navbar-brand,
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-toggler:focus,
.navbar-light .navbar-toggler:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .open > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.open,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-toggler {
  color: white;
}
.navbar-inverse .navbar-brand:focus,
.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-toggler:focus,
.navbar-inverse .navbar-toggler:hover {
  color: white;
}
.navbar-inverse .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-inverse .navbar-nav .nav-link:focus,
.navbar-inverse .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-inverse .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-inverse .navbar-nav .open > .nav-link,
.navbar-inverse .navbar-nav .active > .nav-link,
.navbar-inverse .navbar-nav .nav-link.open,
.navbar-inverse .navbar-nav .nav-link.active {
  color: white;
}
.navbar-inverse .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-inverse .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-inverse .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.db-modern .header {
  background: #2c304d;
}
.db-modern .navbar {
  background: transparent;
}
.db-modern nav.navbar {
  box-shadow: none;
}
.db-modern .menu-area {
  background: #2c304d;
}
.db-modern nav.navbar .avatar {
  border: 0.2rem solid #2c304d;
}
.db-modern nav.navbar .nav-item > a span {
  border: 3px solid #2c304d;
}
.db-social nav.navbar {
  background: #2c304d;
  box-shadow: none;
}
.db-social .menu-area {
  background: #2c304d;
}
.db-social nav.navbar .avatar {
  border: 0.2rem solid #2c304d;
}
.db-social nav.navbar .nav-item > a span {
  border: 3px solid #2c304d;
}
.default-sidebar {
  background: #2c304d;
  position: fixed;
  height: 100%;
  top: 0;
  z-index: 999;
  transition: all 0.2s ease;
}
.default-sidebar .sidebar-separator {
  background: rgba(255, 255, 255, 0.05);
  height: 1px;
  width: 90%;
  margin: 1.07rem auto;
}
.default-sidebar > .side-navbar {
  min-width: 240px;
  max-width: 240px;
  color: #fff;
  z-index: 999;
  position: relative;
  height: 100%;
  padding: 80px 0;
  display: block;
}
.default-sidebar > .side-navbar a {
  color: inherit;
  position: relative;
  font-size: 1rem;
}
.default-sidebar > .side-navbar a[data-toggle="collapse"]::before {
  color: #aea9c3;
  font-size: 0.85rem;
  content: "\f124";
  display: inline-block;
  transform: translateY(-50%);
  font-family: "ionicons";
  position: absolute;
  top: 50%;
  right: 20px;
  opacity: 0.5;
}
.default-sidebar > .side-navbar a[aria-expanded="true"] {
  background: #252946;
  margin: 0 10px 0 10px;
  border-radius: 4px 4px 0 0;
}
.default-sidebar > .side-navbar a[aria-expanded="true"] i {
  color: #e76c90;
}
.default-sidebar > .side-navbar a[aria-expanded="true"]::before {
  content: "\f123";
}
.default-sidebar > .side-navbar a i {
  font-size: 1.6rem;
  margin-right: 10px;
  transition: none;
  vertical-align: -4px;
  color: #98a8b4;
}
.default-sidebar > .side-navbar .sidebar-header {
  padding: 30px 15px;
}
.default-sidebar > .side-navbar span.heading {
  font-weight: 600;
  margin-left: 10px;
  color: #aea9c3;
  font-size: 0.85rem;
  text-transform: uppercase;
}
.default-sidebar > .side-navbar ul {
  padding: 10px 0;
}
.default-sidebar > .side-navbar ul a {
  color: #aea9c3;
  padding: 10px 10px;
  text-decoration: none;
  display: block;
  font-weight: 500;
}
.default-sidebar > .side-navbar ul a:hover i {
  color: #e76c90;
}
.default-sidebar > .side-navbar ul li.active i,
.default-sidebar > .side-navbar li ul li a.active {
  color: #e76c90;
}
.default-sidebar > .side-navbar ul ul {
  margin: 0 10px 0 10px;
}
.default-sidebar > .side-navbar ul ul {
  background: #252946;
  border-radius: 0 0 4px 4px;
}
.default-sidebar > .side-navbar ul ul a {
  font-size: 0.85rem;
  padding-left: 40px;
  color: #aea9c3;
}
.default-sidebar > .side-navbar ul ul a:hover {
  color: #e76c90;
}
.default-sidebar > .side-navbar ul ul a:focus {
  color: #aea9c3;
}
.default-sidebar > .side-navbar.shrinked ul.end-sidebar {
  padding-bottom: 0;
}
.default-sidebar > .side-navbar.shrinked .sidebar-gradient {
  display: none;
}
.default-sidebar > .side-navbar.shrinked {
  min-width: 90px;
  max-width: 90px;
  text-align: center;
}
.default-sidebar > .side-navbar.shrinked span.heading {
  font-size: 0.7rem;
  margin: 0;
}
.default-sidebar > .side-navbar.shrinked a {
  padding: 15px 10px;
  border: none;
  font-size: 0.7rem;
  transition: color 0.3s, background 0.3s;
}
.default-sidebar > .side-navbar.shrinked a[data-toggle="collapse"]::before {
  content: "\f123";
  transform: translateX(50%);
  position: absolute;
  top: auto;
  right: 50%;
  bottom: 0;
  left: auto;
}
.default-sidebar
  > .side-navbar.shrinked
  a[data-toggle="collapse"][aria-expanded="true"]::before {
  content: "\f126";
}
.default-sidebar > .side-navbar.shrinked a:hover {
  border: none;
}
.default-sidebar > .side-navbar.shrinked a {
  padding: 10px 0;
}
.default-sidebar > .side-navbar.shrinked a i {
  margin-right: 0;
  margin-bottom: 5px;
  display: block;
  font-size: 1.7rem;
  transition: color 0.3s;
}
.default-sidebar > .side-navbar.shrinked a span {
  display: none;
}
.default-sidebar > .side-navbar.shrinked .sidebar-header .title {
  display: none;
}
.compact-sidebar {
  background: #2c304d;
  position: fixed;
  height: 100%;
  top: 0;
  z-index: 999;
  transition: all 0.2s ease;
}
.compact-sidebar .sidebar-separator {
  background: rgba(255, 255, 255, 0.05);
  height: 1px;
  width: 90%;
  margin: 1.07rem auto;
}
.compact-sidebar > .side-navbar.shrinked {
  margin-left: -90px;
  min-width: 90px;
  max-width: 90px;
  text-align: center;
}
.compact-sidebar > .side-navbar a {
  color: inherit;
  position: relative;
  font-size: 1rem;
}
.compact-sidebar > .side-navbar a[data-toggle="collapse"]::before {
  font-size: 0.85rem;
  content: "\f124";
  display: inline-block;
  transform: translateY(-50%);
  font-family: "ionicons";
  position: absolute;
  top: 50%;
  right: 20px;
}
.compact-sidebar > .side-navbar a[aria-expanded="true"] {
  background: #252946;
  margin: 0 10px 0 10px;
  border-radius: 4px 4px 0 0;
}
.compact-sidebar > .side-navbar a[aria-expanded="true"] i {
  color: #e76c90;
}
.compact-sidebar > .side-navbar a[aria-expanded="true"]::before {
  content: "\f123";
}
.compact-sidebar > .side-navbar a i {
  font-size: 1.4rem;
  margin-right: 10px;
  transition: none;
  vertical-align: -2px;
  color: #fff;
}
.compact-sidebar > .side-navbar .sidebar-header {
  padding: 30px 15px;
}
.compact-sidebar > .side-navbar span.heading {
  font-weight: 600;
  margin-left: 10px;
  color: #aea9c3;
  font-size: 0.85rem;
  text-transform: uppercase;
}
.compact-sidebar > .side-navbar ul {
  padding: 10px 0;
}
.compact-sidebar > .side-navbar ul a {
  color: #98a8b4;
  padding: 10px 2px;
  text-decoration: none;
  display: block;
  font-weight: 500;
  text-align: center;
  position: relative;
}
.compact-sidebar > .side-navbar ul a:hover i {
  color: #e76c90;
}
.compact-sidebar > .side-navbar ul li.active i,
.compact-sidebar > .side-navbar li ul li a.active {
  color: #e76c90;
}
.compact-sidebar > .side-navbar ul a:hover span {
  opacity: 1;
  bottom: 0;
}
.compact-sidebar > .side-navbar ul a span {
  position: absolute;
  right: 0;
  bottom: -5px;
  left: 0;
  z-index: 20;
  opacity: 0;
  text-align: center;
  transition: bottom 0.3s, opacity 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
}
.compact-sidebar > .side-navbar ul ul {
  margin: 0 10px 0 10px;
}
.compact-sidebar > .side-navbar ul ul {
  background: #252946;
  border-radius: 0 0 4px 4px;
}
.compact-sidebar > .side-navbar ul ul a:hover {
  color: #e76c90;
}
.compact-sidebar > .side-navbar ul ul a:focus {
  color: #aea9c3;
}
.compact-sidebar > .side-navbar.shrinked ul.end-sidebar {
  padding-bottom: 0;
}
.compact-sidebar > .side-navbar {
  min-width: 90px;
  max-width: 90px;
  text-align: center;
  z-index: 999;
  position: relative;
  height: 100%;
  padding: 80px 0;
}
.compact-sidebar > .side-navbar span.heading {
  font-size: 0.7rem;
  margin: 0;
}
.compact-sidebar > .side-navbar a {
  padding: 15px 10px;
  border: none;
  font-size: 0.75rem;
  transition: color 0.3s, background 0.3s;
}
.compact-sidebar > .side-navbar a[data-toggle="collapse"]::before {
  content: "\f123";
  transform: translateX(50%);
  position: absolute;
  top: auto;
  right: 50%;
  bottom: 0;
  left: auto;
}
.compact-sidebar
  > .side-navbar
  a[data-toggle="collapse"][aria-expanded="true"]::before {
  content: "\f126";
}
.compact-sidebar > .side-navbar a:hover {
  border: none;
}
.compact-sidebar > .side-navbar a {
  padding: 10px 0;
}
.compact-sidebar > .side-navbar a i {
  margin-right: 0;
  margin-bottom: 5px;
  display: block;
  font-size: 1.7rem;
  transition: color 0.3s;
}
.compact-sidebar > .side-navbar .sidebar-header .title {
  display: none;
}
.compact-light-sidebar {
  background: #fff;
  position: fixed;
  height: 100%;
  top: 0;
  z-index: 999;
  transition: all 0.2s ease;
}
.compact-light-sidebar .sidebar-separator {
  background: #aea9c3;
  height: 2px;
  width: 50%;
  margin: 0.65rem auto;
  opacity: 0.3;
}
.compact-light-sidebar > .side-navbar.shrinked {
  margin-left: -90px;
  min-width: 90px;
  max-width: 90px;
  text-align: center;
}
.compact-light-sidebar > .side-navbar a {
  color: inherit;
  position: relative;
  font-size: 1rem;
}
.compact-light-sidebar > .side-navbar a[data-toggle="collapse"]::before {
  color: #aea9c3;
  font-size: 0.85rem;
  content: "\f124";
  display: inline-block;
  transform: translateY(-50%);
  font-family: "ionicons";
  position: absolute;
  top: 50%;
  right: 20px;
}
.compact-light-sidebar > .side-navbar a[aria-expanded="true"] {
  background: #fff;
  margin: 0 10px 0 10px;
  border-radius: 4px 4px 0 0;
}
.compact-light-sidebar > .side-navbar a[aria-expanded="true"] i {
  color: #e76c90;
}
.compact-light-sidebar > .side-navbar a[aria-expanded="true"]::before {
  content: "\f123";
}
.compact-light-sidebar > .side-navbar a i {
  font-size: 1.4rem;
  margin-right: 10px;
  transition: none;
  vertical-align: -2px;
  color: #5d5386;
}
.compact-light-sidebar > .side-navbar .sidebar-header {
  padding: 30px 15px;
}
.compact-light-sidebar > .side-navbar span.heading {
  font-weight: 600;
  margin-left: 10px;
  color: #e76c90;
  font-size: 0.85rem;
  text-transform: uppercase;
}
.compact-light-sidebar > .side-navbar ul {
  padding: 10px 0;
}
.compact-light-sidebar > .side-navbar ul a {
  color: #5d5386;
  padding: 10px 2px;
  text-decoration: none;
  display: block;
  font-weight: 600;
  text-align: center;
  position: relative;
}
.compact-light-sidebar > .side-navbar ul a:hover i {
  color: #e76c90;
}
.compact-light-sidebar > .side-navbar ul li.active i,
.compact-light-sidebar > .side-navbar li ul li a.active {
  color: #e76c90;
}
.compact-light-sidebar > .side-navbar ul a:hover span {
  opacity: 1;
  bottom: 0;
}
.compact-light-sidebar > .side-navbar ul a span {
  position: absolute;
  right: 0;
  bottom: -5px;
  left: 0;
  z-index: 20;
  opacity: 0;
  text-align: center;
  transition: bottom 0.3s, opacity 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
}
.compact-light-sidebar > .side-navbar ul ul {
  margin: 0 10px 0 10px;
}
.compact-light-sidebar > .side-navbar ul ul {
  background: #fff;
  border-radius: 0 0 4px 4px;
}
.compact-light-sidebar > .side-navbar ul ul a:hover {
  color: #e76c90;
}
.compact-light-sidebar > .side-navbar ul ul a:focus {
  color: #aea9c3;
}
.compact-light-sidebar > .side-navbar.shrinked ul.end-sidebar {
  padding-bottom: 0;
}
.compact-light-sidebar > .side-navbar {
  min-width: 90px;
  max-width: 90px;
  text-align: center;
  z-index: 999;
  position: relative;
  height: 100%;
  padding: 80px 0;
}
.compact-light-sidebar > .side-navbar span.heading {
  font-size: 0.7rem;
  margin: 0;
}
.compact-light-sidebar > .side-navbar a {
  padding: 15px 10px;
  border: none;
  font-size: 0.8rem;
  transition: color 0.3s, background 0.3s;
}
.compact-light-sidebar > .side-navbar a[data-toggle="collapse"]::before {
  content: "\f123";
  transform: translateX(50%);
  position: absolute;
  top: auto;
  right: 50%;
  bottom: 0;
  left: auto;
}
.compact-light-sidebar
  > .side-navbar
  a[data-toggle="collapse"][aria-expanded="true"]::before {
  content: "\f126";
}
.compact-light-sidebar > .side-navbar a:hover {
  border: none;
}
.compact-light-sidebar > .side-navbar a {
  padding: 10px 0;
}
.compact-light-sidebar > .side-navbar a i {
  margin-right: 0;
  margin-bottom: 5px;
  display: block;
  font-size: 2rem;
  transition: color 0.3s;
}
.compact-light-sidebar > .side-navbar .sidebar-header .title {
  display: none;
}
.db-social .compact-sidebar > .side-navbar ul a {
  padding: 18px 0;
}
.horizontal-menu {
  background: #2c304d;
  padding: 0 30px;
}
.navbar-light .navbar-toggler {
  color: #aea9c3;
  border-color: transparent;
  padding: 1.2rem 1.07rem;
}
.navbar-toggler span {
  background: #aea9c3;
  width: 20px;
  height: 1px;
  display: block;
  margin: 6px 0 0;
  transition: all 0.3s cubic-bezier(0.81, -0.33, 0.345, 1.375);
}
.navbar-toggler span:nth-of-type(2) {
  position: relative;
  width: 30px;
}
.horizontal-menu .dropdown-menu {
  padding: 0;
  margin: 0;
  border: 0 solid transition !important;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
}
.horizontal-menu .main-menu a,
.navbar-default .navbar-nav > li > a,
.horizontal-menu .main-menu ul li a,
.navbar-expand-lg .navbar-nav .nav-link {
  color: #aea9c3;
  font-size: 1rem;
  padding: 1.2rem 1.07rem;
  font-family: "Montserrat", sans-serif;
  display: block !important;
}
.horizontal-menu .main-menu .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.5rem;
  vertical-align: top;
  content: "\f123";
  font-family: "Ionicons";
  border: 0 none;
}
.horizontal-menu .main-menu .active a,
.horizontal-menu .main-menu .active a:focus,
.horizontal-menu .main-menu .active a:hover {
  color: #e76c90;
  outline: 0;
}
.horizontal-menu .main-menu li a:hover,
.horizontal-menu .main-menu li a:focus {
  color: #aea9c3;
  outline: 0;
}
.horizontal-menu .navbar-default .navbar-nav > .show > a,
.horizontal-menu .navbar-default .navbar-nav > .show > a:focus,
.horizontal-menu .navbar-default .navbar-nav > .show > a:hover {
  color: #aea9c3;
  outline: 0;
}
.horizontal-menu .main-menu .dropdown-menu {
  min-width: 180px;
}
.horizontal-menu .main-menu .navbar-nav ul.dropdown-menu li a {
  font-size: 0.9rem;
}
.horizontal-menu .main-menu .navbar-nav ul.dropdown-menu li a i {
  color: #aea9c3;
  font-size: 1.6rem;
  vertical-align: middle;
  margin-right: 0.3rem;
}
.horizontal-menu .main-menu .collapse ul > li:hover > a {
  color: #e76c90;
}
.horizontal-menu .main-menu .collapse ul ul > li > a {
  color: #2c304d;
}
.horizontal-menu .main-menu .collapse ul ul > li:hover > a,
.horizontal-menu
  .navbar-default
  .navbar-nav
  .show
  .dropdown-menu
  > li
  > a:focus,
.horizontal-menu
  .navbar-default
  .navbar-nav
  .show
  .dropdown-menu
  > li
  > a:hover {
  color: #e76c90;
}
.horizontal-menu .main-menu .collapse ul ul ul > li:hover > a {
  color: #e76c90;
}
.horizontal-menu .main-menu .collapse ul ul,
.horizontal-menu .main-menu .collapse ul ul.dropdown-menu {
  background: #fff;
  color: #2c304d;
  border-radius: 4px;
}
.horizontal-menu .main-menu .collapse ul ul ul,
.menu-area .main-menu .collapse ul ul ul.dropdown-menu {
  background: #fff;
  border-radius: 4px;
}
.horizontal-menu .main-menu {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  min-height: 20px;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .horizontal-menu .main-menu .collapse ul ul {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 180px;
    display: none;
  }
  .horizontal-menu .main-menu .collapse ul ul li {
    position: relative;
  }
  .horizontal-menu .main-menu .collapse ul ul li:hover > ul {
    display: block;
  }
  .horizontal-menu .main-menu .collapse ul ul ul {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 250px;
    display: none;
  }
  .horizontal-menu .main-menu .collapse ul ul ul li {
    position: relative;
  }
  .horizontal-menu .main-menu .collapse ul ul ul li:hover ul {
    display: block;
  }
  .horizontal-menu .main-menu .collapse ul ul ul ul {
    position: absolute;
    top: 0;
    left: -100%;
    min-width: 250px;
    display: none;
    z-index: 1;
  }
}
@media only screen and (max-width: 768px) {
  .horizontal-menu nav.navbar .dropdown-menu {
    right: 0;
    left: 0;
    transform: none;
  }
  .horizontal-menu .main-menu .collapse ul ul,
  .horizontal-menu .main-menu .collapse ul ul.dropdown-menu {
    background: transparent;
    color: #aea9c3;
  }
  .horizontal-menu .main-menu .collapse ul ul > li > a {
    color: #aea9c3;
  }
  .horizontal-menu .main-menu .collapse ul ul ul.dropdown-menu {
    box-shadow: none;
  }
  .horizontal-menu .navbar-nav .show .dropdown-menu .dropdown-menu > li > a {
    padding: 16px 15px 16px 35px;
  }
  .horizontal-menu
    .navbar-nav
    .show
    .dropdown-menu
    .dropdown-menu
    .dropdown-menu
    > li
    > a {
    padding: 16px 15px 16px 45px;
  }
}
.light-sidebar {
  background: #fff;
}
.light-sidebar .side-navbar span.heading {
  color: #5d5386;
}
.light-sidebar .side-navbar ul a,
.light-sidebar .side-navbar ul a i {
  color: #aea9c3;
}
.off-sidebar {
  background: rgba(46, 52, 81, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  z-index: 99999;
  transition: visibility 0s 0.6s;
}
.off-sidebar::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
  transition: background 0.3s 0.3s;
}
.off-sidebar.is-visible {
  visibility: visible;
  transition: visibility 0s 0s;
}
.off-sidebar.is-visible::after {
  background: rgba(0, 0, 0, 0.6);
  transition: background 0.3s 0s;
}
.off-sidebar-header {
  background: #fff;
  width: 100%;
  height: 60px;
  z-index: 2;
}
.off-sidebar-header h2 {
  font-family: "Montserrat", sans-serif;
  color: #5d5386;
  padding: 25px 0 0 15px;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0;
}
.off-sidebar-close:before {
  color: #aea9c3;
  content: "\f191";
  font-family: "LineAwesome";
  position: absolute;
  top: 15px;
  right: 15px;
  height: 35px;
  width: 35px;
  line-height: 30px;
  display: inline-block;
  border: 2px solid #aea9c3;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  font-size: 1.2rem;
  font-weight: 500;
  opacity: 0.4;
}
.off-sidebar-container {
  background: #fff;
  position: fixed;
  width: 430px;
  height: 100%;
  top: 0;
  z-index: 1;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
}
.off-sidebar-container.mini {
  width: 150px;
}
.from-right .off-sidebar-container {
  right: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
.from-left .off-sidebar-container {
  left: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  -ms-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}
.is-visible .off-sidebar-container {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-delay: 0s;
}
.off-sidebar-content {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 1.07rem;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.off-sidebar-content .date {
  color: #e76c90;
  font-size: 0.9rem;
  text-align: center;
  display: block;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 0;
}
.messenger-image {
  float: left;
  border-radius: 50%;
}
.messenger-image.messenger-image-default {
  width: 50px;
  height: 50px;
}
.messenger-message {
  margin-bottom: 35px;
  width: 100%;
  display: table;
  overflow: hidden;
}
.messenger-message:before {
  content: " ";
  display: block;
}
.messenger-message:last-of-type {
  margin-bottom: 0;
}
.messenger-message.messenger-message-sender .messenger-message-wrapper {
  max-width: 80%;
  display: table;
  padding: 5px 15px 0;
  position: relative;
}
.messenger-message.messenger-message-sender .messenger-message-wrapper:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border: 12px solid transparent;
  top: 12px;
  position: absolute;
}
.messenger-message.messenger-message-sender .messenger-message-wrapper span {
  color: #aea9c3;
  display: block;
  font-size: 0.85rem;
}
.messenger-message.messenger-message-sender .messenger-message-wrapper p {
  background-color: #f0f2f7;
  border-top-color: #f0f2f7;
  font-size: 0.95rem;
  padding: 1.2rem 1.4rem;
  margin-bottom: 0.85rem;
  color: #2a2c3d;
  border-top: 1px solid;
  border-radius: 4px;
}
.messenger-message.messenger-message-sender
  .messenger-message-wrapper
  p:first-of-type {
  border-top: 0 !important;
}
.messenger-message.messenger-message-sender .messenger-message-wrapper,
.messenger-message.messenger-message-sender .messenger-message-content {
  float: left;
}
.messenger-message.messenger-message-sender .messenger-message-wrapper:before {
  left: -5px;
  border-right-color: #f0f2f7;
}
.messenger-message.messenger-message-sender p {
  color: #2c304d;
  background-color: #e4e8f0;
  border-top-color: #e4e8f0;
}
.messenger-message.messenger-message-sender p span {
  font-size: 21px;
  vertical-align: middle;
}
.messenger-message.messenger-message-sender .messenger-details {
  clear: both;
  width: 100%;
  display: table;
  overflow: hidden;
}
.messenger-message.messenger-message-sender .messenger-details:before {
  content: " ";
  display: block;
}
.messenger-message.messenger-message-sender .messenger-details span {
  color: #aea9c3;
  font-size: 0.7rem;
  font-weight: 600;
}
.messenger-message.messenger-message-recipient .messenger-message-wrapper {
  max-width: 90%;
  display: table;
  padding: 0 10px 0;
  position: relative;
}
.messenger-message.messenger-message-recipient
  .messenger-message-wrapper:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  top: 14px;
  position: absolute;
}
.messenger-message.messenger-message-recipient .messenger-message-wrapper p {
  font-size: 0.95rem;
  padding: 1.2rem 1.4rem;
  margin-bottom: 0.85rem;
  color: #fff;
  border-top: 1px solid;
  border-radius: 4px;
}
.messenger-message.messenger-message-recipient
  .messenger-message-wrapper
  p:first-of-type {
  border-top: 0 !important;
}
.messenger-message.messenger-message-recipient .messenger-message-wrapper,
.messenger-message.messenger-message-recipient .messenger-message-content {
  float: right;
}
.messenger-message.messenger-message-recipient
  .messenger-message-wrapper:before {
  right: -10px;
  border-left-color: rgba(52, 40, 104, 0.8);
}
.messenger-message.messenger-message-recipient p {
  color: #fff;
  background: #5d5386;
  border-top-color: #5d5386;
}
.messenger-message.messenger-message-recipient p span {
  font-size: 21px;
  vertical-align: middle;
}
.messenger-message.messenger-message-recipient .messenger-details {
  clear: both;
  float: right;
  display: table;
  overflow: hidden;
}
.messenger-message.messenger-message-recipient .messenger-details:before {
  content: " ";
  display: block;
}
.messenger-message.messenger-message-recipient .messenger-details span {
  color: #aea9c3;
  font-size: 0.7rem;
  font-weight: 600;
}
.messenger-message .messenger-message-content {
  display: table;
  overflow: hidden;
}
.messenger-message .messenger-message-content:before {
  content: " ";
  display: block;
}
.enter-message {
  padding: 10px 15px;
  position: fixed;
  bottom: 0;
  width: 100%;
}
.enter-message-form {
  display: table-cell;
  width: 100%;
}
.enter-message-button {
  display: table-cell;
  vertical-align: middle;
  padding: 0;
}
.enter-message input {
  border: 0 none;
  padding: 10px 15px;
  background: #e4e8f0;
  color: #2c304d;
  width: 100%;
  border-radius: 20px;
  font-weight: 500;
}
.enter-message input::placeholder {
  color: #2a2c3d;
  font-weight: 500;
}
.enter-message a.send {
  padding: 0 15px;
  border-radius: 20px;
}
.enter-message a.send i {
  font-size: 2rem;
}
.off-sidebar-content .sidebar-heading {
  color: #5d5386;
  font-size: 1rem;
  display: block;
  font-weight: 600;
  text-transform: uppercase;
  padding: 1.07rem 0;
}
.off-sidebar-content .today-stats i {
  color: #aea9c3;
  font-size: 2.4rem;
}
.off-sidebar-content .today-stats .heading {
  color: #98a8b4;
  font-size: 1rem;
}
.off-sidebar-content .today-stats .counter {
  color: #2c304d;
  font-size: 1.2rem;
  font-weight: 700;
}
.off-sidebar-content .list-group-item {
  border: 0 none;
}
.off-sidebar-content .quick-friends .list-group-item i {
  font-size: 2rem;
}
.widget-01 h3 {
  color: #2c304d;
  font-size: 1.2rem;
  padding: 1.4rem 0 1rem;
  margin-bottom: 0;
  text-align: center;
}
.widget-01 h3 span {
  font-weight: 500;
}
.widget-01 .circle {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 1.4rem;
  position: relative;
  text-align: center;
}
.widget-01 .circle canvas {
  max-width: 100%;
  max-height: 100%;
}
.widget-01 .circle .percent {
  color: #2c304d;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: 3rem;
}
.widget-01 .circle .percent i {
  font-style: normal;
  font-size: 2rem;
  font-weight: 400;
}
.widget-01 .other-stats {
  padding: 0;
  margin: 0;
}
.widget-01 .today-sales .text {
  text-align: center;
  margin-top: -20px;
  padding-bottom: 20px;
}
.widget-01 .today-sales .text .heading {
  color: #98a8b4;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: -0.5rem;
}
.widget-01 .today-sales .text .number {
  color: #2c304d;
  display: block;
  font-size: 2.4rem;
  font-weight: 600;
}
.widget-01 .today-sales .graph {
  position: relative;
  overflow: hidden;
}
.widget-01 .today-sales .value {
  font-weight: 600;
}
.widget-01 .elisyam-version {
  text-align: center;
  padding: 0 0 2rem;
}
.widget-01 .elisyam-version p {
  color: #5d5386;
  font-size: 0.85rem;
  margin-bottom: 0;
}
.widget-02 .chart {
  padding: 0;
}
.widget-02 .more-charts {
  padding: 2.5rem 0;
}
.widget-03.blog-image {
  background: url(img/blog/blog-01.jpg);
  display: block;
  background-position: 50% 0%;
  background-size: cover;
}
.widget-03 h3 {
  margin-bottom: 0;
}
.widget-03 .blog-title a {
  color: #fff;
  display: inline-block;
  font-size: 3rem;
  font-weight: 600;
  text-decoration: none;
}
.widget-03 .author {
  color: #fff;
  font-size: 1rem;
}
.widget-03 .blog-overlay {
  background: rgba(46, 52, 81, 0.4);
  content: "";
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.widget-03 .blog-content {
  position: absolute;
  padding: 0 2rem;
  bottom: 10%;
  z-index: 2;
}
.widget-03 .widget-body {
  position: relative;
}
.widget-03 .blog-category {
  padding-top: 1.4rem;
}
.widget-03 .blog-category a {
  background: rgb(226, 63, 131);
  background: linear-gradient(
    to right,
    rgba(226, 63, 131, 1) 0%,
    rgba(234, 88, 68, 1) 100%
  );
  background-size: 250% auto;
  font-weight: 500;
  transition: 0.5s;
  color: #fff;
  padding: 0.7rem 1.4rem;
  border-radius: 50px;
  font-size: 0.85rem;
}
.widget-03 .blog-category a:hover {
  background-position: right center;
}
.widget-03 .meta {
  padding-top: 0.5rem;
}
.widget-03 li {
  display: inline-block;
  margin-right: 10px;
  color: rgba(255, 255, 255, 0.8);
}
.widget-03 li i {
  font-size: 1.4rem;
  margin-right: 5px;
  vertical-align: middle;
}
.widget-04 .post-container {
  position: relative;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.1);
  padding: 1.07rem;
  margin: 1.4rem;
}
.widget-04 .post-container img {
  border-radius: 4px;
}
.widget-04 .user img {
  width: 40px;
}
.widget-04 .user {
  position: relative;
  display: inline-block;
}
.widget-04 .title {
  color: #2c304d;
  font-size: 1rem;
}
.widget-04 .username {
  color: #e76c90;
}
.widget-04 .time {
  font-size: 0.85rem;
}
.widget-04 .meta li {
  display: inline-block;
  margin-right: 0.5rem;
}
.widget-04 .meta li:last-child {
  margin-right: 0;
}
.widget-04 .meta li a {
  color: rgba(52, 40, 104, 0.3);
}
.widget-04 .meta li a:hover {
  color: rgba(52, 40, 104, 0.9);
}
.widget-04 .meta li i {
  font-size: 1.8rem;
  vertical-align: middle;
  margin-right: 0.3rem;
}
.widget-04 .meta li .numb {
  vertical-align: middle;
}
.widget-04 .input-group input {
  border: 0 none;
  border-top: 1px solid #eee;
}
.widget-04 .input-group input::placeholder {
  color: rgba(52, 40, 104, 0.3);
  font-weight: 500;
}
.widget-04 .input-group-addon {
  border: 0 !important;
  border-top: 1px solid #eee !important;
  padding: 0.75rem 0 !important;
}
.widget-04 .input-group-addon .btn {
  background: #fff;
  color: rgba(52, 40, 104, 0.3);
  padding: 0 5px;
  border-radius: 0;
  border: 0;
}
.widget-05 .author-avatar {
  width: 80px;
  height: 80px;
  margin: 50px auto 0;
  position: relative;
}
.widget-05 .author-avatar:before {
  content: "";
  height: 140px;
  width: 140px;
  border-radius: 100%;
  border: 2px dashed rgba(52, 40, 104, 0.08);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.widget-05 .author-avatar:after {
  content: "";
  height: 205px;
  width: 205px;
  border-radius: 100%;
  border: 2px dashed rgba(52, 40, 104, 0.07);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.widget-05 .author-avatar span {
  position: absolute;
  right: 0;
  top: 8px;
  border: 3px solid #fff;
}
.widget-05 .author-name {
  color: #5d5386;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  padding-top: 0.8rem;
  position: relative;
  text-shadow: 0 1px 0 #fff;
}
.widget-05 .author-name span {
  font-size: 1rem;
  color: #98a8b4;
  display: block;
}
.widget-05 .chart {
  padding: 0.8rem 1.4rem 0 1.4rem;
}
.widget-05 .chart-text .heading {
  color: #98a8b4;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: -0.5rem;
}
.widget-05 .chart-text .number {
  color: #2c304d;
  display: block;
  font-size: 2rem;
  font-weight: 600;
}
.widget-05 .chart-graph {
  position: relative;
}
.widget-05 .chart-text .cxg {
  font-weight: 700;
}
.widget-05 .social-stats {
  margin: 1.07rem 0 1.8rem;
  padding: 0 3rem;
}
.widget-05 .social-stats i {
  font-size: 2.4rem;
}
.widget-05 .social-stats i.followers {
  color: rgba(52, 40, 104, 0.3);
}
.widget-05 .social-stats i.dribbble {
  color: rgba(52, 40, 104, 0.3);
}
.widget-05 .social-stats i.behance {
  color: rgba(52, 40, 104, 0.3);
}
.widget-05 .social-stats .heading {
  color: #98a8b4;
  font-size: 1rem;
}
.widget-05 .social-stats .counter {
  color: #2c304d;
  font-size: 1.2rem;
  font-weight: 700;
}
.widget-05 .actions {
  margin-bottom: 1.8rem;
}
.widget-06 .reviews img {
  width: 40px;
}
.widget-06 .reviews .user-img {
  position: relative;
  display: inline-block;
}
.widget-06 .reviews .list-group-item {
  display: block;
  margin: 0;
  padding: 1.8rem 1.07rem;
  border: 0 none;
  border-bottom: 0.07rem solid #eee !important;
  background: transparent;
}
.widget-06 .reviews .list-group-item:first-child {
  border-top: 0;
  border-radius: 0 !important;
}
.widget-06 .reviews .list-group-item:last-child {
  border-bottom: 0;
  border-radius: 0 !important;
}
.widget-06 .media h4 {
  font-size: 1.2rem;
  color: #2c304d;
  margin-bottom: 0.3rem;
}
.widget-06 .media .stars i {
  color: rgba(52, 40, 104, 0.4);
  font-size: normal;
}
.widget-06 .media .media-body {
  padding: 0 0.8rem;
}
.widget-06 .media p {
  font-size: 0.95rem;
  color: #94a4b0;
  margin: 0;
}
.widget-06 .media .meta {
  font-size: 0.85rem;
  padding-top: 0.8rem;
  font-weight: 600;
}
.widget-06 .media .meta a {
  font-style: italic;
}
.widget-06 .like {
  color: #e76c90;
}
.widget-06 .media .like i {
  display: block;
  font-size: 1.4rem;
}
.widget-06 .media .like span {
  font-weight: 600;
}
.widget-07 .display-items {
  font-size: 0.85rem;
  margin-top: 0.3rem;
}
.widget-08 .widget-body {
  position: relative;
  overflow: hidden;
}
.widget-08 .today {
  line-height: 1;
  padding: 1.07rem 0;
}
.widget-08 .today .title {
  color: #5d5386;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
}
.widget-08 .today .new-tasks .nb {
  color: #e76c90;
  font-size: 2.4rem;
  font-weight: 600;
}
.widget-08 input[type="checkbox"]:checked + label {
  text-decoration: line-through;
  color: rgba(52, 40, 104, 0.4);
}
.widget-08 .list {
  list-style: none;
  padding: 0;
  color: #2c304d;
}
.widget-08 .list li {
  width: 100%;
  margin: 0.8rem 0;
  border-radius: 4px;
  padding: 1.07rem 1rem;
  position: relative;
}
.widget-08 .list li.task-color:before {
  content: "";
  height: 100%;
  width: 3px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
}
.widget-08 .list li.task-violet:before {
  background: rgba(52, 40, 104, 0.8);
}
.widget-08 .list li.task-blue:before {
  background: #17a2b8;
}
.widget-08 .list li.task-red:before {
  background: #dc3545;
}
.widget-08 .description {
  margin-top: 1rem;
  display: block;
}
.widget-09 .some-stats {
  line-height: 1.2;
}
.widget-09 .some-stats .title {
  font-size: 1rem;
  color: #2c304d;
}
.widget-09 .some-stats .number {
  font-size: 1.8rem;
  font-weight: 600;
}
.widget-09 .new-orders .title {
  font-size: 1.2rem;
  margin-bottom: 0.85rem;
  color: #2c304d;
}
.widget-09 .circle-orders {
  margin: 0 auto;
  position: relative;
  text-align: center;
}
.widget-09 .circle-orders canvas {
  max-width: 100%;
  max-height: 100%;
}
.widget-09 .circle-orders .percent-orders {
  color: #2c304d;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
  font-size: 2.4rem;
}
.widget-09 .circle-orders .percent-orders i {
  font-style: normal;
  font-size: 1.4rem;
  font-weight: 600;
}
@media (max-width: 576px) {
  .widget-09 .new-orders .title {
    margin-top: 1.07rem;
  }
}
.widget-10 .ticket img {
  width: 50px;
}
.widget-10 .ticket .user-img {
  position: relative;
  display: inline-block;
}
.widget-10 .ticket .list-group-item {
  display: block;
  margin: 0;
  padding: 1.4rem 1.4rem;
  border: 0 none;
  border-bottom: 0.07rem solid #eee !important;
  background: transparent;
}
.widget-10 .ticket .list-group-item:first-child {
  border-top: 0;
  border-radius: 0 !important;
}
.widget-10 .ticket .list-group-item:last-child {
  border-bottom: 0 !important;
  border-radius: 0 !important;
}
.widget-10 .media h4 {
  font-size: 1.2rem;
  color: #2c304d;
  margin-bottom: 0.3rem;
}
.widget-10 .media .status {
  color: rgba(52, 40, 104, 0.5);
  font-size: 0.9rem;
}
.widget-10 .media p {
  color: #94a4b0;
  margin: 0;
}
.widget-10 span {
  color: #fff;
  font-style: normal;
  font-weight: 600;
  padding: 0.5rem 0;
}
.widget-10 span.open {
  color: #60c400;
}
.widget-10 span.pending {
  color: #08a6c3;
}
.widget-10 span.closed {
  color: #fe195e;
}
.widget-11 .timeline:before {
  position: absolute;
  display: block;
  left: 28px;
  width: 4px;
  height: 100%;
  background-color: rgba(52, 40, 104, 0.08);
  content: " ";
}
.widget-11 .timeline:after {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 25px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: 0 0 0 3px #fff;
  content: " ";
}
.widget-11 .timeline.violet:after {
  background: #5d5386;
}
.widget-11 .timeline.red:after {
  background: #fe195e;
}
.widget-11 .timeline.blue:after {
  background: #08a6c3;
}
.widget-11 .timeline.green:after {
  background: #60c400;
}
.widget-11 .timeline {
  position: relative;
}
.widget-11 .timeline-content {
  padding: 1.07rem 1.4rem 1.07rem 4rem;
  position: relative;
  width: 100%;
}
.widget-11 .timeline-content .user-image {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.07rem;
}
.widget-11 .timeline-content .user-image img {
  width: 40px;
}
.widget-11 .timeline-content .timeline-icon {
  color: rgba(52, 40, 104, 0.4);
  font-size: 2.4rem;
  width: 40px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1.07rem;
}
.widget-11 .timeline-content .title {
  color: #2c304d;
  font-size: 1rem;
}
.widget-11 .timeline-content .username {
  color: #e76c90;
}
.widget-11 .timeline-content .time {
  font-size: 0.85rem;
}
.widget-11 .users-like {
  padding: 0.4rem 0;
}
.widget-11 .users-like a {
  margin: 0 -1.6rem 0 0;
  transition: all 0.4s ease;
}
.widget-11 .users-like a:hover {
  margin-right: -0.3rem;
}
.widget-11 .users-like img {
  width: 40px;
  border: 0.25rem solid #fff;
}
.widget-12 .widget-body {
  position: relative;
  overflow: hidden;
}
.widget-12 i {
  font-size: 4rem;
}
.widget-12 .title {
  color: #2c304d;
  font-size: 1.2rem;
}
.widget-12 .number {
  font-size: 1rem;
}
.widget-13 .widget-body {
  position: relative;
  overflow: hidden;
}
.widget-13 .author-avatar {
  width: 100px;
  height: 100px;
  margin: 50px auto 0;
  position: relative;
}
.widget-13 .author-avatar img {
  z-index: 1;
}
.widget-13 .author-avatar:before {
  content: "";
  height: 130px;
  width: 130px;
  border-radius: 100%;
  background: rgba(52, 40, 104, 0.05);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.widget-13 .author-avatar:after {
  content: "";
  height: 160px;
  width: 160px;
  border-radius: 100%;
  background: rgba(52, 40, 104, 0.05);
  position: absolute;
  left: 50%;
  top: 50%;
  box-shadow: 0 0 0 15px rgba(52, 40, 104, 0.025);
  transform: translate(-50%, -50%);
}
.widget-13 .author-avatar span {
  position: absolute;
  right: 0;
  top: 8px;
  border: 3px solid #fff;
}
.widget-13 .author-name {
  color: #5d5386;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  margin-top: 2.6rem;
  position: relative;
  text-shadow: 0 1px 0 #fff;
}
.widget-13 .author-name span {
  font-size: 1rem;
  color: #98a8b4;
  display: block;
}
.widget-13 .social-stats {
  margin-bottom: 150px;
}
.widget-13 .social-stats i {
  font-size: 2.4rem;
}
.widget-13 .social-stats i.followers {
  color: rgba(52, 40, 104, 0.3);
}
.widget-13 .social-stats i.dribbble {
  color: rgba(52, 40, 104, 0.3);
}
.widget-13 .social-stats i.behance {
  color: rgba(52, 40, 104, 0.3);
}
.widget-13 .social-stats .heading {
  color: #98a8b4;
  font-size: 1rem;
}
.widget-13 .social-stats .counter {
  color: #2c304d;
  font-size: 1.2rem;
  font-weight: 700;
}
.widget-13 .follow-btn .btn {
  padding: 10px 30px;
}
.shape-container {
  position: relative;
  -webkit-transform: rotate(5deg);
  transform: rotate(5deg);
}
.widget-13 .wavy {
  position: absolute;
  bottom: -30px;
  width: 130%;
  left: -20%;
}
.widget-14 .owl-item .item {
  margin: 1.07rem;
  padding: 2rem 1.07rem;
  border-radius: 4px;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.15);
  text-align: center;
}
.widget-14 .item-projects .month {
  color: #2c304d;
  font-size: 1.4rem;
  font-weight: 600;
}
.widget-14 .item-projects .year {
  font-size: 0.95rem;
}
.widget-14 .owl-item.active.center .item {
  background: #e76c90;
  color: #fff;
  padding: 2.6rem 1.07rem;
  margin: 0;
}
.widget-14 .owl-item.active.center .item .month {
  color: #fff;
  font-size: 1.4rem;
}
.widget-14 .owl-item.active.center .item .year {
  font-size: 1.1rem;
}
.widget-14 .owl-dots {
  float: left;
  display: block;
  margin-top: 1.8rem;
  text-align: center;
  width: 100%;
}
.widget-14 .owl-dots .owl-dot {
  border: 2px solid #98a8b4;
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  margin: 0 0.3rem;
  width: 12px;
  transition: all 0.3s ease 0s;
}
.widget-14 .owl-dots .owl-dot:hover {
  border-color: #5d5386;
}
.widget-14 .owl-dots .owl-dot.active {
  border: 2px solid #5d5386;
}
.widget-14 .owl-carousel .owl-nav {
  margin-top: 1.4rem;
  text-align: center !important;
}
.widget-14 .owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
  display: inline;
  margin: 0 5px;
}
.widget-14 .owl-prev,
.owl-next {
  color: rgba(52, 40, 104, 0.4);
  font-size: 1.6rem;
  -webkit-transition: ease 0.6s;
  -moz-transition: ease 0.6s;
  -ms-transition: ease 0.6s;
  -o-transition: ease 0.6s;
  transition: ease 0.6s;
}
.widget-14 .owl-prev:hover,
.owl-next:hover {
  color: #5d5386;
}
.widget-14 .list-group-item {
  border: 0 none;
}
.widget-14 .list-group-item .event-date {
  color: #2c304d;
  font-size: 2rem;
  font-weight: 600;
}
.widget-14 .list-group-item .event-title {
  color: #2c304d;
  font-weight: 600;
}
.widget-14 .list-group-item .event-desc {
  display: inline-block;
}
.widget-14 .list-group-item .event-desc i {
  font-size: 1.3rem;
  vertical-align: middle;
}
.widget-14 .list-group-item .event-desc span {
  color: #2c304d;
  font-size: 0.85rem;
}
.widget-15 .weather-infos {
  line-height: 1.2;
}
.widget-15 .weather-infos .temp {
  color: #2c304d;
  font-size: 4rem;
}
.widget-15 .weather-infos .city {
  color: #5d5386;
  font-size: 2rem;
}
.widget-15 .weather-infos i {
  color: rgba(52, 40, 104, 0.4);
  font-size: 2rem;
  vertical-align: middle;
}
.widget-15 .weather-infos,
.widget-15 .weather-icon {
  padding: 1.07rem 0;
}
.widget-15 .weather-icon i {
  color: #2c304d;
  font-size: 10rem;
}
.widget-15 .time-nav {
  font-weight: 500;
}
.widget-15 .nav-tabs {
  border: 0 none;
}
.widget-15 .nav-tabs > li > a::after {
  display: none;
}
.widget-15 .time-nav * {
  transition: all 0.35s ease;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.widget-15 .time-nav li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 0.5rem;
  padding: 0;
}
.widget-15 .time-nav li:first-child {
  margin-left: 0;
}
.widget-15 .time-nav li:last-child {
  margin-right: 0;
}
.widget-15 .time-nav a {
  padding: 0.65rem 0;
  color: #2c304d;
  text-decoration: none;
  display: inline-block;
}
.widget-15 .time-nav a.active {
  background: #5d5386;
  padding: 0.65rem 1.07rem;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  border-radius: 35px;
}
.widget-15 .time-nav a.active:hover {
  color: #fff !important;
}
.widget-15 .time-nav a:hover {
  color: #5d5386;
}
.widget-15 .time-nav a:before {
  bottom: 0;
  left: 0;
}
.widget-15 .owl-item .item {
  text-align: center;
}
.widget-15 .owl-item .item .weather-mini-icon i {
  color: rgba(52, 40, 104, 0.4);
  font-size: 4rem;
}
.widget-15 .owl-item .item .day {
  color: #2c304d;
  font-size: 1.2rem;
}
.widget-15 .owl-item .item .weather-temp {
  color: #2c304d;
  font-size: 2.6rem;
  margin-top: 0.35rem;
  font-weight: 600;
}
.widget-15 .owl-item .item .rain i {
  color: rgba(52, 40, 104, 0.4);
  font-size: 1.2rem;
  vertical-align: middle;
}
.widget-15 .owl-dots {
  float: left;
  display: block;
  margin-top: 2rem;
  text-align: center;
  width: 100%;
}
.widget-15 .owl-dots .owl-dot {
  background: rgba(52, 40, 104, 0.2);
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin: 0 0.3rem;
  width: 10px;
  transition: all 0.3s ease 0s;
}
.widget-15 .owl-dots .owl-dot:hover {
  background: #5d5386;
}
.widget-15 .owl-dots .owl-dot.active {
  background: #5d5386;
}
.widget-15 .owl-carousel .owl-nav {
  margin-top: 2rem;
  text-align: center !important;
}
.widget-15 .owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next {
  display: inline;
  margin: 0 5px;
}
.widget-15 .owl-prev,
.owl-next {
  color: rgba(52, 40, 104, 0.4);
  font-size: 1.6rem;
  -webkit-transition: ease 0.6s;
  -moz-transition: ease 0.6s;
  -ms-transition: ease 0.6s;
  -o-transition: ease 0.6s;
  transition: ease 0.6s;
}
.widget-15 .owl-prev:hover,
.owl-next:hover {
  color: #5d5386;
}
@media (max-width: 1200px) {
  .widget-15 .owl-dots,
  .widget-15 .owl-carousel .owl-nav {
    margin-bottom: 1.07rem;
  }
}
@media (max-width: 991px) {
  .widget-15 .owl-dots,
  .widget-15 .owl-carousel .owl-nav {
    margin-bottom: 1.07rem;
  }
}
@media (max-width: 576px) {
  .widget-15 .owl-dots,
  .widget-15 .owl-carousel .owl-nav {
    margin-bottom: 1.07rem;
  }
}
.widget-16 .counter {
  color: #2c304d;
  font-size: 2.6rem;
}
.widget-16 .total-views {
  display: block;
  font-size: 1.2rem;
}
.widget-16 .pages-views {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
  text-align: center;
}
.widget-16 .pages-views canvas {
  max-width: 100%;
  max-height: 100%;
}
.widget-16 .pages-views .percent {
  color: #2c304d;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: 2rem;
}
.widget-16 .pages-views .percent i {
  font-style: normal;
  font-size: 1.4rem;
  font-weight: 400;
}
.widget-17 .counter {
  color: #2c304d;
  font-size: 2.6rem;
}
.widget-17 .total-visitors {
  display: block;
  font-size: 1.2rem;
}
.widget-17 .chart {
  overflow: hidden;
}
.widget-17 .visitors {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
  text-align: center;
}
.widget-17 .visitors canvas {
  max-width: 100%;
  max-height: 100%;
}
.widget-17 .visitors .percent {
  color: #2c304d;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: 2rem;
}
.widget-17 .visitors .percent i {
  font-style: normal;
  font-size: 1.4rem;
  font-weight: 400;
}
.widget-18 .input-group input {
  border: 0 none;
  border-bottom: 1px solid #eee;
  padding-left: 15px !important;
}
.widget-18 .input-group input::placeholder {
  color: #2a2c3d;
}
.widget-18 .input-group-addon {
  border: 0 none !important;
}
.widget-18 .input-group-addon {
  background: none;
}
.widget-18 .input-group-addon .btn {
  background: #fff;
  color: #5d5386;
  padding: 0 0 5px 0;
  border-radius: 0;
  border: 0;
}
.widget-18 .new-message {
  background: #6859ea;
  background: linear-gradient(135deg, #6859ea 0%, #6acbe0 60%);
  padding: 1.07rem;
  border-radius: 4px;
  width: 100%;
  margin: 1.07rem auto 0;
  position: relative;
}
.widget-18 .new-message-sender {
  color: #fff;
  font-weight: 600;
}
.widget-18 .new-message-time {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
}
.widget-18 .actions a i {
  background: transparent;
  color: #fff;
  font-size: 1.6rem;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.4s ease;
}
.widget-18 .actions a i.reply:hover {
  background: #fff;
  color: #5d5386;
}
.widget-18 .list-group-item {
  border: 0 none;
  padding-right: 0;
  padding-left: 0;
}
.widget-18 .other-message {
  background: #fff;
  padding: 1.07rem;
  border-radius: 4px;
  position: relative;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.15);
}
.widget-18 .other-message-sender {
  color: #2c304d;
  font-weight: 600;
}
.widget-18 .other-message-time {
  color: rgba(52, 40, 104, 0.4);
  font-size: 0.9rem;
}
.widget-18 .actions a i {
  background: transparent;
  color: #5d5386;
  font-size: 1.6rem;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.4s ease;
}
.widget-18 .actions a i.reply:hover {
  background: #5d5386;
  color: #fff;
}
.widget-19 .list-group-item {
  border: 0 none;
  border-radius: 0;
}
.widget-19 .list-group-item {
  background: #f2f3f8;
  padding: 0.65rem 1.8rem;
  z-index: 0;
}
.widget-19 .people-name {
  color: #2c304d;
  font-size: 0.9rem;
  font-weight: 600;
}
.widget-20 .card-image {
  display: block;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  margin: 0 1.07rem;
}
.widget-20 .card-image > img {
  display: block;
  margin-bottom: 0;
  transition: all 0.25s ease-in-out;
}
.widget-20 .card-image:hover > img {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.widget-20 .card-overlay {
  content: "";
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.widget-20 .card-overlay-01 {
  background: linear-gradient(
    to bottom,
    rgba(46, 52, 81, 0.5) 0%,
    rgba(106, 203, 224, 0.95) 100%
  );
  z-index: 2;
}
.widget-20 .card-overlay-02 {
  background: linear-gradient(
    to bottom,
    rgba(46, 52, 81, 0.4) 0%,
    rgba(205, 95, 109, 0.95) 100%
  );
  z-index: 2;
}
.widget-20 .card-overlay-content {
  position: absolute;
  bottom: 0;
  padding: 1.07rem;
  z-index: 3;
}
.widget-20 .category a {
  margin: 0;
  color: #fff;
  font-size: 0.85rem;
}
.widget-20 .card-title {
  color: #fff;
  display: inline-block;
  font-family: "Montserrat";
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 0;
  text-decoration: none;
  transition: all 0.3s ease-out;
}
.widget-20 .card-title:hover {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}
.widget-20 .owl-dots {
  float: left;
  display: block;
  margin-top: 2rem;
  text-align: center;
  width: 100%;
}
.widget-20 .owl-dots .owl-dot {
  background: rgba(52, 40, 104, 0.2);
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin: 0 0.3rem;
  width: 10px;
  transition: all 0.3s ease 0s;
}
.widget-20 .owl-dots .owl-dot:hover {
  background: #5d5386;
}
.widget-20 .owl-dots .owl-dot.active {
  background: #5d5386;
}
.widget-20 .media {
  border: 0.07rem solid #eee;
  border-radius: 4px;
  padding: 1.07rem 0.65rem;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.08);
}
.widget-20 .media i {
  color: #2c304d;
  font-size: 2.5rem;
}
.widget-20 .files-title {
  color: #2c304d;
  font-size: 1rem;
}
.widget-20 .files-number {
  font-size: 0.85rem;
}
.widget-21 .widget-body {
  position: relative;
  padding: 4rem 0;
  overflow: hidden;
}
.widget-21 .hit-rate {
  margin: 0 auto;
  padding: 0;
  position: relative;
  text-align: center;
}
.widget-21 .hit-rate canvas {
  max-width: 100%;
  max-height: 100%;
}
.widget-21 .hit-rate .percent {
  color: #2c304d;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: 2rem;
}
.widget-21 .hit-rate .percent i {
  font-style: normal;
  font-size: 2rem;
  font-weight: 400;
}
.widget-21 .hit-rate:before {
  content: "";
  height: 265px;
  width: 265px;
  border-radius: 100%;
  border: 2px dashed rgba(46, 52, 81, 0.2);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.widget-21 .hit-rate:after {
  content: "";
  height: 400px;
  width: 400px;
  border-radius: 100%;
  border: 2px dashed rgba(46, 52, 81, 0.15);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.widget-21 .section-title {
  position: absolute;
  top: 20px;
  left: 20px;
  text-shadow: 2px 2px 0 #fff;
}
.widget-21 .value-progress {
  position: absolute;
  top: 20px;
  right: 20px;
}
.widget-22 .widget-body {
  position: relative;
  padding: 4rem 0;
  overflow: hidden;
}
.widget-22 .happy-customers {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  position: relative;
  text-align: center;
}
.widget-22 .happy-customers canvas {
  max-width: 100%;
  max-height: 100%;
}
.widget-22 .happy-customers .percent {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: 2rem;
}
.widget-22 .happy-customers .percent i {
  font-style: normal;
  font-size: 2rem;
  font-weight: 400;
}
.widget-22 .happy-customers:before {
  content: "";
  height: 265px;
  width: 265px;
  border-radius: 100%;
  border: 2px dashed rgba(255, 255, 255, 0.2);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.widget-22 .happy-customers:after {
  content: "";
  height: 400px;
  width: 400px;
  border-radius: 100%;
  border: 2px dashed rgba(255, 255, 255, 0.15);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.widget-22 .section-title {
  position: absolute;
  top: 20px;
  left: 20px;
}
.widget-22 .section-title h3 {
  color: #fff;
}
.widget-22 .value-progress {
  color: #fff;
  position: absolute;
  top: 20px;
  right: 20px;
}
.widget-23 i {
  color: rgba(255, 255, 255, 0.4);
  font-size: 6rem;
}
.widget-23 .title {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 0.65rem;
}
.widget-23 .number {
  font-size: 1rem;
}
.widget-24 {
  position: relative;
}
.widget-24.bg-image {
  background: url(img/background/08.jpg);
  display: block;
  background-position: 50% 0%;
  background-size: cover;
}
.widget-24 .overlay {
  background: linear-gradient(
    135deg,
    rgba(104, 89, 234, 0.9) 0%,
    rgba(106, 203, 224, 0.9) 100%
  );
  content: "";
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.widget-24 .content {
  position: relative;
  z-index: 2;
}
.widget-24 .weather-infos {
  line-height: 1.2;
}
.widget-24 .weather-infos .temp {
  color: rgba(0, 0, 0, 0.4);
  font-size: 4rem;
}
.widget-24 .weather-infos i {
  color: #fff;
  font-size: 2rem;
  vertical-align: middle;
}
.widget-24 .weather-icon {
  padding: 1.07rem 0;
}
.widget-24 .weather-icon i {
  padding-top: 1.07rem;
  color: rgba(0, 0, 0, 0.4);
  font-size: 6rem;
}
.widget-24 .week-prev {
  background: rgba(0, 0, 0, 0.1);
  padding: 1.07rem;
}
.widget-24 .weather-mini-icon i {
  color: #fff;
  font-size: 2rem;
}
.widget-24 .day {
  color: #fff;
  font-size: 1rem;
}
.widget-24 .weather-temp {
  color: #fff;
  font-size: 1rem;
  margin: 0;
  font-weight: 500;
}
.widget-24 .city {
  padding-bottom: 2rem;
  color: #fff;
  font-size: 1.4rem;
  text-align: center;
}
.widget-25 .weather-report {
  color: #2c304d;
  font-size: 1.2rem;
  margin-bottom: 0;
}
.widget-25 .current-weather i {
  color: #aea9c3;
  font-size: 4rem;
}
.widget-25 .current-weather .temperature {
  color: #5d5386;
  font-size: 4rem;
  font-weight: 600;
}
.widget-25 .current-weather .condition {
  color: #aea9c3;
}
.widget-25 .weather-next-day .day {
  color: #2c304d;
  margin-bottom: 0;
}
.widget-25 .weather-next-day i {
  color: #aea9c3;
  font-size: 2rem;
}
.widget-25 .weather-next-day .temp {
  color: #5d5386;
  font-size: 1rem;
}
@media (max-width: 1200px) {
  .widget-25 .weather-report {
    font-size: 1rem;
    margin-bottom: 0;
  }
  .widget-25 .current-weather .temperature {
    font-size: 2rem;
  }
  .widget-25 .current-weather i {
    font-size: 2rem;
  }
  .widget-25 .weather-next-day i {
    font-size: 1.4rem;
  }
}
@media (max-width: 991px) {
  .widget-25 .weather-report {
    font-size: 1rem;
    margin-bottom: 0;
  }
  .widget-25 .current-weather .temperature {
    font-size: 2rem;
  }
  .widget-25 .current-weather i {
    font-size: 2rem;
  }
  .widget-25 .weather-next-day i {
    font-size: 1.4rem;
  }
}
@media (max-width: 576px) {
  .widget-25 .weather-report {
    font-size: 1rem;
    margin-bottom: 0;
  }
  .widget-25 .current-weather .temperature {
    font-size: 2rem;
  }
  .widget-25 .current-weather i {
    font-size: 2rem;
  }
  .widget-25 .weather-next-day i {
    font-size: 1.4rem;
  }
}
.widget-26.widget {
  position: relative;
  overflow: hidden;
}
.widget-26 .quick-stats {
  position: relative;
  overflow: hidden;
  z-index: 2;
}
.widget-26 .quick-act {
  width: 100%;
  z-index: 2;
}
.widget-26 .q-settings {
  padding: 1.07rem 0;
}
.widget-26 .q-settings i {
  color: #e76c90;
  font-size: 2.4rem;
}
.widget-26 .t-title {
  color: #2c304d;
}
.widget-27 .widget-body {
  position: relative;
}
.widget-27 .thermostat .temperature {
  color: #5d5386;
  font-size: 6rem;
  font-weight: 600;
  text-align: center;
}
.widget-27 .thermostat .temperature span::selection {
  background: transparent;
}
.widget-27 .thermostat .control.up {
  font-size: 2rem;
  color: rgba(254, 189, 128, 1);
}
.widget-27 .thermostat .control.down {
  font-size: 2rem;
  color: rgba(75, 195, 222, 1);
}
.widget-27 .thermostat .control i:hover {
  cursor: pointer;
}
.widget-27 .humidity i {
  color: #5d5386;
  display: block;
  font-size: 1.6rem;
}
.widget-27 .humidity span {
  color: #2c304d;
  font-size: 1.2rem;
}
.widget-27 .humidity .title {
  color: #aea9c3;
}
.widget-27 .thermostat-options i {
  color: #aea9c3;
  font-size: 2rem;
}
@media (max-width: 991px) {
  .widget-27 .thermostat .temperature {
    font-size: 3rem;
  }
}
@media (max-width: 576px) {
  .widget-27 .thermostat .temperature {
    font-size: 3rem;
  }
}
.widget-28 .widget-body {
  position: relative;
}
.widget-28 .camera-active .title {
  color: #2c304d;
  font-size: 1.2rem;
  font-weight: 500;
}
.widget-28 .camera-active {
  background-color: #fff;
  width: 80%;
  padding: 1.6rem;
  position: absolute;
  top: -35px;
  border-radius: 4px;
  text-align: center;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.widget-28 .list-group-item {
  display: block;
  margin: 0;
  padding: 0.75rem 0;
  border: 0 none;
  background: transparent;
}
.widget-28 .list-group-item:first-child {
  border-top: 0;
  border-radius: 0 !important;
}
.widget-28 .list-group-item:last-child {
  border-bottom: 0;
  border-radius: 0 !important;
}
.widget-28 .list-group-item i {
  color: #aea9c3;
  padding: 0 0.65rem;
  font-size: 1.8rem;
}
.widget-28 .list-group-item .act-title {
  color: #2c304d;
}
.widget-29 .widget-body {
  padding: 0;
}
.widget-29 .owl-item .item {
  margin: 0 0.5rem;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.widget-29 .devices-item {
  background: linear-gradient(
    to bottom,
    rgba(44, 48, 77, 0) 0%,
    rgba(93, 83, 134, 1) 80%
  );
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
}
.widget-29 .devices-item i {
  color: #fff;
  font-size: 1.8rem;
  padding-right: 0.3rem;
  vertical-align: middle;
  display: inline-block;
}
.widget-29 .devices-item .room {
  color: #fff;
  display: inline-block;
  font-weight: 500;
}
.widget-29 .devices-item .nb-devices {
  color: #aea9c3;
  margin-bottom: 1.07rem;
}
.widget-29 .owl-dots {
  float: left;
  display: block;
  margin-top: 1.8rem;
  text-align: center;
  width: 100%;
}
.widget-29 .owl-dots .owl-dot {
  border: 2px solid #98a8b4;
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  margin: 0 0.3rem;
  width: 12px;
  transition: all 0.3s ease 0s;
}
.widget-29 .owl-dots .owl-dot:hover {
  border-color: #5d5386;
}
.widget-29 .owl-dots .owl-dot.active {
  border: 2px solid #5d5386;
}
.widget-29 .owl-carousel .owl-nav {
  text-align: center !important;
  margin-top: 1.4rem;
}
.widget-29 .owl-carousel .owl-nav .owl-prev,
.widget-29 .owl-carousel .owl-nav .owl-next {
  display: inline;
  margin: 0 8px;
  font-size: 1.4rem;
}
.widget-29 .owl-prev,
.widget-29 .owl-next {
  color: #aea9c3 !important;
  transition: all 0.4s ease;
}
.widget-29 .owl-prev:hover,
.widget-29 .owl-next:hover {
  color: #5d5386 !important;
}
.widget-30 {
  position: relative;
  line-height: 1.2;
}
.widget-30.bg-image {
  background: url(img/smarthome/06.jpg);
  display: block;
  background-position: 50% 0%;
  background-size: cover;
}
.widget-30 .overlay {
  background: linear-gradient(
    135deg,
    rgba(44, 48, 77, 0.5) 0%,
    rgba(93, 83, 134, 0.8) 100%
  );
  content: "";
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.widget-30 .widget-body {
  background: #fff;
  position: relative;
  z-index: 2;
}
.widget-30 .content {
  padding: 3rem 0;
  position: relative;
  z-index: 2;
}
.widget-30 #events-date {
  color: rgba(255, 255, 255, 0.5);
  font-size: 8rem;
  font-weight: 600;
}
.widget-30 #events-day {
  color: #fff;
  font-size: 1.2rem;
}
.widget-30 #events-year {
  color: #fff;
  font-size: 1rem;
}
.widget-30 .real-time {
  padding: 1.6rem 0;
  z-index: 2;
  position: relative;
}
.widget-30 #events-time {
  color: #fff;
  font-size: 1.2rem;
}
.widget-30 .house-members {
  padding: 0.4rem 0;
}
.widget-30 .house-members a {
  margin: 0 -1.6rem 0 0;
  transition: all 0.4s ease;
  position: relative;
}
.widget-30 .house-members img {
  width: 50px;
  border: 0.25rem solid #fff;
}
.widget-30 .house-members a.here {
  z-index: 2;
}
.widget-30 .house-members a.away {
  opacity: 0.4;
}
.widget-31 .conso {
  position: relative;
  z-index: 2;
  line-height: 1.2;
}
.widget-31 .icon-big {
  color: #2c304d;
  font-size: 10rem;
  position: absolute;
  vertical-align: middle;
  bottom: -40px;
  left: -15px;
  opacity: 0.085;
  z-index: 2;
}
.widget-31 {
  width: 100%;
  padding: 0;
  position: relative;
  overflow: hidden;
}
.widget-31 canvas {
  max-width: 100%;
  max-height: 100%;
}
.widget-31 .percent {
  color: #5d5386;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
}
.widget-31 .percent i {
  color: #aea9c3;
  display: block;
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
}
.widget-31 .conso-title .title {
  color: #5d5386;
  font-size: 1.6rem;
  position: relative;
  z-index: 1;
  margin-left: 2rem;
}
@media (max-width: 1200px) {
  .widget-31 .conso-title .title {
    text-align: center;
    margin-left: 0;
    margin-bottom: 1.07rem;
  }
}
@media (max-width: 991px) {
  .widget-31 .conso-title .title {
    text-align: center;
    margin-left: 0;
    margin-bottom: 1.07rem;
  }
}
@media (max-width: 576px) {
  .widget-31 .conso-title .title {
    text-align: center;
    margin-left: 0;
    margin-bottom: 1.07rem;
  }
}
.widget-32 {
  position: relative;
  text-align: center;
  line-height: 1.2;
}
.widget-32.bg-image {
  background: url(img/background/06.jpg);
  display: block;
  background-position: 50% 0%;
  background-size: cover;
}
.widget-32 .overlay {
  background: linear-gradient(
    135deg,
    rgba(44, 48, 77, 0.8) 0%,
    rgba(93, 83, 134, 0.8) 100%
  );
  content: "";
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
.widget-32 .content {
  padding: 4rem 0;
  position: relative;
  z-index: 2;
}
.widget-32 #events-date {
  color: #fff;
  font-size: 8rem;
  font-weight: 600;
}
.widget-32 #events-day {
  color: #e76c90;
  font-size: 1.2rem;
}
.widget-32 #events-year {
  color: #aea9c3;
  font-size: 1rem;
}
.widget-32 .real-time {
  padding: 1.6rem 0;
  z-index: 2;
  position: relative;
}
.widget-32 #events-time {
  color: #fff;
  font-size: 1.2rem;
}
.widget-33 #music {
  width: 100%;
  overflow: hidden;
}
.widget-33 #music img {
  width: 100%;
}
.widget-33 .song-title {
  color: #2c304d;
  text-align: center;
  font-size: 1.07rem;
  padding: 1.07rem 0;
}
.widget-33 #pause {
  display: none;
}
.widget-33 progress {
  border: none;
  width: 100%;
  height: 6px;
  background: #eee;
}
.widget-33 progress {
  color: #e76c90;
}
.widget-33 progress::-webkit-progress-value {
  background: #e76c90;
}
.widget-33 progress::-moz-progress-bar {
  background: #e76c90;
}
.widget-33 progress::-webkit-progress-value {
  background: #e76c90;
}
.widget-33 progress::-webkit-progress-bar {
  background: #e76c90;
}
.widget-33 .actions {
  padding: 15px 20px;
  overflow: hidden;
}
.widget-33 .actions i {
  font-size: 1.8rem;
  color: #aea9c3;
  cursor: pointer;
  vertical-align: middle;
}
.widget-33 .actions i:hover {
  opacity: 0.8;
}
.widget-33 .prev {
  display: inline-block;
}
.widget-33 .play {
  display: inline-block;
}
.widget-33 .next {
  display: inline-block;
}
.widget-33 #mute {
  display: none;
}
.widget-33 #playmusic {
  font-size: 2.8rem;
  color: #e76c90;
}
.widget-33 #pause {
  font-size: 2.8rem;
  color: #5d5386;
}
.widget-34 .r-title {
  font-size: 5rem;
  font-weight: 600;
  color: #2c304d;
  display: inline-block;
  margin-right: 1.07rem;
}
.widget-34 .star-rating {
  display: inline-block;
}
.widget-34 .star-rating ul li {
  font-size: 2rem;
  display: inline-block;
}
.widget-34 .star-rating .rate {
  color: #ffe234;
}
.widget-34 .chart {
  margin-top: -2rem;
}
@media (max-width: 576px) {
  .widget-34 .r-title {
    font-size: 2.8rem;
  }
  .widget-34 .star-rating ul li {
    font-size: 1.4rem;
  }
  .widget-34 .chart {
    margin-top: 0;
  }
}
.widget-35.widget {
  background: linear-gradient(135deg, #3b5998 40%, #1b3c89 100%) !important;
}
.widget-35 .widget-body {
  position: relative;
  overflow: hidden;
}
.widget-35 .user-avatar {
  display: inline-block;
}
.widget-35 .user-avatar img {
  width: 50px;
  height: 50px;
}
.widget-35 .user-n {
  display: inline-block;
  color: #fff;
  margin-left: 1.07rem;
}
.widget-35 .account-name {
  color: #aea9c3;
}
.widget-35 i {
  color: #fff;
  font-size: 3rem;
  margin-right: 0.65rem;
}
.widget-35 .w-text {
  position: absolute;
  bottom: 30px;
}
.widget-35 p {
  width: 85%;
  color: #fff;
  line-height: 1.8;
}
.widget-35 a {
  color: #aea9c3;
}
@media (max-width: 991px) {
  .widget-35 .w-text {
    position: relative;
  }
}
@media (max-width: 576px) {
  .widget-35 .w-text {
    position: relative;
  }
}
.widget-36 .widget-body {
  position: relative;
}
.widget-36 .nb-visitors {
  color: #2c304d;
  font-size: 1.2rem;
}
.widget-36 .visitors-nb {
  color: #2c304d;
  font-size: 6rem;
  font-weight: 700;
  margin-top: -0.8rem;
}
.widget-38 .leaflet-marker-icon {
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.15);
}
.widget-38 .leaflet-popup-content-wrapper {
  border-radius: 4px;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.15);
}
.widget-39.widget {
  position: relative;
  overflow: hidden;
}
.widget-39 .widget-effect i {
  color: rgba(255, 255, 255, 0.035);
  font-size: 20rem;
  z-index: 1;
  position: absolute;
  bottom: -5%;
  right: -10%;
}
.widget-39 .widget-body {
  z-index: 2;
}
.widget-39 .widget-body i {
  color: #fff;
  font-size: 6rem;
}
.widget-39 .congrats-name {
  color: #fff;
  font-size: 2.2rem;
  font-weight: 700;
}
.widget-39 p {
  color: #fff;
  font-size: 1.1rem;
}
@media (max-width: 576px) {
  .widget-39 .widget-effect i {
    font-size: 10rem;
  }
  .widget-39 .widget-body i {
    font-size: 4rem;
  }
  .widget-39 .congrats-name {
    font-size: 1.6rem;
  }
}
footer.main-footer {
  background: #fff;
  padding: 15px 30px;
  border-top: 0.07rem solid #eee;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.08);
}
footer.main-footer.fixed-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  margin-left: 240px;
}
.content-inner.active footer.main-footer.fixed-footer {
  margin-left: 90px;
}
footer.main-footer a {
  color: inherit;
}
footer.main-footer a:hover {
  color: #5d5386;
}
footer.main-footer p {
  color: #98a8b4;
  margin: 0;
  font-size: 0.85rem;
  font-weight: 500;
}
footer.main-footer .nav-link {
  padding: 0 1rem;
  font-size: 0.85rem;
}
footer.main-footer a.nav-link {
  color: #98a8b4;
}
footer.main-footer a:hover.nav-link {
  color: #5d5386;
}
footer.second-footer {
  padding: 15px 30px;
}
footer.second-footer a {
  color: inherit;
}
footer.second-footer a:hover {
  color: #5d5386;
}
footer.second-footer p {
  color: #2c304d;
  margin: 0;
  font-size: 0.85rem;
  font-weight: 500;
}
footer.second-footer .nav-link {
  padding: 0 1rem;
  font-size: 0.85rem;
}
footer.second-footer a.nav-link {
  color: #5d5386;
}
footer.second-footer a:hover.nav-link {
  color: #e76c90;
}
.mail .bg-mail {
  background: #f2f3f8;
}
.mail .nb-new {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.mail .messages-list img {
  width: 40px;
}
.mail .messages-list .user-img {
  position: relative;
  display: inline-block;
}
.mail .input-group input {
  border: 0 none;
  border-bottom: 1px solid #eee;
  padding-left: 15px !important;
}
.mail .input-group input::placeholder {
  color: #98a8b4;
}
.mail .input-group-addon {
  border: 0 none !important;
}
.mail .input-group-addon {
  background: none;
}
.mail .input-group-addon .btn {
  background: #fff;
  color: #e76c90;
  padding: 18px 15px;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #eee;
}
.mail .sidebar-content #list-group {
  flex: 1 1;
}
.mail #sidebar {
  background: #f2f3f8;
  border: 0 none;
}
.mail .sidebar-content {
  display: flex;
  flex-direction: column;
}
.mail .messages-list .list-group-item {
  display: block;
  margin: 0;
  padding: 1.8rem 1.25rem;
  border: 0 none;
  border-bottom: 0.07rem solid rgba(255, 255, 255, 0.05);
  background: transparent;
  transition: all 0.5s ease;
}
.mail .messages-list .list-group-item:hover .media h4 {
  color: #e76c90;
}
.mail .messages-list .list-group-item:first-child {
  border-top: 0;
  border-radius: 0 !important;
}
.mail .messages-list .list-group-item:last-child {
  border-bottom: 0;
  border-radius: 0 !important;
}
.mail .media h4 {
  font-size: 1.2rem;
  color: #5d5386;
}
.mail .media h5 {
  color: #5d5386;
  font-size: 1.1rem;
}
.mail .media .media-body {
  padding: 0 0.8rem;
}
.mail .media .badge-rounded {
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
}
.mail .media p {
  font-size: 0.95rem;
  color: #aea9c3;
  margin: 0;
}
.mail .media .date-send {
  color: #aea9c3;
  font-size: 0.85rem;
}
.mail .media .username i {
  font-size: 1.2rem;
  vertical-align: middle;
  margin-left: 0.3rem;
}
.mail .card {
  margin: 1.4rem;
  border: 0 none;
  border-radius: 4px 4px 0 0;
}
.mail .card .card-header {
  background: #fff;
  color: #2c304d;
  padding: 1.2rem 1.8rem 0.5rem 1.8rem;
  border: 0 none;
}
.mail .card-header .message-avatar {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5rem;
}
.mail .card-header .message-infos {
  display: inline-block;
  vertical-align: middle;
}
.mail .card-header .user-title {
  font-size: 1.2rem;
}
.mail .message-card {
  margin: 0.25rem;
  max-width: 50%;
}
.mail .card-body {
  padding: 1.2rem 1.8rem;
}
.mail .show-details a {
  color: #aea9c3;
  font-size: 0.85rem;
}
.mail #details li {
  color: #94a4b0;
  font-size: 0.9rem;
}
.mail .mail-title {
  padding-bottom: 1.07rem;
}
.mail .mail-title h2 {
  color: #5d5386;
}
.mail .attachments .title {
  padding: 1.07rem 0;
}
.mail .attachments .title i {
  font-size: 1.4rem;
}
.mail .publisher {
  border-top: 0.07rem solid #eee;
  position: relative;
  padding: 1rem 1.8rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.mail .publisher > * {
  margin: 0 8px;
}
.mail .publisher > *:first-child {
  margin-left: 0;
}
.mail .publisher > *:last-child {
  margin-right: 0;
}
.mail .publisher-multi {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
}
.mail .publisher-multi > * {
  margin: 0;
  width: 100%;
  -webkit-box-flex: 1;
  flex-grow: 1;
}
.mail .publisher-multi .publisher-input {
  padding: 1rem;
  border-radius: 4px;
  color: #2c304d;
}
.mail .publisher-input {
  border: 0 none;
  outline: none !important;
  background-color: rgba(52, 40, 104, 0.05);
  -webkit-box-flex: 1;
  flex-grow: 1;
}
.mail .publisher-bottom {
  padding: 1rem 0%;
}
.mail .publisher-btn {
  background-color: transparent;
  border: 0 none;
  color: #98a8b4;
  font-size: 1.8rem;
  margin-right: 0.5rem;
}
.mail .publisher-btn:hover {
  color: #5d5386;
}
.mail .attachments {
  color: #2c304d;
}
.mail .file-attachment .item {
  padding-bottom: 1rem;
}
.mail .file-attachment i {
  color: #e76c90;
  margin-right: 0.5rem;
  font-size: 1.4rem;
  vertical-align: middle;
}
@media (max-width: 1200px) {
  .mail .message-card {
    max-width: 100%;
  }
  .mail .card .input-group {
    position: relative;
  }
}
@media (max-width: 991px) {
  .mail .message-card {
    max-width: 100%;
  }
  .mail .card .input-group {
    position: relative;
  }
}
@media (max-width: 576px) {
  .mail .message-card {
    max-width: 100%;
  }
  .mail .card .input-group {
    position: relative;
  }
}
.fc-bg-default,
.fc-bg-violet,
.fc-bg-orange,
.fc-bg-green,
.fc-bg-green {
  background-color: #fff !important;
  position: relative;
}
.fc-bg-default .fc-title i,
.fc-bg-violet .fc-title i,
.fc-bg-orange .fc-title i,
.fc-bg-blue .fc-title i,
.fc-bg-green .fc-title i {
  font-size: 1.8rem;
  margin-right: 0.2rem;
  vertical-align: -5px;
}
.fc-bg-default .fc-title,
.fc-bg-default .fc-time {
  color: #2c304d !important;
}
.fc-bg-violet .fc-title,
.fc-bg-violet .fc-time {
  color: #5d5386 !important;
}
.fc-bg-orange .fc-title,
.fc-bg-orange .fc-time {
  color: #e76c90 !important;
}
.fc-bg-blue .fc-title,
.fc-bg-blue .fc-time {
  color: #08a6c3 !important;
}
.fc-bg-green .fc-title,
.fc-bg-green .fc-time {
  color: #60c400 !important;
}
.fc-event .fc-title {
  font-weight: 600;
}
.fc-bg-default,
.fc-bg-violet,
.fc-bg-orange,
.fc-bg-blue,
.fc-bg-green {
  background-color: #fff !important;
  position: relative;
}
.fc-bg-default,
.fc-bg-violet,
.fc-bg-orange,
.fc-bg-blue,
.fc-bg-green {
  border-left: 0.25rem solid !important;
}
.fc-bg-default {
  border-left-color: #2c304d !important;
}
.fc-bg-violet {
  border-left-color: #5d5386 !important;
}
.fc-bg-orange {
  border-left-color: #e76c90 !important;
}
.fc-bg-blue {
  border-left-color: #08a6c3 !important;
}
.fc-bg-green {
  border-left-color: #60c400 !important;
}
.fc-bg-default .fc-list-item-title i,
.fc-bg-violet .fc-list-item-title i,
.fc-bg-orange .fc-list-item-title i,
.fc-bg-blue .fc-list-item-title i,
.fc-bg-green .fc-list-item-title i {
  font-size: 1.6rem;
  margin-right: 0.2rem;
  vertical-align: -3px;
}
.fc-bg-default .fc-list-item-title,
.fc-bg-default .fc-time {
  color: #2c304d !important;
}
.fc-bg-violet .fc-list-item-title,
.fc-bg-violet .fc-time {
  color: #5d5386 !important;
}
.fc-bg-orange .fc-list-item-title,
.fc-bg-orange .fc-time {
  color: #e76c90 !important;
}
.fc-bg-blue .fc-list-item-title,
.fc-bg-blue .fc-time {
  color: #08a6c3 !important;
}
.fc-bg-green .fc-list-item-title,
.fc-bg-green .fc-time {
  color: #60c400 !important;
}
.fc-list-item-title {
  font-weight: 600;
}
#external-events {
  z-index: 2;
}
#external-events .fc-event {
  margin: 1em 0;
  cursor: grab;
  overflow: hidden;
}
#external-events .fc-content {
  padding-left: 1.4rem 0.8rem;
}
#external-events .remove-drop span {
  color: #2c304d;
  font-size: 0.85rem;
  margin-left: 0.4rem;
}
#calendar-container {
  position: relative;
  z-index: 1;
}
#modal-view-event .event-icon i {
  color: rgba(52, 40, 104, 0.8);
  font-size: 3rem;
}
.fc {
  direction: ltr;
  text-align: left;
}
.fc-rtl {
  text-align: right;
}
body .fc {
  font-size: 1rem;
}
.fc-highlight {
  background: #bce8f1;
  opacity: 0.3;
}
.fc-bgevent {
  background: #8fdf82;
  opacity: 0.3;
}
.fc-nonbusiness {
  background: rgba(52, 40, 104, 0.05);
}
.fc button {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  height: 2.75rem;
  padding: 0 1.25rem;
  font-size: 1rem;
  font-weight: 500 !important;
  white-space: nowrap;
  cursor: pointer;
}
.fc button::-moz-focus-inner {
  margin: 0;
  padding: 0;
}
.fc-state-default {
  border: 0.07rem solid;
}
.fc-state-default.fc-corner-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.fc-state-default.fc-corner-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.fc button .fc-icon {
  position: relative;
  top: -0.05rem;
  margin: 0 0.2rem;
}
.fc-state-default {
  background-color: #f1f3f7;
  border-color: #f1f3f7;
  color: #2c304d;
}
.fc button:focus {
  color: #fff;
  background-color: rgba(52, 40, 104, 0.8);
  border-color: rgba(52, 40, 104, 0.8);
}
.fc-state-hover,
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  color: #2c304d;
  background-color: #e4e8f0;
  box-shadow: none;
}
.fc-state-hover {
  color: rgba(52, 40, 104, 0.8);
  text-decoration: none;
  box-shadow: none;
}
.fc-state-down,
.fc-state-active {
  background-color: rgba(52, 40, 104, 0.8);
  border-color: rgba(52, 40, 104, 0.8);
  background-image: none;
  color: #fff;
  box-shadow: none;
}
.fc-state-disabled {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  box-shadow: none;
}
.fc-button-group {
  display: inline-block;
}
.fc .fc-button-group > * {
  float: left;
  margin: 0 0 0 -1px;
}
.fc .fc-button-group > :first-child {
  margin-left: 0;
}
.fc-popover {
  position: absolute;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}
.fc-popover .fc-header {
  padding: 2px 4px;
}
.fc-popover .fc-header .fc-title {
  margin: 0 2px;
}
.fc-popover .fc-header .fc-close {
  cursor: pointer;
}
.fc-ltr .fc-popover .fc-header .fc-title,
.fc-rtl .fc-popover .fc-header .fc-close {
  float: left;
}
.fc-rtl .fc-popover .fc-header .fc-title,
.fc-ltr .fc-popover .fc-header .fc-close {
  float: right;
}
.fc-divider {
  border-style: solid;
  border-width: 1px;
}
hr.fc-divider {
  height: 0;
  margin: 0;
  padding: 0 0 2px;
  border-width: 1px 0;
}
.fc-clear {
  clear: both;
}
.fc-bg,
.fc-bgevent-skeleton,
.fc-highlight-skeleton,
.fc-helper-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc-bg {
  bottom: 0;
}
.fc-bg table {
  height: 100%;
}
.fc table {
  width: 100%;
  box-sizing: border-box;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1rem;
}
.fc-unthemed th.fc-day-header {
  color: #2c304d;
  padding: 0.75rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
}
.fc th {
  text-align: center;
}
.fc th,
.fc td {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top;
}
.fc td.fc-today {
  border-style: double;
}
a[data-goto] {
  cursor: pointer;
}
a[data-goto]:hover {
  text-decoration: underline;
}
.fc .fc-row {
  border-style: solid;
  border-width: 0;
}
.fc-row table {
  border-left: 0 hidden transparent;
  border-right: 0 hidden transparent;
  border-bottom: 0 hidden transparent;
}
.fc-row:first-child table {
  border-top: 0 hidden transparent;
}
.fc-row {
  position: relative;
}
.fc-row .fc-bg {
  z-index: 1;
}
.fc-row .fc-bgevent-skeleton,
.fc-row .fc-highlight-skeleton {
  bottom: 0;
}
.fc-row .fc-bgevent-skeleton table,
.fc-row .fc-highlight-skeleton table {
  height: 100%;
}
.fc-row .fc-highlight-skeleton td,
.fc-row .fc-bgevent-skeleton td {
  border-color: transparent;
}
.fc-row .fc-bgevent-skeleton {
  z-index: 2;
}
.fc-row .fc-highlight-skeleton {
  z-index: 3;
}
.fc-row .fc-content-skeleton {
  position: relative;
  z-index: 4;
  padding-bottom: 2px;
}
.fc-row .fc-helper-skeleton {
  z-index: 5;
}
.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-helper-skeleton td {
  background: none;
  border-color: transparent;
  padding: 0.5rem 0.5rem;
}
.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  border-bottom: 0;
}
.fc-row .fc-content-skeleton tbody td,
.fc-row .fc-helper-skeleton tbody td {
  border-top: 0;
}
.fc-scroller {
  -webkit-overflow-scrolling: touch;
}
.fc-scroller > .fc-day-grid,
.fc-scroller > .fc-time-grid {
  position: relative;
  width: 100%;
}
.fc-event {
  position: relative;
  display: block;
  font-size: 0.85rem;
  line-height: 1.3;
  border-radius: 3px;
  border: 1px solid #eee;
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.05);
}
.fc-event {
  background-color: #fff;
}
.fc-event-dot {
  background-color: rgba(52, 40, 104, 0.3);
  border-radius: 4px;
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.1);
}
.fc-event,
.fc-event:hover {
  color: #fff;
  text-decoration: none;
}
.fc-event[href],
.fc-event.fc-draggable {
  cursor: pointer;
}
.fc-not-allowed,
.fc-not-allowed .fc-event {
  cursor: not-allowed;
}
.fc-event .fc-bg {
  z-index: 1;
  background: #fff;
  opacity: 0.25;
}
.fc-event .fc-content {
  color: #2c304d;
  position: relative;
  z-index: 2;
  padding: 0.5rem;
}
.fc-event .fc-resizer {
  position: absolute;
  z-index: 4;
}
.fc-event .fc-resizer {
  display: none;
}
.fc-event.fc-allow-mouse-resize .fc-resizer,
.fc-event.fc-selected .fc-resizer {
  display: block;
}
.fc-event.fc-selected .fc-resizer:before {
  content: "";
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
}
.fc-event.fc-selected {
  z-index: 9999 !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.fc-event.fc-selected.fc-dragging {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
}
.fc-h-event.fc-selected:before {
  content: "";
  position: absolute;
  z-index: 3;
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0;
}
.fc-ltr .fc-h-event.fc-not-start,
.fc-rtl .fc-h-event.fc-not-end {
  margin-left: 0;
  border-left-width: 0;
  padding-left: 1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.fc-ltr .fc-h-event.fc-not-end,
.fc-rtl .fc-h-event.fc-not-start {
  margin-right: 0;
  border-right-width: 0;
  padding-right: 1px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fc-ltr .fc-h-event .fc-start-resizer,
.fc-rtl .fc-h-event .fc-end-resizer {
  cursor: w-resize;
  left: -1px;
}
.fc-ltr .fc-h-event .fc-end-resizer,
.fc-rtl .fc-h-event .fc-start-resizer {
  cursor: e-resize;
  right: -1px;
}
.fc-h-event.fc-allow-mouse-resize .fc-resizer {
  width: 7px;
  top: -1px;
  bottom: -1px;
}
.fc-h-event.fc-selected .fc-resizer {
  border-radius: 4px;
  border-width: 1px;
  width: 6px;
  height: 6px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  top: 50%;
  margin-top: -4px;
}
.fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
  margin-left: -4px;
}
.fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
.fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
  margin-right: -4px;
}
.fc-day-grid-event {
  margin: 1px 2px 0;
  padding: 0 1px;
}
tr:first-child > td > .fc-day-grid-event {
  margin-top: 2px;
}
.fc-day-grid-event.fc-selected:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background: #000;
  opacity: 0.25;
}
.fc-day-grid-event .fc-content {
  white-space: nowrap;
  overflow: hidden;
}
.fc-day-grid-event .fc-time {
  font-weight: 700;
}
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
  margin-left: -2px;
}
.fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
.fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
  margin-right: -2px;
}
a.fc-more {
  margin: 1px 3px;
  font-size: 0.85rem;
  cursor: pointer;
  text-decoration: none;
}
a.fc-more:hover {
  text-decoration: underline;
}
.fc-limited {
  display: none;
}
.fc-day-grid .fc-row {
  z-index: 1;
}
.fc-more-popover {
  z-index: 2;
  width: 220px;
}
.fc-more-popover .fc-event-container {
  padding: 10px;
}
.fc-now-indicator {
  position: absolute;
  border: 0 solid red;
}
.fc-unselectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-content,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-list-view,
.fc-unthemed .fc-list-heading td {
  border-color: #eee;
}
.fc-unthemed .fc-popover {
  background-color: #fff;
}
.fc-unthemed .fc-divider {
  border: 0.07rem solid #eee;
}
.fc-unthemed .fc-popover .fc-header,
.fc-unthemed .fc-list-heading td {
  background: rgba(52, 40, 104, 0.8);
  color: #fff;
  border: 0 none;
}
.fc-unthemed .fc-popover .fc-header .fc-close {
  color: #666;
}
.fc-unthemed td.fc-today {
  background: rgba(52, 40, 104, 0.03);
}
.fc-unthemed .fc-disabled-day {
  background: #d7d7d7;
  opacity: 0.3;
}
.fc-icon {
  display: inline-block;
  height: 1rem;
  line-height: 1rem;
  font-size: 1rem;
  text-align: center;
  overflow: hidden;
  font-family: "Ionicons", Courier, monospace;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.fc-icon:after {
  position: relative;
}
.fc-icon-left-single-arrow:after {
  content: "\f124";
  font-weight: 400;
  font-size: normal;
  top: -7%;
}
.fc-icon-right-single-arrow:after {
  content: "\f125";
  font-weight: 400;
  font-size: normal;
  top: -7%;
}
.fc-icon-left-double-arrow:after {
  content: "\AB";
  font-size: 160%;
  top: -7%;
}
.fc-icon-right-double-arrow:after {
  content: "\BB";
  font-size: 160%;
  top: -7%;
}
.fc-icon-left-triangle:after {
  content: "\25C4";
  font-size: 125%;
  top: 3%;
}
.fc-icon-right-triangle:after {
  content: "\25BA";
  font-size: 125%;
  top: 3%;
}
.fc-icon-down-triangle:after {
  content: "\25BC";
  font-size: 125%;
  top: 2%;
}
.fc-icon-x:after {
  content: "\D7";
  font-size: 200%;
  top: 6%;
}
.fc-unthemed .fc-popover {
  border-width: 1px;
  border-style: solid;
}
.fc-unthemed .fc-popover .fc-header .fc-close {
  font-size: 0.9rem;
  margin-top: 2px;
}
.fc-unthemed .fc-list-item:hover td {
  background-color: #f5f5f5;
}
.ui-widget .fc-disabled-day {
  background-image: none;
}
.fc-popover > .ui-widget-header + .ui-widget-content {
  border-top: 0;
}
.ui-widget .fc-event {
  color: #fff;
  text-decoration: none;
  font-weight: 400;
}
.ui-widget td.fc-axis {
  font-weight: 400;
}
.fc-time-grid .fc-slats .ui-widget-content {
  background: none;
}
.fc.fc-bootstrap3 a {
  text-decoration: none;
}
.fc.fc-bootstrap3 a[data-goto]:hover {
  text-decoration: underline;
}
.fc-bootstrap3 hr.fc-divider {
  border-color: inherit;
}
.fc-bootstrap3 .fc-today.alert {
  border-radius: 0;
}
.fc-bootstrap3 .fc-popover .panel-body {
  padding: 0;
}
.fc-bootstrap3 .fc-time-grid .fc-slats table {
  background: none;
}
.fc.fc-bootstrap4 a {
  text-decoration: none;
}
.fc.fc-bootstrap4 a[data-goto]:hover {
  text-decoration: underline;
}
.fc-bootstrap4 hr.fc-divider {
  border-color: inherit;
}
.fc-bootstrap4 .fc-today.alert {
  border-radius: 0;
}
.fc-bootstrap4 a.fc-event:not([href]):not([tabindex]) {
  color: #fff;
}
.fc-bootstrap4 .fc-popover.card {
  position: absolute;
}
.fc-bootstrap4 .fc-popover .card-body {
  padding: 0;
}
.fc-bootstrap4 .fc-time-grid .fc-slats table {
  background: none;
}
.fc-toolbar {
  text-align: center;
}
.fc-toolbar.fc-header-toolbar {
  margin: 1rem 0 2rem 0;
}
.fc-toolbar.fc-footer-toolbar {
  margin-top: 1rem;
}
.fc-toolbar .fc-left {
  float: left;
}
.fc-toolbar .fc-right {
  float: right;
}
.fc-toolbar .fc-center {
  display: inline-block;
}
.fc .fc-toolbar > * > * {
  float: left;
  margin-left: 0.75rem;
}
.fc .fc-toolbar > * > :first-child {
  margin-left: 0;
}
.fc-toolbar h2 {
  color: #2c304d;
  text-transform: uppercase;
  font-size: 1.4rem;
  margin-top: 0.7rem;
}
.fc-toolbar button {
  position: relative;
}
.fc-toolbar .fc-state-hover,
.fc-toolbar .ui-state-hover {
  z-index: 2;
}
.fc-toolbar .fc-state-down {
  z-index: 3;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active {
  z-index: 4;
}
.fc-toolbar button:focus {
  z-index: 5;
}
.fc-view-container *,
.fc-view-container *:before,
.fc-view-container *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.fc-view,
.fc-view > table {
  position: relative;
  z-index: 1;
}
.fc-basicWeek-view .fc-content-skeleton,
.fc-basicDay-view .fc-content-skeleton {
  padding-bottom: 1rem;
}
.fc-basic-view .fc-body .fc-row {
  min-height: 4rem;
}
.fc-row.fc-rigid {
  overflow: hidden;
}
.fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.fc-day-top.fc-other-month {
  opacity: 0.3;
}
.fc-basic-view .fc-week-number,
.fc-basic-view .fc-day-number {
  padding: 2px;
}
.fc-basic-view th.fc-week-number,
.fc-basic-view th.fc-day-number {
  padding: 0 2px;
}
.fc-ltr .fc-basic-view .fc-day-top .fc-day-number {
  float: right;
  color: rgba(52, 40, 104, 0.8);
}
.fc-rtl .fc-basic-view .fc-day-top .fc-day-number {
  float: left;
}
.fc-ltr .fc-basic-view .fc-day-top .fc-week-number {
  float: left;
  border-radius: 0 0 3px 0;
}
.fc-rtl .fc-basic-view .fc-day-top .fc-week-number {
  float: right;
  border-radius: 0 0 0 3px;
}
.fc-basic-view .fc-day-top .fc-week-number {
  min-width: 1.5rem;
  text-align: center;
  background-color: #f2f2f2;
  color: gray;
}
.fc-basic-view td.fc-week-number {
  text-align: center;
}
.fc-basic-view td.fc-week-number > * {
  display: inline-block;
  min-width: 1.25rem;
}
.fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2;
}
.fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3rem;
}
.fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-bottom: 1rem;
}
.fc .fc-axis {
  vertical-align: middle;
  padding: 0 4px;
  white-space: nowrap;
}
.fc-ltr .fc-axis {
  text-align: right;
}
.fc-rtl .fc-axis {
  text-align: left;
}
.fc-time-grid-container,
.fc-time-grid {
  position: relative;
  z-index: 1;
}
.fc-time-grid {
  min-height: 100%;
}
.fc-time-grid table {
  border: 0 hidden transparent;
}
.fc-time-grid > .fc-bg {
  z-index: 1;
}
.fc-time-grid .fc-slats,
.fc-time-grid > hr {
  position: relative;
  z-index: 2;
}
.fc-time-grid .fc-content-col {
  position: relative;
}
.fc-time-grid .fc-content-skeleton {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
}
.fc-time-grid .fc-business-container {
  position: relative;
  z-index: 1;
}
.fc-time-grid .fc-bgevent-container {
  position: relative;
  z-index: 2;
}
.fc-time-grid .fc-highlight-container {
  position: relative;
  z-index: 3;
}
.fc-time-grid .fc-event-container {
  position: relative;
  z-index: 4;
}
.fc-time-grid .fc-now-indicator-line {
  z-index: 5;
}
.fc-time-grid .fc-helper-container {
  position: relative;
  z-index: 6;
}
.fc-time-grid .fc-slats td {
  height: 1.5rem;
  border-bottom: 0;
}
.fc-time-grid .fc-slats .fc-minor td {
  border-top-style: dotted;
}
.fc-time-grid .fc-highlight-container {
  position: relative;
}
.fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0;
}
.fc-ltr .fc-time-grid .fc-event-container {
  margin: 0 2.5% 0 2px;
}
.fc-rtl .fc-time-grid .fc-event-container {
  margin: 0 2px 0 2.5%;
}
.fc-time-grid .fc-event,
.fc-time-grid .fc-bgevent {
  position: absolute;
  z-index: 1;
}
.fc-time-grid .fc-bgevent {
  left: 0;
  right: 0;
}
.fc-v-event.fc-not-start {
  border-top-width: 0;
  padding-top: 1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.fc-v-event.fc-not-end {
  border-bottom-width: 0;
  padding-bottom: 1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.fc-time-grid-event {
  overflow: hidden;
}
.fc-time-grid-event.fc-selected {
  overflow: visible;
}
.fc-time-grid-event.fc-selected .fc-bg {
  display: none;
}
.fc-time-grid-event .fc-content {
  overflow: hidden;
}
.fc-time-grid-event .fc-time,
.fc-time-grid-event .fc-title {
  padding: 0 1px;
}
.fc-time-grid-event .fc-time {
  font-size: 0.85rem;
  white-space: nowrap;
}
.fc-time-grid-event.fc-short .fc-content {
  white-space: nowrap;
}
.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
  display: inline-block;
  vertical-align: top;
}
.fc-time-grid-event.fc-short .fc-time span {
  display: none;
}
.fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start);
}
.fc-time-grid-event.fc-short .fc-time:after {
  content: "\A0-\A0";
}
.fc-time-grid-event.fc-short .fc-title {
  font-size: 0.85rem;
  padding: 0;
}
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  overflow: hidden;
  line-height: 8px;
  font-size: 11px;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
}
.fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
  content: "=";
}
.fc-time-grid-event.fc-selected .fc-resizer {
  border-radius: 5px;
  border-width: 1px;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  left: 50%;
  margin-left: -5px;
  bottom: -5px;
}
.fc-time-grid .fc-now-indicator-line {
  border-top-width: 1px;
  left: 0;
  right: 0;
}
.fc-time-grid .fc-now-indicator-arrow {
  margin-top: -5px;
}
.fc-ltr .fc-time-grid .fc-now-indicator-arrow {
  left: 0;
  border-width: 5px 0 5px 6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.fc-rtl .fc-time-grid .fc-now-indicator-arrow {
  right: 0;
  border-width: 5px 6px 5px 0;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.fc-event-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.fc-rtl .fc-list-view {
  direction: rtl;
}
.fc-list-view {
  border-width: 1px;
  border-style: solid;
}
.fc .fc-list-table {
  table-layout: auto;
}
.fc-list-table td {
  border-width: 1px 0 0;
  padding: 8px 14px;
}
.fc-list-table tr:first-child td {
  border-top-width: 0;
}
.fc-list-heading {
  border-bottom-width: 1px;
}
.fc-list-heading td {
  font-weight: 700;
}
.fc-ltr .fc-list-heading-main {
  float: left;
}
.fc-ltr .fc-list-heading-alt {
  float: right;
}
.fc-rtl .fc-list-heading-main {
  float: right;
}
.fc-rtl .fc-list-heading-alt {
  float: left;
}
.fc-list-item.fc-has-url {
  cursor: pointer;
}
.fc-list-item-marker,
.fc-list-item-time {
  white-space: nowrap;
  width: 1px;
}
.fc-ltr .fc-list-item-marker {
  padding-right: 0;
}
.fc-rtl .fc-list-item-marker {
  padding-left: 0;
}
.fc-list-item-title a {
  text-decoration: none;
  color: inherit;
}
.fc-list-item-title a[href]:hover {
  text-decoration: underline;
}
.fc-list-empty-wrap2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.fc-list-empty-wrap1 {
  width: 100%;
  height: 100%;
  display: table;
}
.fc-list-empty {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.fc-unthemed .fc-list-empty {
  background-color: #eee;
}
.contact-card {
  margin-top: 0;
  position: relative;
}
.contact-card .cover-image {
  display: block;
  position: relative;
  padding-top: 2rem;
  width: 120px;
}
.contact-card .cover-image > img {
  display: block;
  margin-bottom: 0;
  width: 120px;
  transition: all 0.25s ease-in-out;
}
.contact-card .badge-pulse,
.contact-card .badge-pulse-green {
  position: absolute;
  left: 20px;
  top: 25px;
  width: 18px;
  height: 18px;
  border: 4px solid #fff;
}
.contact-card .widget-body {
  position: relative;
}
.contact-card .name {
  color: #5d5386;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
  text-decoration: none;
  text-align: center;
}
.contact-card .job {
  color: rgba(52, 40, 104, 0.5);
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}
.contact-card .stats {
  margin-top: 1.4rem;
}
.contact-card .stats .counter {
  display: block;
  color: #2c304d;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
}
.contact-card .stats .text {
  display: block;
  font-weight: 600;
  text-align: center;
}
.contact-card .btn-group {
  padding: 2rem 0 1.4rem;
}
.contact-card .quick-about {
  text-align: center;
  margin-top: 1.4rem;
}
.contact-card .quick-about h4 {
  color: #2c304d;
  font-weight: 600;
  margin-bottom: 0.4rem;
}
.contact-card .quick-about p {
  font-size: 0.9rem;
  margin: 0;
}
.contact-card .owl-dots {
  float: left;
  margin-top: 1.4rem;
  text-align: center;
  width: 100%;
}
.contact-card .owl-dots .owl-dot {
  border: 2px solid #98a8b4;
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  margin: 0 0.3rem;
  width: 12px;
  transition: all 0.3s ease 0s;
}
.contact-card .owl-dots .owl-dot:hover {
  border-color: #e76c90;
}
.contact-card .owl-dots .owl-dot.active {
  border: 2px solid #e76c90;
}
.chat .friend-list img {
  width: 50px;
}
.chat .friend-list .user-img {
  position: relative;
}
.chat .friend-list .badge-pulse,
.chat .friend-list .badge-pulse-green,
.chat .friend-list .badge-pulse-orange {
  position: absolute;
  left: 2px;
  bottom: 2px;
  width: 12px;
  height: 12px;
}
.chat .search-input-h {
  height: 10% !important;
}
.chat #search-group {
  background: #252946;
}
.chat #search-group input {
  background: #1e223f;
  color: #98a8b4;
  font-weight: 500;
  padding: 0.85rem 1rem;
  margin: 0.8rem;
  border-radius: 50px;
}
.chat #search-group input::placeholder {
  color: #98a8b4;
  font-weight: 500;
}
.chat #list-group {
  flex: 1 1;
}
.chat #sidebar {
  background: #252946;
}
.chat .sidebar-content {
  display: flex;
  flex-direction: column;
}
.chat .list-group .heading {
  color: #aea9c3;
  padding: 0.7rem 1.25rem;
}
.chat .friend-list .list-group-item {
  display: block;
  margin: 0;
  padding: 0.95rem 1.25rem;
  border: 0 none;
  background: transparent;
  transition: all 0.5s ease;
}
.chat .friend-list .list-group-item:hover {
  box-shadow: inset 2px 0 0 0 #e76c90;
}
.chat .friend-list .list-group-item:first-child {
  border-top: 0;
  border-radius: 0 !important;
}
.chat .friend-list .list-group-item:last-child {
  border-bottom: 0;
  border-radius: 0 !important;
}
.chat .friend-list .media h4 {
  font-size: 1.1rem;
  color: #e76c90;
  margin: 0;
}
.chat .friend-list .contacts .media h4 {
  color: #aea9c3;
}
.chat .friend-list .media-body {
  padding: 0 0.8rem;
}
.chat .friend-list .media p {
  font-size: 0.85rem;
  color: #aea9c3;
  margin: 0;
}
.chat .friend-list .media .date-send {
  color: #aea9c3;
  font-size: 0.85rem;
}
.chat .card .card-header {
  background: transparent;
  padding: 1.6rem 1.25rem;
  border-radius: 0;
  border: 0 none;
}
.chat .discussion-name {
  color: #5d5386;
  font-size: 1.4rem;
}
.chat .card {
  border: 0 none;
  border-right: 1px solid #eee;
}
.chat .message-card {
  margin-bottom: 1.07rem;
  max-width: 50%;
}
.chat .card-body {
  padding: 1.2rem 1rem;
}
.chat .avatar-bubble {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-left: 15px;
}
.chat .sender-background {
  position: relative;
  background: rgba(93, 83, 134, 0.1);
  color: #2c304d;
  border-radius: 4px;
}
.chat .card-body .sender-background:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  left: -16px;
  border-right-color: rgba(93, 83, 134, 0.15);
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}
.chat .sender-time {
  color: #2c304d;
}
.chat .receiver-background {
  position: relative;
  background: #5d5386;
  color: #fff;
  border-radius: 4px;
}
.chat .card-body .receiver-background:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  right: -16px;
  border-left-color: #5d5386;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}
.chat .receiver-time {
  color: #5d5386;
  text-align: right;
}
.chat .card .input-group {
  position: absolute;
  bottom: 0;
}
.chat .card .input-group input {
  border: 0 none;
  border-top: 1px solid #eee;
}
.chat .card .input-group input::placeholder {
  color: #2a2c3d;
  font-weight: 500;
}
.chat .card .input-group-addon {
  border: 0 !important;
  border-top: 1px solid #eee !important;
}
.chat .card .input-group-btn .btn {
  background: #fff;
  padding: 18px 20px;
  border-radius: 0;
  border-top: 1px solid #eee !important;
  border: 0;
}
.chat .no-messages {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
}
.chat .no-messages i {
  color: rgba(52, 40, 104, 0.5);
  font-size: 8rem;
}
.chat .no-messages .text {
  font-size: 1.6rem;
}
.chat .chat-infos .message-avatar {
  position: relative;
}
.chat .chat-infos .message-avatar .overlay {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.75) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.chat .chat-infos .message-avatar img {
  display: block;
}
.chat .chat-infos .message-infos {
  display: block;
}
.chat .chat-infos .user-title {
  font-size: 1.4rem;
  color: #5d5386;
}
.chat .chat-infos .last-seen {
  color: #94a4b0;
}
@media (max-width: 1200px) {
  .chat .message-card {
    max-width: 100%;
  }
  .chat .card .input-group {
    position: relative;
  }
}
@media (max-width: 991px) {
  .chat .message-card {
    max-width: 100%;
  }
  .chat .card .input-group {
    position: relative;
  }
}
@media (max-width: 576px) {
  .chat .message-card {
    max-width: 100%;
  }
  .chat .card .input-group {
    position: relative;
  }
  .chat .discussion-name {
    margin-bottom: 1.07rem;
  }
}
.authentication-col-content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  padding: 0 50px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.authentication-col-content h1 {
  margin-top: 30px;
  margin-bottom: 0;
  color: #fff;
  font-size: 3.2rem;
  font-weight: 700;
}
.authentication-col-content .description {
  color: #fff;
  margin-top: 15px;
  font-size: 1.2rem;
  font-weight: 500;
}
.authentication-form {
  font-size: 0.9rem;
  width: 70%;
  display: block;
}
.authentication-form h3 {
  color: #2c304d;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: left;
}
.authentication-form .group {
  position: relative;
  margin-bottom: 30px;
}
.authentication-form .sign-btn {
  margin: 25px 0 0 0;
}
.register {
  color: #40545a;
  text-align: center;
  margin-top: 30px;
}
@media (max-width: 991px) {
  .authentication-form {
    padding: 3rem;
  }
}
@media (max-width: 572px) {
  .authentication-form {
    width: 100%;
    padding: 3rem;
  }
}
.authentication-col-content-2 {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  padding: 0 50px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.authentication-col-content-2 h1 {
  margin-top: 30px;
  margin-bottom: 0;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 600;
}
.authentication-col-content-2 .description {
  color: #fff;
  margin-top: 15px;
  font-size: 1rem;
  font-weight: 500;
}
.authentication-form-2 {
  width: 50%;
  display: block;
}
.authentication-form-2 h3 {
  color: #2c304d;
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: left;
}
.authentication-form-2 .group {
  position: relative;
  margin-bottom: 30px;
}
.authentication-form-2 .sign-btn {
  margin: 25px 0 0 0;
}
.login-nav {
  font-weight: 500;
}
.login-nav.nav-tabs {
  border: 0 none;
}
.login-nav.nav-tabs > li > a::after {
  display: none;
}
.login-nav * {
  transition: all 0.35s ease;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.login-nav li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 0.5rem;
  padding: 0;
}
.login-nav li:first-child {
  margin-left: 0;
}
.login-nav li:last-child {
  margin-right: 0;
}
.login-nav a {
  padding: 0.65rem 0;
  color: #fff;
  text-decoration: none;
  display: inline-block;
}
.login-nav a.active {
  background: #fff;
  padding: 0.65rem 1.07rem;
  color: #e76c90;
  text-decoration: none;
  display: inline-block;
  border-radius: 35px;
}
.login-nav a.active:hover {
  color: #2c304d !important;
}
.login-nav a:hover {
  background: #fff !important;
  padding: 0.65rem 1.07rem;
  text-decoration: none;
  display: inline-block;
  border-radius: 35px;
}
.login-nav.nav-tabs > li > a:hover {
  color: #2c304d !important;
}
.login-nav a:before {
  bottom: 0;
  left: 0;
}
@media (max-width: 572px) {
  .authentication-form-2 {
    width: 100%;
    padding: 2rem;
  }
}
.lock-form {
  background: #fff;
  font-size: 0.9rem;
  padding: 3.8rem 3rem;
  display: block;
  width: 400px;
  max-width: 400px;
  border-radius: 4px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 15px 25px 2px rgba(0, 0, 0, 0.14), 0 5px 30px 5px rgba(0, 0, 0, 0.12);
  z-index: 2;
}
.lock-form h3 {
  color: #2c304d;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
}
.lock-form .group {
  position: relative;
  margin-bottom: 30px;
}
.lock-form .button {
  margin: 25px 0 0 0;
}
.lock-form .back {
  color: #40545a;
  text-align: center;
  margin-top: 30px;
}
@media (max-width: 572px) {
  .lock-form {
    width: 100%;
    margin: 1rem;
  }
}
.password-form {
  background: #fff;
  font-size: 0.9rem;
  padding: 3.8rem 3rem;
  display: block;
  width: 400px;
  max-width: 400px;
  border-radius: 4px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 15px 25px 2px rgba(0, 0, 0, 0.14), 0 5px 30px 5px rgba(0, 0, 0, 0.12);
  z-index: 2;
}
.password-form h3 {
  color: #2c304d;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
}
.password-form .group {
  position: relative;
  margin-bottom: 30px;
}
.password-form .button {
  margin: 25px 0 0 0;
}
.password-form .back {
  color: #40545a;
  text-align: center;
  margin-top: 30px;
}
@media (max-width: 572px) {
  .password-form {
    width: 100%;
    margin: 1rem;
  }
}
.mail-confirm {
  background: #fff;
  font-size: 0.9rem;
  padding: 3.8rem 3rem;
  display: block;
  width: 400px;
  max-width: 400px;
  border-radius: 4px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 15px 25px 2px rgba(0, 0, 0, 0.14), 0 5px 30px 5px rgba(0, 0, 0, 0.12);
  z-index: 2;
}
.mail-confirm h3 {
  color: #2c304d;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 30px;
  text-align: center;
}
.mail-confirm p {
  font-size: 1rem;
  text-align: center;
}
.mail-confirm .button {
  margin: 30px 0 0 0;
}
@media (max-width: 572px) {
  .mail-confirm {
    width: 100%;
    margin: 1rem;
  }
}
.coming-soon {
  background: #fff;
  font-size: 0.9rem;
  padding: 3.8rem 3rem;
  width: 600px;
  max-width: 600px;
  text-align: center;
  display: block;
  border-radius: 4px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 15px 25px 2px rgba(0, 0, 0, 0.14), 0 5px 30px 5px rgba(0, 0, 0, 0.12);
  z-index: 2;
}
.coming-soon h1 {
  font-family: "Montserrat", sans-serif;
  color: #2c304d;
  font-size: 3rem;
  margin-bottom: 0;
  text-transform: uppercase;
}
.coming-soon h1 span {
  font-weight: 500;
}
.coming-soon .sub-heading {
  color: #2c304d;
  font-size: 2rem;
}
.coming-soon .group {
  position: relative;
  margin-bottom: 1.4rem;
}
.coming-soon .button {
  margin: 1.2rem 0 0 0;
}
.coming-soon #countdown {
  margin: 1rem 0;
}
.coming-soon #countdown .counter {
  display: inline-block;
  text-align: center;
  overflow: hidden;
  margin-top: 15px;
  padding-right: 3rem;
}
.coming-soon #countdown .counter:last-child {
  padding-right: 0;
}
.coming-soon #countdown span {
  font-family: "Montserrat", serif;
  font-size: 2.6rem;
  font-weight: 600;
  margin: 0;
  color: #e76c90;
}
.coming-soon #countdown p {
  font-size: 1.2rem;
  color: #98a8b4;
}
.coming-soon .notify-form {
  margin: 10px 0;
}
.coming-soon .notify-form .heading {
  color: #2c304d;
  font-size: 1.2rem;
  margin-bottom: 30px;
}
.follow-link {
  margin: 1.07rem 0 0;
}
@media (max-width: 1200px) {
  .coming-soon {
    width: 100%;
    padding: 2rem;
  }
  .coming-soon .sub-heading {
    font-size: 1.2rem;
  }
  .coming-soon #countdown {
    margin: 10px 0;
  }
  .coming-soon #countdown .counter {
    padding-right: 1rem;
  }
}
@media (max-width: 572px) {
  .coming-soon {
    width: 100%;
    padding: 2rem;
    margin-bottom: 1rem;
  }
  .coming-soon .sub-heading {
    font-size: 1.2rem;
  }
  .coming-soon #countdown {
    margin: 10px 0;
  }
  .coming-soon #countdown span {
    font-size: 1.6rem;
  }
  .coming-soon #countdown p {
    font-size: 1rem;
  }
  .coming-soon #countdown .counter {
    padding-right: 1rem;
  }
}
.bg-error-01 {
  background: #675e8e url(img/clouds.png) no-repeat bottom
    center;
}
.error-01 .error-container {
  display: block;
}
.error-01 h1 {
  font-size: 14rem;
  color: #fff;
  -webkit-transform: skewY(-15deg);
  -ms-transform: skewY(-15deg);
  transform: skewY(-15deg);
  text-shadow: 1px 1px 1px #675e8e, 2px 2px 1px #675e8e, 3px 3px 1px #675e8e,
    4px 4px 1px #675e8e, 5px 5px 1px #675e8e, 6px 6px 1px #675e8e,
    7px 7px 1px #675e8e, 8px 8px 1px #675e8e, 9px 9px 1px #675e8e,
    10px 10px 1px #483d77, 11px 11px 1px #483d77, 12px 12px 1px #483d77,
    13px 13px 1px #483d77, 14px 14px 1px #483d77, 15px 15px 1px #483d77,
    16px 16px 1px #483d77, 17px 17px 1px #483d77, 18px 18px 1px #483d77,
    19px 19px 1px #483d77, 20px 20px 1px #483d77, 21px 21px 1px #483d77,
    22px 22px 1px #483d77, 23px 23px 1px #483d77, 24px 24px 1px #483d77,
    25px 25px 1px #483d77, 26px 26px 1px #483d77, 27px 27px 1px #483d77,
    28px 28px 1px #483d77, 29px 29px 1px #483d77, 30px 30px 1px #483d77;
  -webkit-backface-visibility: hidden;
}
.error-01 h2 {
  font-size: 3rem;
  color: #fff;
  margin-top: 3rem;
}
.error-01 p {
  font-size: 1.4rem;
  margin-top: 1rem;
}
.error-01 .btn {
  margin-top: 1rem;
}
.bg-error-02 {
  background: url(img/background/07.jpg) no-repeat center
    center;
  background-size: cover;
}
.error-02 {
  width: 90%;
  display: block;
}
.error-02 h1 {
  font-size: 10rem;
  color: #fff;
  -webkit-backface-visibility: hidden;
}
.error-02 h2 {
  font-size: 2rem;
  color: rgba(52, 40, 104, 0.8);
  margin-top: 1rem;
}
.error-02 p {
  font-size: 1.2rem;
  margin-top: 1rem;
}
.error-02 .btn {
  margin-top: 1rem;
}
@media (max-width: 572px) {
  .error-01 h1 {
    font-size: 8rem !important;
  }
  .error-01 h2 {
    font-size: 1.8rem;
  }
  .error-01 p {
    font-size: 1rem;
  }
  .error-02 {
    width: 100%;
    padding: 3rem;
  }
  .error-02 h1 {
    font-size: 10rem !important;
  }
}
.pricing-tables-fixed {
  width: 70%;
  margin: 0 auto;
}
.pricing-tables-01.pricing-wrapper {
  position: relative;
  cursor: pointer;
  text-align: center;
  transition: all 0.4s ease;
}
.pricing-tables-01.pricing-wrapper {
  border-right: 0.07rem solid #e4e8f0;
}
.pricing-tables-01.pricing-wrapper.last {
  border-right: 0 none;
}
.pricing-tables-01.pricing-wrapper .pricing-image {
  margin: 2.1rem 0;
}
.pricing-tables-01.pricing-wrapper .pricing-image img {
  width: 120px;
  margin: 0 auto;
}
.pricing-tables-01.pricing-wrapper .title {
  color: #5d5386;
  font-size: 2rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0;
}
.pricing-tables-01.pricing-wrapper .container-text {
  text-align: center;
  color: #cdd6e0;
  font-size: 1.6rem;
  font-weight: 500;
}
.pricing-tables-01.pricing-wrapper .main-number {
  color: #5d5386;
  text-align: center;
  font-size: 6rem;
  font-weight: 600;
  margin: 20px 0;
}
.pricing-tables-01.pricing-wrapper .main-number::before {
  content: "$";
  font-size: 4rem;
}
.pricing-tables-01.pricing-wrapper .pricing-list {
  margin-bottom: 2.5rem;
}
.pricing-tables-01.pricing-wrapper .pricing-list p {
  color: #2c304d;
  font-size: 1rem;
  line-height: 2rem;
  padding: 0 2rem;
}
.pricing-tables-01.pricing-wrapper a {
  margin-bottom: 2.5rem;
}
.pricing-tables-02.pricing-wrapper {
  position: relative;
  cursor: pointer;
  text-align: center;
  padding: 2rem 0;
  transition: all 0.4s ease;
  border-radius: 4px;
  z-index: 1;
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.1);
}
.pricing-tables-02.pricing-wrapper .pricing-image {
  margin: 2.1rem 0;
}
.pricing-tables-02 {
  background: #fff;
}
.pricing-tables-02.purple {
  background: #5d5386;
}
.pricing-tables-02.dark {
  background: #2c304d;
}
.pricing-tables-02.purple {
  margin: -1rem -1rem 0 -1rem;
  z-index: 2;
}
.pricing-tables-02.pricing-wrapper .pricing-image img {
  width: 120px;
  margin: 0 auto;
}
.pricing-tables-02.pricing-wrapper .title {
  color: #5d5386;
  font-size: 2rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0;
}
.pricing-tables-02.purple.pricing-wrapper .title {
  color: #fff;
}
.pricing-tables-02.dark.pricing-wrapper .title {
  color: #fff;
}
.pricing-tables-02.pricing-wrapper .main-number {
  color: #5d5386;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 2rem 0;
  position: relative;
}
.pricing-tables-02.purple.pricing-wrapper .main-number {
  color: #fff;
}
.pricing-tables-02.dark.pricing-wrapper .main-number {
  color: #fff;
}
.pricing-tables-02.pricing-wrapper .main-number::before {
  content: "$20";
  font-size: 5rem;
  position: absolute;
  top: -28px;
  left: 0;
  right: 0;
  color: rgba(46, 52, 81, 0.1);
  z-index: -1;
}
.pricing-tables-02.purple.pricing-wrapper .main-number::before {
  content: "$95";
  font-size: 5rem;
  position: absolute;
  top: -28px;
  left: 0;
  right: 0;
  color: rgba(0, 0, 0, 0.15);
  z-index: -1;
}
.pricing-tables-02.dark.pricing-wrapper .main-number::before {
  content: "$65";
  font-size: 5rem;
  position: absolute;
  top: -28px;
  left: 0;
  right: 0;
  color: rgba(255, 255, 255, 0.1);
  z-index: -1;
}
.pricing-tables-02.pricing-wrapper .pricing-list {
  margin-bottom: 2.5rem;
}
.pricing-tables-02.pricing-wrapper .pricing-list li {
  color: #5d5386;
  font-size: 1rem;
  line-height: 2.5rem;
  padding: 0 2rem;
}
.pricing-tables-02.purple.pricing-wrapper .pricing-list li {
  color: #fff;
}
.pricing-tables-02.dark.pricing-wrapper .pricing-list li {
  color: #fff;
}
.pricing-tables-02.pricing-wrapper .pricing-list p {
  color: #5d5386;
  font-size: 1rem;
  line-height: 2rem;
  padding: 0 2rem;
}
.pricing-tables-02.pricing-wrapper a {
  margin-bottom: 1.4rem;
}
@media (max-width: 1200px) {
  .pricing-tables-fixed {
    width: 85%;
  }
}
@media (max-width: 576px) {
  .pricing-tables-01.pricing-wrapper {
    border: 0 none;
  }
  .pricing-tables-02.pricing-wrapper {
    margin: 0 0 30px 0;
  }
}
.invoice {
  background: #fff;
  margin-bottom: 30px;
}
.invoice-container {
  width: 80%;
  margin: 0 auto;
}
.invoice hr {
  border-color: rgba(52, 40, 104, 0.15);
  padding: 2rem 0;
}
.invoice .invoice-top {
  width: 100%;
  padding: 3rem 2rem 2rem 2rem;
}
.invoice .invoice-top h1 {
  color: #2c304d;
  font-size: 3rem;
  text-transform: uppercase;
  margin-right: 10px;
  margin-bottom: 0;
}
.invoice .invoice-top span {
  display: block;
  font-weight: 600;
  font-style: italic;
}
.invoice .invoice-header {
  width: 100%;
  padding: 3rem 2rem;
  font-size: 1.1rem;
}
.invoice .invoice-header .invoice-logo {
  display: block;
}
.invoice .invoice-header .invoice-logo img {
  width: 150px;
}
.invoice .invoice-header .details .company-name {
  color: #2c304d;
  font-weight: 600;
  font-size: 1.6rem;
}
.invoice .invoice-header .client-details .title {
  color: #2c304d;
  font-size: 1.6rem;
  font-weight: 600;
}
.invoice-date {
  padding: 0 2rem;
}
.invoice-date span {
  font-size: 1.4rem;
  font-weight: 600;
  color: #2c304d;
  margin: 50px 0;
}
.invoice .desc-tables {
  padding: 0 2rem;
}
.invoice .table thead {
  background: none;
  border-radius: 0;
  border-bottom: 0.07rem solid rgba(52, 40, 104, 0.15);
}
.invoice .table thead th {
  vertical-align: middle;
  border: 0 none;
  padding: 1rem 0.85rem;
  color: #e76c90;
  font-weight: 600;
  text-transform: uppercase;
}
.invoice .table td {
  border-bottom: 0 none;
  padding: 1.2rem;
  vertical-align: middle;
  color: #5d5386;
  font-weight: 600;
}
.invoice .table td .description {
  color: #98a8b4;
  font-size: 0.9rem;
  font-weight: 500;
}
.invoice .invoice-footer {
  background: #2c304d;
  padding: 4rem 2rem;
  margin-top: 6rem;
}
.invoice .bank .title {
  color: #aea9c3;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
.invoice .total {
  text-align: center;
  line-height: 2.2rem;
}
.invoice .total .title {
  color: #aea9c3;
  font-size: 1.4rem;
  font-weight: 600;
}
.invoice .total .number {
  color: #e76c90;
  font-size: 2.4rem;
  font-weight: 700;
}
.invoice .total .taxe {
  color: #aea9c3;
}
.invoice .footer-bottom {
  margin-top: 4rem;
  text-align: center;
}
.invoice .thx i {
  color: #e76c90;
  font-size: 3rem;
  margin-bottom: -15px;
}
.invoice .thx span {
  display: block;
  color: #aea9c3;
  font-size: 1.6rem;
  font-weight: 600;
}
@media (max-width: 1200px) {
  .invoice-container {
    width: 100%;
    margin: 0;
  }
}
@media (max-width: 576px) {
  .invoice-container {
    width: 100%;
    margin: 0;
  }
}
.search-results .input-group input {
  border: 0 none;
  border-bottom: 1px solid #eee;
  padding-left: 15px !important;
}
.search-results .input-group input::placeholder {
  color: #2a2c3d;
}
.search-results .input-group-addon {
  border: 0 none !important;
}
.search-results .input-group-addon {
  background: none;
}
.search-results .input-group-addon .btn {
  background: #fff;
  color: #e76c90;
  padding: 0 10px;
  border-radius: 0;
  border: 0;
}
.search-results .search-items {
  margin-bottom: 30px;
}
.search-results .search-items h4 {
  margin-bottom: 0.2rem;
  font-weight: 600;
}
.search-results .search-items p {
  margin-top: 0.5rem;
  font-weight: 400;
}
@media (max-width: 576px) {
  .search-results .input-group-addon .btn {
    padding: 0 5px;
  }
}
.faq .jumbotron {
  margin: 0;
  background: #f2f3f8;
  height: 235px;
  position: relative;
}
.faq .jumbotron h2 {
  color: #5d5386;
  font-size: 3rem;
  margin-bottom: 1.4rem;
}
.faq .searchbox {
  width: 40%;
  margin: 0 auto;
  position: relative;
}
.faq .searchbox input {
  width: 80%;
  box-sizing: border-box;
  border: 2px solid #aea9c3;
  border-radius: 50px;
  font-size: 1rem;
  color: #2c304d;
  background-color: transparent;
  padding: 0.85rem 1.4rem;
  transition: width 0.5s ease-out;
}
.faq .searchbox input::placeholder {
  color: #aea9c3;
  font-weight: 500;
}
.faq .searchbox input:focus {
  width: 90%;
}
.faq .accordion .card-header {
  padding: 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0 none;
}
.faq .accordion .card-header:before {
  font-family: "lineAwesome";
  content: "\f113";
  display: inline-block;
  font-size: 1.4rem;
  vertical-align: middle;
  margin-right: 0.5rem;
}
.faq .accordion .card-header.collapsed:before {
  content: "\f110";
}
.faq .accordion .card-title {
  margin-bottom: 0;
  display: inline-block;
}
.faq .accordion .card-body {
  border-bottom: 0.07rem solid #eee;
}
@media (max-width: 576px) {
  .faq .searchbox {
    width: 100%;
    margin: 0;
  }
}
.newsfeed .pop-groups i {
  cursor: pointer;
}
.hover-img {
  position: relative;
  overflow: hidden;
}
.hover-img .overlay .button {
  background: rgba(93, 83, 134, 0.9);
  width: 80px;
  height: 80px;
  line-height: 80px;
  color: #fff;
  font-size: 2.4rem;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
}
.hover-img .overlay .button-mini {
  background: rgba(93, 83, 134, 0.9);
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: #fff;
  font-size: 1.4rem;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
}
.hover-img .overlay .button:hover,
.hover-img .overlay .button-mini:hover {
  background: rgba(93, 83, 134, 0.85);
  color: #fff;
}
.newsfeed .post-video p {
  font-size: 0.95rem;
}
.newsfeed .post-video .source i {
  color: #aea9c3;
  font-size: 2.8rem;
  vertical-align: middle;
}
.newsfeed .friends-mini img {
  width: 50px;
}
.newsfeed .friends-mini a {
  display: block;
  margin-bottom: 1.4rem;
}
.newsfeed .friends-mini i {
  color: #aea9c3;
  font-size: 2.6rem;
}
.newsfeed .sticky.is-sticky {
  position: fixed;
  top: 90px;
}
@media (max-width: 576px) {
  .newsfeed .sticky.is-sticky {
    position: relative;
    top: auto;
  }
}
.contact-card-2 {
  margin-top: 0;
  position: relative;
}
.contact-card-2 .cover-bg {
  display: block;
}
.contact-card-2 .cover-bg > img {
  display: block;
  margin-bottom: 0;
  transition: all 0.25s ease-in-out;
}
.contact-card-2 .cover-image {
  display: block;
  position: absolute;
  width: 100px;
  top: -50px;
  left: 0;
  right: 0;
}
.contact-card-2 .cover-image > img {
  display: block;
  width: 100px;
  border: 6px solid #fff;
  transition: all 0.25s ease-in-out;
}
.contact-card-2 .widget-body {
  position: relative;
}
.contact-card-2 .name {
  color: #5d5386;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0;
  margin-top: 3rem;
  margin-bottom: 0;
  text-decoration: none;
  text-align: center;
}
.contact-card-2 .job {
  color: #aea9c3;
  font-size: 1rem;
  text-align: center;
}
.contact-card-2 .stats {
  margin-top: 1.4rem;
}
.contact-card-2 .stats .counter {
  display: block;
  color: #5d5386;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
}
.contact-card-2 .stats .text {
  color: #aea9c3;
  display: block;
  text-align: center;
}
.contact-card-2 .btn-group {
  padding: 2rem 0 1.4rem;
}
.contact-card-2 .quick-about {
  text-align: center;
  margin-top: 1.4rem;
}
.contact-card-2 .quick-about h4 {
  color: #2c304d;
  font-weight: 600;
  margin-bottom: 0.4rem;
}
.contact-card-2 .quick-about p {
  font-size: 0.9rem;
  margin: 0;
}
.contact-card-2 .owl-dots {
  float: left;
  margin-top: 1.4rem;
  text-align: center;
  width: 100%;
}
.contact-card-2 .owl-dots .owl-dot {
  border: 2px solid #aea9c3;
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  margin: 0 0.3rem;
  width: 12px;
  transition: all 0.3s ease 0s;
}
.contact-card-2 .owl-dots .owl-dot:hover {
  border-color: #e76c90;
}
.contact-card-2 .owl-dots .owl-dot.active {
  border: 2px solid #e76c90;
}
.group-card {
  margin-top: 0;
  position: relative;
}
.group-card .cover-image {
  display: block;
  width: 120px;
}
.group-card .cover-image > img {
  display: block;
  width: 120px;
  border: 6px solid #f2f3f8;
  transition: all 0.25s ease-in-out;
}
.group-card .widget-body {
  position: relative;
}
.group-card .name {
  color: #5d5386;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0;
  margin-top: 2rem;
  margin-bottom: 0;
  text-decoration: none;
  text-align: center;
}
.group-card .category {
  color: #aea9c3;
  font-size: 1rem;
  text-align: center;
}
.group-card .stats {
  margin-top: 1.4rem;
}
.group-card .stats i {
  color: #aea9c3;
  font-size: 2rem;
}
.group-card .stats .counter {
  display: block;
  color: #5d5386;
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
}
.group-card .stats .text {
  color: #aea9c3;
  display: block;
  text-align: center;
}
.group-card .group-members {
  padding: 0.4rem 0;
}
.group-card .group-members a {
  margin: 0 -1.6rem 0 0;
  transition: all 0.4s ease;
}
.group-card .group-members img {
  width: 50px;
  border: 0.25rem solid #fff;
}
.about .about-infos {
  margin-bottom: 1.4rem;
}
.about .about-title {
  color: #5d5386;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.about .about-infos .date {
  font-size: 0.85rem;
  margin-bottom: 0.4rem;
}
.about .about-infos i {
  font-size: 1.4rem;
  color: #fec42d;
}
.about .hobbies {
  text-align: center;
  display: inline-block;
  margin-right: 3.5rem;
}
.about .hobbies:last-child {
  margin-right: 0;
}
.about .hobbies i {
  color: #aea9c3;
  font-size: 3rem;
}
@media (max-width: 1200px) {
  .default-sidebar > .side-navbar,
  .compact-sidebar > .side-navbar,
  .compact-light-sidebar > .side-navbar {
    margin-left: -90px;
    min-width: 90px;
    max-width: 90px;
    text-align: center;
  }
  .default-sidebar > .side-navbar span.heading,
  .compact-sidebar > .side-navbar span.heading,
  .compact-light-sidebar > .side-navbar span.heading {
    margin: 0;
  }
  .default-sidebar > .side-navbar ul a,
  .compact-sidebar > .side-navbar ul a,
  .compact-light-sidebar > .side-navbar ul a {
    padding: 10px 5px;
    border: none;
    font-size: 0.85rem;
  }
  .default-sidebar > .side-navbar ul a[data-toggle="collapse"]::before,
  .compact-sidebar > .side-navbar ul a[data-toggle="collapse"]::before,
  .compact-light-sidebar > .side-navbar ul a[data-toggle="collapse"]::before {
    content: "\f123";
    transform: translateX(50%);
    position: absolute;
    top: auto;
    right: 50%;
    bottom: 0;
    left: auto;
  }
  .default-sidebar
    > .side-navbar
    ul
    a[data-toggle="collapse"][aria-expanded="true"]::before,
  .compact-sidebar
    > .side-navbar
    ul
    a[data-toggle="collapse"][aria-expanded="true"]::before,
  .compact-light-sidebar
    > .side-navbar
    ul
    a[data-toggle="collapse"][aria-expanded="true"]::before {
    content: "\f126";
  }
  .default-sidebar > .side-navbar ul a:hover,
  .compact-sidebar > .side-navbar ul a:hover,
  .compact-light-sidebar > .side-navbar ul a:hover {
    border: none;
  }
  .default-sidebar > .side-navbar ul a i,
  .compact-sidebar > .side-navbar ul a i,
  .compact-light-sidebar > .side-navbar ul a i {
    margin-right: 0;
    margin-bottom: 5px;
    display: block;
    font-size: 1.6rem;
    transition: color 0.3s;
  }
  .default-sidebar > .side-navbar .sidebar-header .title,
  .compact-sidebar > .side-navbar .sidebar-header .title,
  .compact-light-sidebar > .side-navbar .sidebar-header .title {
    display: none;
  }
  .default-sidebar > .side-navbar.shrinked,
  .compact-sidebar > .side-navbar.shrinked,
  .compact-light-sidebar > .side-navbar.shrinked {
    margin-left: 0;
  }
  .content-inner,
  .content-inner.compact {
    width: 100% !important;
    margin-left: 0;
  }
  .content-inner.active {
    width: calc(100% - 90px);
  }
  nav.navbar .navbar-brand .brand-big {
    display: none;
  }
  nav.navbar .navbar-brand .brand-small {
    display: block !important;
  }
  nav.navbar .menu-btn {
    margin-right: 20px;
    font-size: 1.2rem;
    transition: all 0.7s;
  }
  nav.navbar .menu-btn span:first-of-type {
    transform: translateY(16px);
  }
  nav.navbar .menu-btn span:nth-of-type(2) {
    transform: none;
  }
  nav.navbar .menu-btn span:nth-of-type(2)::before,
  nav.navbar .menu-btn span:nth-of-type(2)::after {
    transform: rotate(45deg);
  }
  nav.navbar .menu-btn span:nth-of-type(2)::after {
    transform: rotate(145deg);
    position: absolute;
    top: -2px;
    left: 0;
  }
  nav.navbar .menu-btn span:last-of-type {
    transform: translateY(-16px);
  }
  nav.navbar .menu-btn.active span:first-of-type {
    transform: none;
  }
  nav.navbar .menu-btn.active span:nth-of-type(2) {
    transform: rotateY(180deg);
  }
  nav.navbar .menu-btn.active span:last-of-type {
    transform: none;
  }
  footer.main-footer.fixed-footer {
    margin-left: 0;
  }
  .content-inner.active footer.main-footer.fixed-footer {
    margin-left: 90px;
  }
  .widget-01 .other-stats {
    padding-top: 1rem;
  }
  .widget-03.blog-image {
    height: 385px !important;
  }
}
@media (max-width: 991px) {
  section.dashboard-header div[class*="col-"] {
    margin-bottom: 20px;
  }
  .project .right-col {
    margin-top: 20px;
    margin-left: 65px;
  }
  .project .project-progress {
    width: 150px;
  }
  section.charts h2 {
    font-size: 1rem;
  }
  .d-scroll {
    max-height: 640px;
  }
}
@media (max-width: 576px) {
  h1,
  .h1 {
    font-size: 2rem !important;
    font-weight: 700;
  }
  .horizontal-menu {
    padding: 0 15px;
  }
  .db-modern nav.navbar {
    padding: 0;
  }
  .navbar-brand {
    margin-right: 0.3rem;
  }
  nav.navbar {
    font-size: 1rem;
  }
  nav.navbar .navbar-brand .brand-big {
    display: none;
  }
  nav.navbar .badge {
    width: 19px;
    height: 19px;
  }
  nav.navbar .nav-item > a {
    width: 40px;
  }
  nav.navbar .nav-item a > span {
    height: 10px;
    width: 10px;
  }
  nav.navbar .dropdown-menu {
    right: auto;
    left: -50%;
    transform: translateX(-50%);
  }
  nav.navbar a#user {
    margin: 0;
  }
  nav.navbar .avatar {
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
  footer.main-footer {
    position: relative !important;
  }
  footer.main-footer.fixed-footer {
    margin-left: 0;
  }
  footer.main-footer div[class*="col"] {
    text-align: center !important;
  }
  footer.main-footer .btn {
    margin-bottom: 1.07rem;
  }
  .d-scroll {
    max-height: 500px;
  }
  .off-sidebar-container {
    width: 100%;
  }
  .off-sidebar-header {
    width: 100%;
  }
  .off-sidebar-header h2 {
    padding-left: 20px;
  }
  .messenger-image.messenger-image-default {
    width: 50px;
    height: 50px;
  }
  .widget-01 .today-sales .text {
    margin: 0 0 20px;
  }
  .widget-04 .message-body span {
    float: none;
  }
  .widget-07 li {
    display: block;
    padding-top: 1.4rem;
  }
}
